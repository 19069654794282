(function () {
    'use strict';

    angular
        .module('stpApp')
        .directive('autoDropup', autoDropup);

    autoDropup.$inject = ['$timeout', '$document'];

    function autoDropup($timeout, $document) {
        var directive = {
            restrict: 'A',
            link: function ($scope, $element, $attrs) {
                $element.on('click', function() {
                    $element.removeClass('dropup');
                    $timeout(function() {
                        var dropdownMenu = $element[0].querySelector('.dropdown-menu');

                        // Check if the element has the 'open' class
                        if ($element.hasClass('open') && dropdownMenu) {
                            dropdownMenu.addEventListener('click', function(event) {
                                event.stopPropagation();
                            });
                        }

                        var parentElement = $document[0].querySelector($attrs.autoDropup);

                        if (dropdownMenu && parentElement) {
                            var rect = dropdownMenu.getBoundingClientRect();
                            var parentRect = parentElement.getBoundingClientRect();

                            if (rect.bottom >= parentRect.bottom) {
                                requestAnimationFrame(function() {
                                    $element.removeClass('dropdown');
                                    $element.addClass('dropup');
                                });
                            } else {
                                requestAnimationFrame(function() {
                                    $element.removeClass('dropup');
                                    $element.addClass('dropdown');
                                });
                            }
                        }
                    }, 0);
                });
            },
        };

        return directive;
    }
})();
