(function() {
    'use strict';

    angular
        .module('stpApp')
        .controller('ConfirmApplyCalculationController',ConfirmApplyCalculationController);

    ConfirmApplyCalculationController.$inject = ['$uibModalInstance'];

    function ConfirmApplyCalculationController($uibModalInstance) {
        var vm = this;

        vm.clear = clear;
        vm.apply = apply;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function apply () {
            $uibModalInstance.close(true);
        }
    }
})();
