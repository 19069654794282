(function() {
    'use strict';
    angular
        .module('stpApp')
        .factory('SfaIntegration', SfaIntegration);

    SfaIntegration.$inject = ['$resource'];

    function SfaIntegration ($resource) {
        var resourceUrl =  'stpcore/' + 'api/sfa-integrations/:projectId';
        // var mockUrl = 'https://93a73a5c-98dc-4e53-af86-c41fe7c98b88.mock.pstmn.io';

        return $resource(resourceUrl, {}, {
            'reset': {
                // url: mockUrl + '/api/:projectId/reset',
                url: resourceUrl + '%3Areset',
                method: 'POST'
            },
            'sendAndDeleteProject': {
                // url: mockUrl + '/api/:projectId/sendAndDelete',
                url: resourceUrl + '%3AsendAndDeleteProject',
                method: 'POST' },
            'synchronize': {
                // url: mockUrl + '/api/:projectId/synchronize',
                url: resourceUrl + '%3Asynchronize',
                method: 'POST'
            },
            'getCurrentDiff': {
                // url: mockUrl + '/api/getConflict/:projectId',
                url: resourceUrl + '%3AcurrentDiff',
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            }
        });
    }
})();
