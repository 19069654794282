(function() {
    'use strict';

    angular
        .module('stpApp')
        .controller('UploadDialogController',UploadDialogController);

    UploadDialogController.$inject = ['$uibModalInstance'];

    function UploadDialogController($uibModalInstance) {
        var vm = this;

        vm.clear = clear;
        vm.sendToParang = sendToParang;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function sendToParang() {
            $uibModalInstance.close();
        }
    }
})();
