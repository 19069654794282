(function () {
    "use strict";

    angular.module("stpApp").directive("invalidStop", invalidStop);

    function invalidStop() {
        var directive = {
            restrict: "E",
            scope: {
                list: "=list",
                tabView: "=tabView",
                stopLimit: "=stopLimit"
            },
            templateUrl: "app/route/directives/invalid-stop.html",
        };

        return directive;
    }
})();
