(function () {
    'use strict';
    angular
        .module('stpApp')
        .factory('RoutePlanningDataShare', RoutePlanningDataShare);

    RoutePlanningDataShare.$inject = ['$translate'];

    function RoutePlanningDataShare($translate) {

        var sharedData = {
            map: null,
            depots: null,
            dayTab: null,
            weekTab: null,
            filters: null,
            dataStyle: null,
            routeLayer: null,
            disableTab: null,
            currentView: null,
            selectedRoute: new Set(),
            textLayer: L.featureGroup(),
            depotLayer: L.featureGroup(),
            depotGravityLayer: L.featureGroup(),
            admLayer: null,
            drawnItem: null,
            polygon: null,
            groupWeek: {},
            weekConfig: null,
            weekConfigIsValid: null,
            weekStatus: {},
            groupDay: {},
            dayConfig: null,
            dayConfigIsValid: null,
            dayStatus: {},
            toastInfo: {
                content: '<strong>'+$translate.instant('stpApp.route.pleaseWait')+'</strong>',
                dismissButton: false,
                dismissOnTimeout: false
            },
            movingMode: false,
            rectangle : null,
            circle : null,
            isDrawingMode : false,
            totalList : {
                depot: 0,
                territory : 0,
                week: 0,
                day: 0,
            },
            areaByPoiLayer: L.featureGroup(),
            convexHull: L.featureGroup(),
            unassignLayer: L.featureGroup(),
            selectedUnassignStop: new Set(),
            numOfValues: 0,
        };

        return sharedData;

    }
})();
