(function() {
    'use strict';
    angular
        .module('stpApp')
        .factory('Stop', Stop);

    Stop.$inject = ['$resource'];

    function Stop ($resource) {
        var resourceUrl =  'stpcore/' + 'api/stops/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'getDepot': {
                isArray: true,
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' },
            'bulkDelete': {
                method:'POST',
                url: 'stpcore/' + 'api/stops%3Abulk-delete',
                headers: {"Content-Type": "application/json;charset=UTF-8"},
            }
        });
    }
})();
