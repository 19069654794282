(function(){
    'use strict';

    angular
        .module('stpApp')
        .controller('CannotDeleteTerritoryDialogController', CannotDeleteTerritoryDialogController);

    CannotDeleteTerritoryDialogController.$inject = ['$uibModalInstance'];

    function CannotDeleteTerritoryDialogController($uibModalInstance) {
        var vm = this;
        vm.close = close;

        function close() {
            $uibModalInstance.dismiss();
        }
    }
})();
