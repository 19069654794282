(function() {
    'use strict';

    angular
        .module('stpApp')
        .factory('LicenseActivation', LicenseActivation);

    LicenseActivation.$inject = ['$rootScope', '$state', '$sessionStorage'];

    function LicenseActivation ($rootScope, $state, $sessionStorage) {
        var service = {
            getPreviousState: getPreviousState,
            resetPreviousState: resetPreviousState,
            storePreviousState: storePreviousState,
            activateLicense: activateLicense,
            activateLicenseCallback: activateLicenseCallback
        };

        return service;

        function getPreviousState() {
            var previousState = $sessionStorage.previousState;
            return previousState;
        }

        function resetPreviousState() {
            delete $sessionStorage.previousState;
        }

        function storePreviousState(previousStateName, previousStateParams) {
            var previousState = { "name": previousStateName, "params": previousStateParams };
            $sessionStorage.previousState = previousState;
        }

        function activateLicense() {
            storePreviousState($rootScope.toState.name, $rootScope.toStateParams);
            $state.go('license-activation');
        }

        function activateLicenseCallback() {
            var previousState = getPreviousState();
            resetPreviousState();
            if (previousState.name) {
                $state.go(previousState.name, previousState.params);
            }
        }
    }
})();
