(function() {
    'use strict';

    angular
        .module('stpApp')
        .controller('UserManagementDialogForceController',UserManagementDialogForceController);

    UserManagementDialogForceController.$inject = ['$uibModalInstance', 'entity', 'Auth', 'Principal'];

    function UserManagementDialogForceController ($uibModalInstance, entity, Auth, Principal) {
        var vm = this;

        vm.authorities = ['ROLE_USER', 'ROLE_ADMIN', 'ROLE_ONE_TIME_USER', 'ROLE_M2M'];
        vm.clear = clear;
        vm.save = save;
        vm.user = entity;
        var oldWorkplace = entity.workplace;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function onSaveSuccess (result) {
            if (oldWorkplace !== result.workplace) {
                mixpanel.track('User Profile Changed', {
                    'workplace': result.workplace
                })
            }
            vm.isSaving = false;
            $uibModalInstance.close(result);
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        function save () {
            vm.isSaving = true;
            Auth.updateAccount(vm.user).then(onSaveSuccess).catch(onSaveError);
        }
    }
})();
