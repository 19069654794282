(function () {
    'use strict';
    angular
        .module('stpApp')
        .service('RoutePlanningService', RoutePlanningService);

    RoutePlanningService.$inject = ['RoutePlanningLeafletObjectFactory', '$localStorage', 'RoutePlanningConstants',
        'RoutePlanningDataShare', 'Route', 'FeatureSetting', 'FeatureToggleService', 'HTTP_CODE_CONCURRENT_USER_ID_MISMATCH', '$translate'];

    function RoutePlanningService (RoutePlanningLeafletObjectFactory, $localStorage, RoutePlanningConstants,
        RoutePlanningDataShare, Route, FeatureSetting, FeatureToggleService, HTTP_CODE_CONCURRENT_USER_ID_MISMATCH, $translate) {

        var CONST = RoutePlanningConstants;
        var sharedData = RoutePlanningDataShare;

        /* #region Leaflet Initialization */
        this.initMap = initMap;
        this.mapInteractions = mapInteractions;
        this.redrawRouteLayer = redrawRouteLayer;
        this.updateDepotRoute = updateDepotRoute;
        this.updateTerritoryRoute = updateTerritoryRoute;
        this.updateWeekRoute = updateWeekRoute;
        this.deselectStop = deselectStop;
        this.deselectUnassignStop = deselectUnassignStop;
        this.selectOnCheck = selectOnCheck;
        /* #endregion */

        /* #region Filter Initialization */
        this.filterBuilder = filterBuilder;
        this.filterRemover = filterRemover;
        this.setFilters = setFilters;
        /* #endregion */

        /* #region Assign Day Initialization */
        this.findGroupDay = findGroupDay;
        this.fillDayConfig = fillDayConfig;
        this.assignDays = assignDays;
        this.getDayConfig = getDayConfig;
        this.isDaysValid = isDaysValid;
        /* #endregion */

        /* #region Assign Week Initialization */
        this.findGroupWeek = findGroupWeek;
        this.fillWeekConfig = fillWeekConfig;
        this.assignWeeks = assignWeeks;
        this.getWeekConfig = getWeekConfig;
        this.isWeeksValid = isWeeksValid;
        /* #endregion */

        /* #region Other Initialization */
        this.getQueryString = getQueryString;
        this.transformFiltersToArrayData = transformFiltersToArrayData;
        this.loadSelectionInfoControl = loadSelectionInfoControl;
        this.getRouteData = getRouteData;
        this.getDepotData = getDepotData;
        this.checkAllRoute = checkAllRoute;
        this.formatToDepotStat = formatToDepotStat;
        this.loadTimeSetting = loadTimeSetting;
        this.onTimeChanged = onTimeChanged;
        /* #endregion */

        this.featureSetting = featureSetting;
        this.featureEnabled = featureEnabled;

        /* #region Leaflet */
        function initMap (baseMaps) {
            var basemapskeys = Object.keys(baseMaps);
            var defaultBaseMap;

            if (basemapskeys.length) {
                var isLocalBasemapExist = basemapskeys.filter(function(key){
                    return key === $localStorage.selectedBasemap;
                });

                if (isLocalBasemapExist.length) {
                    defaultBaseMap = baseMaps[$localStorage.selectedBasemap];
                } else {
                    defaultBaseMap = baseMaps[basemapskeys[0]];
                    $localStorage.selectedBasemap = basemapskeys[0];
                }
            }

            var layers = [sharedData.depotGravityLayer,sharedData.depotLayer,sharedData.routeLayer,sharedData.areaByPoiLayer, sharedData.unassignLayer];

            if (defaultBaseMap) {
                layers.unshift(defaultBaseMap);
            }

            sharedData.map = RoutePlanningLeafletObjectFactory.getMap(layers);
            sharedData.map.setView([-2.49607, 117.89558], 5);
            sharedData.map.createPane('convexPane');
            sharedData.map.getPane('convexPane').style.zIndex = 399;
            sharedData.map.createPane('routePane');
            sharedData.map.getPane('routePane').style.zIndex = 401;

            addLayerControls(baseMaps);
            drawLayerSetup();
        }

        function addLayerControls (baseMaps) {
            var layerControl = L.control.groupedLayers(baseMaps, null, { position: 'bottomright' }).addTo(sharedData.map);
            var zoom = new L.Control.Zoom({ position: 'topright' }).addTo(sharedData.map);
            var scale = new L.control.scale().addTo(sharedData.map);

            layerControl.addOverlay(sharedData.depotLayer, 'Depot', 'POI LAYER');
            layerControl.addOverlay(sharedData.routeLayer, 'Outlet', 'POI LAYER');
            layerControl.addOverlay(sharedData.textLayer, 'Outlet label', 'POI LAYER');
            layerControl.addOverlay(sharedData.unassignLayer, 'Unassign', 'POI LAYER');
            layerControl.addOverlay(sharedData.convexHull, 'Convex Hull', 'UTILITY');
        }

        function drawLayerSetup () {
            var drawOptions = { allowIntersection: false, showArea: true, metric: ['km', 'm'] };
            sharedData.drawnItem = L.featureGroup().addTo(sharedData.map);
            sharedData.polygon = new L.Draw.Polygon(sharedData.map, drawOptions);
            sharedData.rectangle = new L.Draw.Rectangle(sharedData.map, drawOptions);
            sharedData.circle = new L.Draw.Circle(sharedData.map, drawOptions);
        }

        function mapInteractions (updateScope) {
            sharedData.map.on('dblclick', function (e) {
                if(sharedData.movingMode || sharedData.isDrawingMode){return;}
                sharedData.isDrawingMode = true;
                sharedData.polygon.enable();
            });

            sharedData.map.on('overlayremove', function (e) {
                sharedData.map.fitBounds(sharedData.map.getBounds());
            });

            sharedData.map.on('baselayerchange', function (e) {
                $localStorage.selectedBasemap = e.name; // changed cause 'e.layer.options.tileLayerName' is deprecated
            });

            sharedData.map.on('draw:created', function (e) {
                var selectedArea = e.layer;
                var layerType = e.layerType;
                sharedData.isDrawingMode = false;
                if (layerType === 'circle') {
                    var selectedRadius = selectedArea._mRadius;
                    var center = selectedArea._latlng;

                    sharedData.routeLayer.eachLayer(function (layer) {
                        var point = layer._latlng;
                        var distance = center.distanceTo(point);

                        if(distance <= selectedRadius) {
                            layer.setStyle({
                                color: '#FFFFFF',
                                weight: 2
                            });
                            sharedData.selectedRoute.add(layer.feature);
                        }
                    });

                    sharedData.unassignLayer.eachLayer(function (layer) {
                        var point = layer._latlng;
                        var distance = center.distanceTo(point);

                        if(distance <= selectedRadius) {
                            layer.setStyle({
                                color: '#FFFFFF',
                                weight: 2
                            });
                            sharedData.selectedUnassignStop.add(layer.feature);
                        }
                    });
                } else {
                    sharedData.routeLayer.eachLayer(function (layer) {
                        var contains = turf.inside(layer.toGeoJSON(), selectedArea.toGeoJSON());
                        if (contains) {
                            layer.setStyle({
                                color: '#FFFFFF',
                                weight: 2
                            });
                            sharedData.selectedRoute.add(layer.feature);
                        }
                    });

                    sharedData.unassignLayer.eachLayer(function (layer) {
                        var contains = turf.inside(layer.toGeoJSON(), selectedArea.toGeoJSON());
                        if (contains) {
                            layer.setStyle({
                                color: '#FFFFFF',
                                weight: 2
                            });
                            sharedData.selectedUnassignStop.add(layer.feature);
                        }
                    });
                    sharedData.disableTab = (sharedData.selectedRoute.size == 0);
                    if (sharedData.dayTab == 2) {
                        findGroupDay();
                    }
                    if (sharedData.weekTab == 2) {
                        findGroupWeek();
                    }
                }
                updateScope();
            });

            sharedData.map.on('draw:canceled', function(e) {
                sharedData.isDrawingMode = false;
                updateScope();
            });

            sharedData.map.on('draw:drawstop', function(e) {
                sharedData.isDrawingMode = false;
                updateScope();
            });
        }

        function redrawRouteLayer (openChangeFreqDialog, moveStop) {
            sharedData.routeLayer.setStyle(function (feature) {
                return RoutePlanningLeafletObjectFactory.setPointStyle(feature);
            });
            sharedData.routeLayer.eachLayer(function (layer) {
                var stopTooltipsContent = RoutePlanningLeafletObjectFactory.createStopTooltipsContent(layer.feature);
                layer.setPopupContent(stopTooltipsContent);
            });
        }

        function updateTerritoryRoute (key, project, isMustEmptyStopIds) {
            var bulkTerritory = {
                "projectId": project.id,
                "stopIds": [],
                "territory": key
            };

            if (!isMustEmptyStopIds) {
                sharedData.selectedRoute.forEach(function (each) {
                    bulkTerritory['stopIds'].push(each.id);
                });
            }

            return Route.updateTerritoryRoutes(bulkTerritory).$promise;
        }

        function updateWeekRoute (key) {
            var bulkWeek = {
                stops: [],
                totalWeek: Object.keys(sharedData.dataStyle.depots[sharedData.filters.depot][CONST.WEEKS]).length,
                week: key
            };
            sharedData.selectedRoute.forEach(function (each) {
                var freq = each.properties.stopVisitFreq.substring(
                    each.properties.stopVisitFreq.lastIndexOf("d") + 1,
                    each.properties.stopVisitFreq.lastIndexOf("w")
                );
                bulkWeek["stops"].push({
                    "stopId": each.id,
                    "weekFreq": freq
                });
            });
            return Route.updateWeekRoutes(bulkWeek).$promise;
        }

        function updateDepotRoute (key, project) {
            var ids = Array.from(sharedData.selectedRoute).map(function (elm) { return elm.properties.stopId; });
            var queryParams = { ids: ids };
            var headerParams = { projectId: project.id, id: key };
            return Route.updateDepotRoutes(headerParams, queryParams).$promise;
        }

        function deselectStop() {
            sharedData.routeLayer.eachLayer(function (layer) {
                var layerStyle = RoutePlanningLeafletObjectFactory.selectedLayerStyle(false);
                layer.setStyle(layerStyle);
            });
            sharedData.selectedRoute = new Set();
            sharedData.disableTab = true;
            sharedData.weekTab = 1;
            sharedData.dayTab = 1;
            sharedData.groupWeek = {};
            sharedData.weekConfig = {};
            sharedData.weekConfigIsValid = {};
            sharedData.weekStatus = {};
            sharedData.groupDay = {};
            sharedData.dayConfig = {};
            sharedData.dayConfigIsValid = {};
            sharedData.dayStatus = {};
        }

        function deselectUnassignStop() {
            sharedData.unassignLayer.eachLayer(function (layer) {
                var layerStyle = RoutePlanningLeafletObjectFactory.selectedLayerStyle(false);
                layer.setStyle(layerStyle);
            });

            sharedData.selectedUnassignStop = new Set();
        }

        function selectOnCheck (toggle) {
            var selectedToggle = [];

            if (sharedData.currentView == CONST.DEPOT) {
                selectedToggle[0] = sharedData.filters.depot;
                sharedData.selectedRoute = new Set();
            } else {
                var str = sharedData.currentView.charAt(0);
                for (var k in toggle[str]) {
                    if (toggle[str][k] == true)
                        selectedToggle.push(k);
                }
            }

            function selectLayerStyle (layer) {
                var layerStyle = RoutePlanningLeafletObjectFactory.selectedLayerStyle(true);
                layer.setStyle(layerStyle);
                sharedData.selectedRoute.add(layer.feature);
            }

            sharedData.routeLayer.eachLayer(function (layer) {
                var route = layer.feature.properties.routes;
                var prop = layer.feature.properties;
                if (sharedData.currentView !== CONST.DEPOT) {
                    for (var i = 0; i < route.length; i++) {
                        for (var j = 0; j < selectedToggle.length; j++) {
                            if (route[i][sharedData.currentView] == selectedToggle[j]) {
                                selectLayerStyle(layer);
                            }
                        }
                    }
                } else {
                    if (selectedToggle[0] == prop.depotId) {
                        selectLayerStyle(layer);
                    }
                }
            });
            sharedData.disableTab = (sharedData.selectedRoute.size == 0);
        }
        /* #endregion */

        /* #region Filter */
        function filterBuilder (view, key) {
            if (sharedData.filters[view] && view !== CONST.DEPOT) {
                var b = sharedData.filters[view].indexOf(key) > -1;
                !b ? sharedData.filters[view] = sharedData.filters[view].concat(',' + key) : sharedData.filters[view] = sharedData.filters[view];
            } else {
                sharedData.filters[view] = key;
            }
        }

        function filterRemover (view, key) {
            if (sharedData.filters[view]) {
                if (sharedData.filters[view].length > 1) {
                    sharedData.filters[view] = sharedData.filters[view].replace(',' + key, '');
                    sharedData.filters[view] = sharedData.filters[view].replace(key + ',', '');
                } else {
                    sharedData.filters[view] = sharedData.filters[view].replace(key, '');
                    sharedData.filters[view] == '' ? sharedData.filters[view] = null : sharedData.filters[view] = sharedData.filters[view];
                }
            }
        }

        function setFilters (state, stateParams) {
            sharedData.filters.territory = state.params.territory ? stateParams.territory : null;
            sharedData.filters.week = state.params.week ? stateParams.week : null;
            sharedData.filters.day = state.params.day ? stateParams.day : null;
            sharedData.filters.depot = state.params.depot ? stateParams.depot : null;

            if (!sharedData.filters.depot) {
                if (sharedData.depots.length && sharedData.depots.length === 1) {
                    sharedData.filters.depot = sharedData.depots[0].id;
                } else if (sharedData.depots.length && sharedData.depots.length > 1) {
                    sharedData.currentView = "depot";
                }
            } else if (sharedData.filters.depot) {
                sharedData.currentView = state.params.view != CONST.DEPOT ? state.params.view : CONST.TERRITORY;
            }
        }
        /* #endregion */

        /* #region Assign Day */
        function findGroupDay() {
            sharedData.groupDay = {};
            var stopFreqs = Array.from(sharedData.selectedRoute).map(function (data) {
                return data.properties.stopVisitFreq;
            });
            sharedData.selectedRoute.forEach(function(each) {
                var freqDay = each.properties.stopVisitFreq.substring(0, each.properties.stopVisitFreq.indexOf('d'));
                if (!sharedData.groupDay[freqDay]) {
                    sharedData.groupDay[freqDay] = 1;
                }
                if (!sharedData.dayConfig[freqDay + 'd']) {
                    sharedData.dayConfig[freqDay + 'd'] = [];
                }
            });

            var freqFullDay = stopFreqs.filter(function(freq){return freq.week == 1});
            var totalWeek = Object.keys(sharedData.dataStyle.depots[sharedData.filters.depot][CONST.WEEKS]).length;
            freqFullDay.forEach(function(freq) {
                for(var i=0; i<totalWeek; i++) {
                    if (!sharedData.weekStatus[freq.prop]) {
                        sharedData.weekStatus[freq.prop] = {};
                    }
                    sharedData.weekStatus[freq.prop][(i+1).toString()] = true;
                    fillWeekConfig(freq.week, freq.prop, (i+1).toString(), true);
                };
                isWeeksValid();
            });
        }

        function fillDayConfig(freq, day, status) {
            var key = freq + 'd';
            if (!sharedData.dayConfig[key]) {
                sharedData.dayConfig[key] = [];
            }

            if (status &&(sharedData.dayConfig[key].length < freq)) {
                sharedData.dayConfig[key].push(day);
            } else if (!status &&(sharedData.dayConfig[key].length > 0)) {
                var idx = sharedData.dayConfig[key].indexOf(day);
                sharedData.dayConfig[key].splice(idx, 1);
            } else {
                sharedData.dayStatus[freq][day] = false;
            }
        }

        function assignDays() {
            var dayBulk = [];
            sharedData.selectedRoute.forEach(function(each) {
                var stopId = each.id;
                var el = {
                    "days": getDayConfig(each.properties.stopVisitFreq, sharedData.dayConfig),
                    "stopId": stopId
                };
                dayBulk.push(el);
            });
            sharedData.dayStatus = {};
            sharedData.dayConfig = {};
            sharedData.dayConfigIsValid = {};
            sharedData.groupDay = {};
            return Route.updateDayRoutes(dayBulk).$promise;
        }

        function isDaysValid() {
            angular.forEach(sharedData.dayConfig, function(value, key) {
                var limiter = key.substring(0, key.indexOf('d'));
                sharedData.dayConfigIsValid[key] = parseInt(limiter) === value.length ? true : false;
            });
            return !Object.keys(sharedData.dayConfigIsValid).some(function(value) {
                return !sharedData.dayConfigIsValid[value];
            });
        }

        function getDayConfig(stopVisitFreq, dayConfig) {
            var stopDayFreq = stopVisitFreq.substring(0, stopVisitFreq.indexOf('d') + 1);
            for(var k in dayConfig) {
                if (k === stopDayFreq) {
                    return dayConfig[k];
                }
            }
            return [];
        }
        /* #endregion */

        /* #region Assign Week */
        function findGroupWeek () {
            sharedData.groupWeek = {};
            var stopFreqs = Array.from(sharedData.selectedRoute).map(function (data) {
                return data.properties.stopVisitFreq;
            });

            function onlyUnique(value, index, self) {
                return self.indexOf(value) === index;
            }
            stopFreqs = stopFreqs.filter(onlyUnique).map(function(data) {
                return {prop:data, day: data.split('d')[0], week: data.split('d')[1].split('w')[0]}
            });
            stopFreqs.sort(function (a, b) {
                return a.week - b.week || a.day - b.day;
            });

            stopFreqs.forEach(function (each) {
                if (!sharedData.groupWeek[each.prop]) {
                    sharedData.groupWeek[each.prop] = each.week;
                }
                if (!sharedData.weekConfig[each.prop]) {
                    sharedData.weekConfig[each.prop] = [];
                }
            });

            var freq1w = stopFreqs.filter(function(freq){return freq.week == 1});
            var totalWeek = Object.keys(sharedData.dataStyle.depots[sharedData.filters.depot][CONST.WEEKS]).length;
            freq1w.forEach(function(freq) {
                for(var i=0; i<totalWeek; i++) {
                    if (!sharedData.weekStatus[freq.prop]) {
                        sharedData.weekStatus[freq.prop] = {};
                    }
                    sharedData.weekStatus[freq.prop][(i+1).toString()] = true;
                    fillWeekConfig(freq.week, freq.prop, (i+1).toString(), true);
                };
                isWeeksValid();
            });
        }

        function fillWeekConfig (freq, key, week, status) {
            var totalWeek = Object.keys(sharedData.dataStyle.depots[sharedData.filters.depot][CONST.WEEKS]).length;
            var weekSelectionLimit = totalWeek / freq;

            // empty weekConfig for reset selection
            sharedData.weekConfig[key] = [];

            // reset week selection
            for (var _week in sharedData.weekStatus[key]) {
                sharedData.weekStatus[key][_week] = false;
            }

            if(status) {
                while(sharedData.weekConfig[key].length < weekSelectionLimit) {
                    week = (week <= totalWeek) ? +week : (week - totalWeek);

                    sharedData.weekStatus[key][week] = status;
                    sharedData.weekConfig[key].push(week);

                    week += +freq;
                }
            }
        }

        function assignWeeks () {
            var weekBulk = [];
            sharedData.selectedRoute.forEach(function (each) {
                if (!isOneWeek(each.properties.stopVisitFreq)) {
                    var stopId = each.id;
                    var el = {
                        "weeks": getWeekConfig(each.properties.stopVisitFreq, sharedData.weekConfig),
                        "stopId": stopId
                    };
                    weekBulk.push(el);
                }
            });
            sharedData.weekStatus = {};
            sharedData.weekConfig = {};
            sharedData.weekConfigIsValid = {};
            sharedData.groupWeek = {};

            return Route.updateWeekRoutes(weekBulk).$promise;
        }

        function isOneWeek (val) {
            return val.includes("1w");
        }

        function isWeeksValid () {
            angular.forEach(sharedData.weekConfig, function (value, key) {
                var limiter = key.split('d')[1].split('w')[0];
                var totalWeek = Object.keys(sharedData.dataStyle.depots[sharedData.filters.depot][CONST.WEEKS]).length;
                sharedData.weekConfigIsValid[key] = parseInt(limiter) === totalWeek / value.length ? true : false;
            });
            return !Object.keys(sharedData.weekConfigIsValid).some(function (value) {
                return !sharedData.weekConfigIsValid[value];
            });
        }

        function getWeekConfig (stopVisitFreq, weekConfig) {
            for (var k in weekConfig) {
                if (k === stopVisitFreq) {
                    return weekConfig[k];
                }
            }
            return [];
        }
        /* #endregion */

        /* #region Other */
        function getQueryString (id) {
            return {
                "projectId.equals": id,
                "territory.in": sharedData.filters.territory,
                "week.in": sharedData.filters.week,
                "day.in": sharedData.filters.day,
                "depotId.equals": sharedData.filters.depot
            };
        }

        function transformFiltersToArrayData (filters) {
            return {
                territory: filters.territory ? filters.territory.split(',') : [],
                week: filters.week ? filters.week.split(',') : [],
                day: filters.day ? filters.day.split(',') : [],
            };
        }

        function loadSelectionInfoControl (filters) {
            var filtersInArray = transformFiltersToArrayData(filters);

            return {
                territory: createInfoControlMessage(filtersInArray.territory, CONST.TERRITORY),
                week: createInfoControlMessage(filtersInArray.week, CONST.WEEK),
                day: createInfoControlMessage(filtersInArray.day, CONST.DAY)
            };

            function createInfoControlMessage (list, viewKey) {
                if(!list.length) {
                    return null;
                }
                var singular = $translate.instant('stpApp.route.selectedViewInfo.'+viewKey+'.single', {param: list[0]});
                var plural = $translate.instant('stpApp.route.selectedViewInfo.'+viewKey+'.plural', {param: list.length});
                return list.length < 2 ? singular : plural;
            }
        }

        function getRouteData (routeGeom, searchQuery, milisMultiple, resetLimit) {
            var dataRoutes = [];
            var queryNotNull = Object.keys(searchQuery).some(function (key) { return searchQuery[key]; });
            if (queryNotNull) {
                resetLimit();
                dataRoutes = routeGeom.features.filter(function (feature) {

                    var source = {
                        extId: feature.properties.stopExternalId.toString(),
                        name: feature.properties.stopName.toLowerCase(),
                        value: feature.properties.stopProps.value,
                        inOutletTime: feature.properties.inStopDuration ? feature.properties.inStopDuration / milisMultiple : 0,
                        frequency: feature.properties.stopVisitFreq
                    };

                    var searchItems = [];

                    Object.keys(searchQuery).forEach(function (key) {
                        if (searchQuery[key]) {
                            searchItems.push(typeof source[key] == 'string' ? source[key].includes(searchQuery[key]) : source[key] == searchQuery[key]);
                        }
                    });
                    return searchItems.every(function (item) { return item; });
                });
            } else {
                dataRoutes = routeGeom.features;
            }
            dataRoutes.forEach(function (x) {
                var isOnSelection = sharedData.selectedRoute.has(x);
                x.isChecked = isOnSelection;
            });
            return dataRoutes;
        }

        function getDepotData (depotItems, depotSearchQuery, radioDepot) {
            if (depotSearchQuery) {
                depotItems = sharedData.depots.filter(function (depot) {
                    var name = depot.name.toLowerCase();
                    var extId = depot.externalId;

                    var isInclude = name.includes(depotSearchQuery.toLowerCase()) || extId.toString().includes(depotSearchQuery);
                    return isInclude;
                });
            } else if (radioDepot) {
                depotItems = sharedData.depots.filter(function (depot) {
                    return depot.id == radioDepot;
                });
            }
            return depotItems;
        }

        function checkAllRoute (flag, routesList) {
            sharedData.routeLayer.eachLayer(function (layer) {
                var stopId = layer.feature.properties.stopId;
                routesList.forEach(function (route) {
                    route.isChecked = flag;
                    var layerStyle = RoutePlanningLeafletObjectFactory.selectedLayerStyle(stopId == route.id && route.isChecked);
                    layer.setStyle(layerStyle);
                    if (stopId == route.id && route.isChecked) {
                        sharedData.selectedRoute.add(layer.feature);
                    } else {
                        sharedData.selectedRoute.delete(layer.feature);
                    }
                });
            });
        }

        function formatToDepotStat (stat) {
            return {
                "depotId": stat.depotId,
                "totalValue": stat.totalValue,
                "totalValue2": stat.totalValue2,
                "totalValue3": stat.totalValue3,
                "stopCount": stat.stopCount,
                "visitCount": stat.visitCount,
                "stopDistance": stat.stopDistance,
                "stopTime": humanizeTime(stat.stopTime)
            };
        }

        function humanizeTime (input) {
            var result = '';
            var duration = moment.duration(input, "hour");

            var hours = duration.asHours() >= 1 ? Math.floor(duration.asHours()) + 'h ' : '';
            var minutes = duration.minutes() ? duration.minutes() + 'min ' : '';

            result = hours + minutes;

            if (!duration.hours() && !duration.minutes()) {
                result = '0 min';
            }

            return result;
        }

        function loadTimeSetting (project) {
            var timeSetting = [];

            if (project.props.dayTimeWindows.length > 0) {
                var timeAll = project.props.dayTimeWindows;

                if (timeAll.length > project.props.maxDay) { // kurangin
                    var bucket = [];
                    for (var i = 0; i < project.props.maxDay; i++) {
                        bucket.push(timeAll[i]);
                    }
                    timeAll = bucket;
                } else if (timeAll.length < project.props.maxDay) {
                    var diff = project.props.maxDay - timeAll.length;

                    for (var i = 0; i < diff; i++) {
                        timeAll.push({
                            "startTime": new Date(1970, 0, 1, 0, 0, 0, 0),
                            "endTime": new Date(1970, 0, 1, 0, 0, 0, 0)
                        });
                    }
                }
                timeAll.forEach(function (eachTime) {
                    var offset = moment().utcOffset(),
                        startTimeUnix = moment.unix(eachTime.startTime),
                        endTimeUnix = moment.unix(eachTime.endTime);

                    var startTimeWithZone = moment(startTimeUnix).utcOffset(0 - offset),
                        endTimeWithZone = moment(endTimeUnix).utcOffset(0 - offset);

                    var startTimeConverted = getTimeToDateNow(startTimeWithZone._d),
                        endTimeConverted = getTimeToDateNow(endTimeWithZone._d);

                    // get unix time(hour, minute, sec) then set that new date with it
                    var timePerDay = {
                        "startTime": startTimeConverted,
                        "endTime": endTimeConverted
                    };
                    timeSetting.push(timePerDay);
                });
            } else if (project.props.dayTimeWindows.length == 0) {
                var totalDay = project.props.maxDay;
                for (var i = 0; i < totalDay; i++) {
                    var timePerDay = {
                        "startTime": new Date(1970, 0, 1, 0, 0, 0, 0),
                        "endTime": new Date(1970, 0, 1, 0, 0, 0, 0)
                    };

                    timeSetting.push(timePerDay);
                }
            }
            return timeSetting;
        }

        function onTimeChanged (timeSetting, start, end) {
            for (var i = 0; i < timeSetting.length; i++) {
                if (timeSetting[i].startTime == null || timeSetting[i].endTime == null || start > end) {
                    return true;
                } else {
                    return false;
                }
            }
        }

        function getTimeToDateNow (time) {
            var hours = moment(time).get("h"),
                mins = moment(time).get("m"),
                d = new Date();
            d.setHours(hours);
            d.setMinutes(mins);
            return d;
        }
        /* #endregion */

        function featureSetting() {
            function onLoadFeatureSettingSuccess(result) {
                var arrFeature = [];
                result.forEach(function(feature) {
                    if (feature.value === 'true') {
                        arrFeature.push(feature.key);
                    }
                })
                FeatureToggleService.setFeatures(arrFeature);
            }

            function onLoadFeatureSettingError(error) {
                if (error.status != HTTP_CODE_CONCURRENT_USER_ID_MISMATCH) {
                    ngToast.danger({
                        content: "<strong>" + error + "</strong>",
                        dismissButton: true,
                        timeout: 5000,
                    });
                }
            }
            FeatureSetting.getFeatureSetting(onLoadFeatureSettingSuccess, onLoadFeatureSettingError);
        }

        function featureEnabled(featureName) {
            var result = FeatureToggleService.findFeature(featureName);
            return result;
        }
    }
})();
