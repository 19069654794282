/**
 * @typedef IFormTerritory
 * @prop {number | null} numberOfTerritory
 * @prop {number | null} capacity
 */

/**
 * @typedef IFormProjectSetting
 * @prop {number | null} fixedCost
 * @prop {number | null} costPerKm
 * @prop {boolean} avoidTolls
 * @prop {number | null} reloadDurationInMin
 */

/**
 * @typedef IFormWorkingTime
 * @prop {number} day
 * @prop {number} startTime
 * @prop {number} endTime
 */

/**
 * @typedef IFormBreakTime
 * @prop {number | null} durationInMin
 * @prop {number | null} startTime
 * @prop {number | null} endTime
 */

/**
 * @typedef IResBalanceStatus
 * @prop {boolean} done
 * @prop {string} errorDescription
 */

(function(){
    'use strict';

    angular
        .module('stpApp')
        .controller('OneClickOptimzeDialogController', OneClickOptimzeDialogController);

    OneClickOptimzeDialogController.$inject = ['sharedData', 'project', 'mapProp', 'balancingStatus', '$uibModalInstance', '$q', 'MapProperty', 'Project', 'RoutePlanningService'];

    function OneClickOptimzeDialogController(sharedData, project, mapProp, balancingStatus, $uibModalInstance, $q, MapProperty, Project, RoutePlanningService) {
        var vm = this;

        vm.service = RoutePlanningService;

        vm.resultIntervalPolling = balancingStatus

        vm.isSaving = false;
        vm.isOptimized = false;
        vm.isErrorOptimized = false;

        vm.stepper = 1;
        vm.useCapacity = false;

        vm.depots = sharedData.depots;
        vm.selectedDepotId = sharedData.depots[0].id.toString();

        vm.isEditMode = false;
        vm.isSelectedAllTerritory = false;

        /** @type IFormTerritory */
        vm.formTerritory = {
            numberOfTerritory: null,
            capacity: null
        };

        /** @type IFormProjectSetting */
        vm.formProjectSetting = {
            avoidTolls: false,
            reloadDurationInMin: null,
        };

        /** @type IFormWorkingTime[] */
        vm.formWorkingTime = [];
        vm.workingDays = null;
        vm.maxWorkingDays = 7;
        vm.invalidTime = false;


        /** @type IFormBreakTime */
        vm.formBreakTime = {
            durationInMin: 30,
            startTime: new Date(1970, 0, 1, 12, 0, 0, 0),
            endTime: new Date(1970, 0, 1, 13, 0, 0, 0),
        };
        vm.isTerritoryBreakTime = false;

        vm.depotTerritory = {};
        vm.nearDaySeqConfig = project.props.nearestToDepotDayOrders;
        vm.sortableOptions = {
            "ui-floating": false,
            "ui-preserve-size": true,
            axis: "y",
            stop: onDragStop,
        };

        vm.close = close;
        vm.save = save;

        vm.isDisabledAddTerritory = isDisabledAddTerritory;
        vm.isDisabledNext = isDisabledNext;
        vm.prevNext = prevNext;

        vm.onCheckAllTerritory = onCheckAllTerritory;
        vm.onCheckTerritory = onCheckTerritory;
        vm.onChangeDepot = onChangeDepot;
        vm.addTerritory = addTerritory;
        vm.updateTerritory = updateTerritory;
        vm.removeTerritory = removeTerritory;
        vm.onTimeChanged = onTimeChanged;
        vm.reOptimize = reOptimize;
        vm.updateNearDayConfig = updateNearDayConfig;
        vm.clusteringOptions = 'DISTANCE';

        init();

        function init() {
            vm.useCapacity = project.props.useCapacity;

            _updateTerritoryCollection();
            _updateFormProjectSetting();
            _updateFormWorkingTime();
            updateNearDayConfig();
            if (vm.resultIntervalPolling) {
                vm.stepper = 5;

                vm.isSaving = true;
                vm.isOptimized = false
                vm.isErrorOptimized = false;
                setTimeout(function () {
                    _intervalPolling();
                }, 5000);
            }
        }

        function close() {
            if(vm.stepper < 5) {
                $uibModalInstance.dismiss(vm.resultIntervalPolling);
            }
            $uibModalInstance.close(vm.resultIntervalPolling);
        }

        /**
         * on prev/next action
         * @param {'prev'| 'next'} type
         */
        function prevNext(type, reload) {
            if (type === 'next') {
                vm.stepper = vm.stepper + 1;
                if (vm.stepper === 5) {
                    save();
                }
            } else {
                if (reload) {
                    vm.isSaving = false;
                    vm.isOptimized = false;
                    vm.isErrorOptimized = false;
                }
                vm.stepper = vm.stepper - 1
            }
        }

        /**
         * check `add territory` vm.formTerritory
         * @returns {boolean}
         */
        function isDisabledAddTerritory() {
            if (vm.useCapacity) {
                return !vm.formTerritory.numberOfTerritory || vm.formTerritory.numberOfTerritory < 0 || vm.depotTerritory[vm.selectedDepotId].length + vm.formTerritory.numberOfTerritory > 100 || vm.formTerritory.capacity === undefined || vm.formTerritory.capacity === null || vm.formTerritory.capacity < -1
            }
            return !vm.formTerritory.numberOfTerritory || vm.formTerritory.numberOfTerritory < 0 || vm.depotTerritory[vm.selectedDepotId].length + vm.formTerritory.numberOfTerritory > 100
        }

        /**
         * check disabled Next
         * @returns {boolean}
         */
        function isDisabledNext() {
            if (vm.stepper === 1) {
                return vm.depotTerritory[vm.selectedDepotId].length === 0
            } else if(vm.stepper === 2) {
                return vm.invalidTime;
            } else if(vm.stepper === 3) {
                //
            } else if(vm.stepper === 4) {
                /**
                 * reload duration min 0, fixed cost min 0, cost per kilomoter min 0
                 * sudah ditambah attr `min` di htmlnya jadi hanya perlu jaga jika undifined/null
                 */
                if (vm.useCapacity) {
                    return vm.formProjectSetting.reloadDurationInMin === undefined ||
                    vm.formProjectSetting.reloadDurationInMin === null;
                }
                return false;
            }
        }

        function onTimeChanged (start, end) {
            vm.invalidTime = vm.service.onTimeChanged(vm.formWorkingTime, start, end);
        }

        function onChangeDepot() {
            _resetFormTerritory()
        }

        function onCheckAllTerritory() {
            vm.depotTerritory[vm.selectedDepotId].forEach(function (val) {
                val.isSelected = vm.isSelectedAllTerritory
            })
            _checkIskEditMode();
        }

        function onCheckTerritory() {
            vm.isSelectedAllTerritory = vm.depotTerritory[vm.selectedDepotId].every(function (val) {
                return val.isSelected
            })
            _checkIskEditMode();
        }

        function addTerritory() {
            for (var index = 0; index < vm.formTerritory.numberOfTerritory; index++) {
                vm.depotTerritory[vm.selectedDepotId].push({
                    "color": '#e1e1e1',
                    "name": null,
                    "loadCapacity": vm.formTerritory.capacity,
                    "isSelected": false,
                })
            }
            _resetFormTerritory()
        }

        function updateTerritory() {
            vm.depotTerritory[vm.selectedDepotId].forEach(function (val) {
                if (val.isSelected) {
                    val.loadCapacity = vm.formTerritory.capacity
                    val.isSelected = false
                }
            })
            _resetFormTerritory()
        }

        function removeTerritory() {
            var filterIndexSelectedTerritory = vm.depotTerritory[vm.selectedDepotId].filter(function (val) {
                return val.isSelected;
            }).map(function (val, index) {
                return index;
            });
            vm.depotTerritory[vm.selectedDepotId] = vm.depotTerritory[vm.selectedDepotId].filter(function (val, index) {
               return !filterIndexSelectedTerritory.includes(index)
            });
            _resetFormTerritory();
        }

        function save() {
            vm.isSaving = true;
            vm.isOptimized = false;
            vm.isErrorOptimized = false;

            // Step 1
            for (var key in vm.depotTerritory) {
                mapProp.style.depots[key].territories = vm.depotTerritory[key].reduce(function (acc, val, index) {
                    acc[index + 1] = {
                        "color": val.color,
                        "name": val.name,
                        "loadCapacity": vm.useCapacity ? val.loadCapacity : null
                    };
                    return acc;
                }, {})
            }

            // Step 2
            project.props.avoidTolls = vm.formProjectSetting.avoidTolls;
            project.props.useCapacity = vm.useCapacity;
            project.props.reloadDurationInMin = vm.useCapacity ? vm.formProjectSetting.reloadDurationInMin : null;
            project.props.maxDay = vm.workingDays;
            project.props.nearestToDepotDayOrders = vm.nearDaySeqConfig;

            // Step 3
            var arrStartEpoch = [],
                arrEndEpoch = [],
                dayTimeWindows = [],
                totalDay = vm.workingDays;

            vm.formWorkingTime.forEach(function (each) {
                each.startTime.setSeconds(0);
                each.endTime.setSeconds(0);
                arrStartEpoch = _convertToEpoch(each.startTime, arrStartEpoch);
                arrEndEpoch = _convertToEpoch(each.endTime, arrEndEpoch);
            });

            for (var i = 0; i < totalDay; i++) {
                dayTimeWindows.push({
                    "day": i + 1,
                    "endTime": arrEndEpoch[i],
                    "startTime": arrStartEpoch[i]
                });
            }
            project.props.dayTimeWindows = dayTimeWindows;

            /**
             * Processing save
             * jika saving `Project.update` gagal -> `vm.isErrorOptimized = true`
             * jika saving `MapProperty.update` gagal -> `vm.isErrorOptimized = true`
             * jika saving `Project.oneClickBalancing` gagal -> `vm.isErrorOptimized = true`
             */
            Project.update(project).$promise.then(function () {
                MapProperty.update(mapProp).$promise.then(function () {
                    reOptimize();
                }, _onError);
            }, _onError);

            function _onError(err) {
                vm.isSaving = false;
                vm.isErrorOptimized = true;
            }
        }

        function reOptimize() {
            vm.isSaving = true;
            vm.isOptimized = false
            vm.isErrorOptimized = false;

            if (vm.clusteringOptions === 'PIZZA') {
                Project.oneClickPizzaBalancing({ projectId: project.id }, null).$promise.then(_onSuccess, _onError);
            } else {
                Project.oneClickBalancing({ projectId: project.id }, null).$promise.then(_onSuccess, _onError);
            }

            function _onSuccess() {
                _intervalPolling();
            }

            function _onError(err) {
                vm.isSaving = false;
                vm.isErrorOptimized = true
            }
        }

        function onDragStop () {
            vm.nearDaySeqConfig.forEach(function(day, idx){
                day.sequence = idx;
            });
        }

        function updateNearDayConfig() {
            if (vm.workingDays !== vm.nearDaySeqConfig.length) {
                vm.nearDaySeqConfig = [];
                for (var i = 0; i < vm.workingDays; i++) {
                    vm.nearDaySeqConfig.push({
                        day: i + 1,
                        sequence: i
                    });
                }
            }
        }

        function _updateTerritoryCollection() {
            for (var keyDepot in sharedData.dataStyle.depots) {
                var territoryCollection = []
                for (var keyTerritory in sharedData.dataStyle.depots[keyDepot].territories) {
                    var territory = sharedData.dataStyle.depots[keyDepot].territories[keyTerritory];
                    territory.isSelected = false;
                    territoryCollection.push(territory)
                }
                sharedData.dataStyle.depots[keyDepot]
                vm.depotTerritory[keyDepot] = territoryCollection
            }
        }

        function _updateFormProjectSetting() {
            vm.formProjectSetting = {
                avoidTolls: project.props.avoidTolls,
                reloadDurationInMin: project.props.reloadDurationInMin,
            };
        }

        function _updateFormWorkingTime() {
            vm.workingDays = project.props.maxDay;
            vm.formWorkingTime = _loadTimeSetting(project)

            function _loadTimeSetting (project) {
                var timeSetting = [];

                if (project.props.dayTimeWindows.length > 0) {
                    var timeAll = project.props.dayTimeWindows;

                    if (timeAll.length > 7) { // kurangin
                        var bucket = [];
                        for (var i = 0; i < 7; i++) {
                            bucket.push(timeAll[i]);
                        }
                        timeAll = bucket;
                    } else if (timeAll.length < 7) {
                        var diff = 7 - timeAll.length;

                        for (var i = 0; i < diff; i++) {
                            timeAll.push({
                                "startTime": new Date(1970, 0, 1, 0, 0, 0, 0),
                                "endTime": new Date(1970, 0, 1, 0, 0, 0, 0)
                            });
                        }
                    }
                    timeAll.forEach(function (eachTime) {
                        var offset = moment().utcOffset(),
                            startTimeUnix = moment.unix(eachTime.startTime),
                            endTimeUnix = moment.unix(eachTime.endTime);

                        var startTimeWithZone = moment(startTimeUnix).utcOffset(0 - offset),
                            endTimeWithZone = moment(endTimeUnix).utcOffset(0 - offset);

                        var startTimeConverted = _getTimeToDateNow(startTimeWithZone._d),
                            endTimeConverted = _getTimeToDateNow(endTimeWithZone._d);

                        // get unix time(hour, minute, sec) then set that new date with it
                        var timePerDay = {
                            "startTime": startTimeConverted,
                            "endTime": endTimeConverted
                        };
                        timeSetting.push(timePerDay);
                    });
                } else if (project.props.dayTimeWindows.length == 0) {
                    var totalDay = 7;
                    for (var i = 0; i < totalDay; i++) {
                        var timePerDay = {
                            "startTime": new Date(1970, 0, 1, 0, 0, 0, 0),
                            "endTime": new Date(1970, 0, 1, 0, 0, 0, 0)
                        };

                        timeSetting.push(timePerDay);
                    }
                }
                return timeSetting;
            }

            function _getTimeToDateNow (time) {
                var hours = moment(time).get("h"),
                    mins = moment(time).get("m"),
                    d = new Date();
                d.setHours(hours);
                d.setMinutes(mins);
                return d;
            }
        }

        function _checkIskEditMode() {
            vm.isEditMode = vm.depotTerritory[vm.selectedDepotId].some(function (val) {
                return val.isSelected;
            });
        }

        function _resetFormTerritory() {
            vm.isEditMode = false;
            vm.isSelectedAllTerritory = false
            vm.depotTerritory[vm.selectedDepotId].forEach(function (val) {
                val.isSelected = false
            })
        }

        function _convertToEpoch (time, arr) {
            var momentDateStr = '1970-01-01', // unix gmt 0
                str = JSON.stringify(time),
                spl = str.slice(1, -1),
                mmt = moment(spl),
                gmtz = moment(mmt, 'hmmss').format('HH:mm:ss');

            var toSeventiesStr = momentDateStr + 'T' + gmtz + '+00:00',
                toUtc = moment(toSeventiesStr).utc(),
                epochUtc = moment(toUtc).valueOf() / 1000;
            arr.push(epochUtc);
            return arr;
        }

        function _intervalPolling() {
            Project.balancingStatus({projectId: project.id}).$promise.then(_onSuccessIntervalPolling).catch(_onErrorIntervalPolling);
        }

        /**
         * success load `balancingStatus`
         * @param {IResBalanceStatus} res
         */
        function _onSuccessIntervalPolling(res) {
            vm.resultIntervalPolling = res

            // if error description
            if (res.errorDescription) {
                vm.isSaving = false;
                vm.isErrorOptimized = true
                return
            }

            // if need loading
            if (!res.done) {
                setTimeout(function () {
                    _intervalPolling()
                }, 5000);
                return
            }

            // success
            vm.isSaving = false;
            vm.isOptimized = true;
        }

        function _onErrorIntervalPolling(err) {
            vm.isSaving = false;
            vm.isErrorOptimized = true;
        }
    }

})();
