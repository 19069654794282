(function () {
    'use strict';

    angular
        .module('stpApp')
        .controller('DaySettingController', DaySettingController);

    DaySettingController.$inject = ['$scope', '$uibModalInstance', 'projectResource', 'Project', 'uiSortableMultiSelectionMethods'];

    function DaySettingController($scope, $uibModalInstance,  projectResource, Project, uiSortableMultiSelectionMethods) {
        var vm = this;

        vm.closeDialog = closeDialog;
        vm.project = projectResource;
        vm.balanceValue = vm.project.props.depotBalancingFactor;
        vm.days = vm.project.props.nearestToDepotDayOrders;
        vm.maxDay = vm.project.props.maxDay;
        vm.sortableOptions = uiSortableMultiSelectionMethods.extendOptions({
            "ui-floating": false,
            "ui-preserve-size": true,
            axis: "y",
            stop: onDragStop,
            helper: "clone"
        });
        vm.apply = apply;

        vm.tabActive = 1;


        checkDayList();

        function checkDayList() {
            if (!vm.days.length || vm.days.length != vm.maxDay) {
                vm.days = [];
                for (var i = 0; i < vm.maxDay; i++) {
                    vm.days.push({
                        day: i + 1,
                        sequence: i
                    });
                }
            }
        }

        function closeDialog () {
            $uibModalInstance.dismiss('cancel');
        }

        function onDragStop () {
            vm.days.forEach(function(day, idx){
                day.sequence = idx;
            });

            vm.project.props.nearestToDepotDayOrders = vm.days;
        }

        function apply() {
            if (vm.project.props) {
                vm.project.props.depotBalancingFactor = vm.balanceValue;
            }
            Project.update(vm.project, onUpdateSucess);

            function onUpdateSucess() {
                $uibModalInstance.close('cancel');
            }
        }
    }
})();
