(function() {
    'use strict';
    angular
        .module('stpApp')
        .factory('ParseFileService', ParseFileService);

    ParseFileService.$inject = ['$q'];

    function ParseFileService ($q) {
        var service = {
            parseCsv: parseCsv,
            parseSheet: parseSheet
        };

        return service;

        function parseCsv(file) {
            var defer = $q.defer();
            Papa.parse(file, {
                skipEmptyLines: true,
                complete: function(results) {
                    var isHeaderDuplicate = false;
                    var data = results.data;
                    var header = data[0]
                    isHeaderDuplicate = _findDuplicates(header);

                    if (isHeaderDuplicate.length) {
                        defer.reject('error.duplicate.header');
                        return;
                    }

                    var maxDataLength = data.reduce(function(max, curr){
                        max = curr.length > max ? curr.length : max;
                        return max;
                    }, 0);

                    var dataHeaderDiff = Math.abs(maxDataLength - header.length);
                    var keys = _createKeys(header, dataHeaderDiff);
                    var dataWithoutHeader = data.filter(function(x,idx){return idx > 0});

                    var result = {
                        keys: keys,
                        data: _createData(keys, dataWithoutHeader)
                    }

                    defer.resolve(result);
                }
            });

            return defer.promise;
        }

        function parseSheet(file) {
            var defer = $q.defer();
            var reader = new FileReader();

            reader.readAsArrayBuffer(file);

            reader.onload = function (e) {
                var result = e.target.result;
                var workbook = XLSX.read(result, { type: 'buffer' });

                // first worksheet
                var workSheet = workbook.Sheets[workbook.SheetNames[0]];
                var data = XLSX.utils.sheet_to_json(workSheet, { header: 1, blankrows: false});

                // fill empty array on data
                for (var i = 0; i < data.length; i++) {
                    for (var j = 0; j < data[j].length; j++) {
                        if (!data[i][j]) {
                            data[i][j] = '';
                        }
                    }
                }

                var isHeaderDuplicate = false;
                var header = data[0];
                isHeaderDuplicate = _findDuplicates(header);

                if (isHeaderDuplicate.length) {
                    defer.reject('error.duplicate.header');
                    return;
                }

                var maxDataLength = data.reduce(function (max, curr) {
                    max = curr.length > max ? curr.length : max;
                    return max;
                }, 0);

                var dataHeaderDiff = Math.abs(maxDataLength - header.length);
                var keys = _createKeys(header, dataHeaderDiff);
                var dataWithoutHeader = data.filter(function (x, idx) {
                    return idx > 0;
                });

                var result = {
                    keys: keys,
                    data: _createData(keys, dataWithoutHeader),
                };

                defer.resolve(result);
            };

            return defer.promise;
        }

        function _findDuplicates(arr) {
            var sorted_arr = arr.filter(function(x){return x}).slice().sort();
            var results = [];
            for (var i = 0; i < sorted_arr.length - 1; i++) {
                if (sorted_arr[i + 1] == sorted_arr[i]) {
                    results.push(sorted_arr[i]);
                }
            }
            return results;
        }

        function _createKeys(header, diff) {
            var result = diff ? header.concat(Array(diff).fill('')) : header;
            var counter = 0;
            result = result.map(function (each) {
                counter = !each ? counter + 1 : counter ;
                return each ? each : 'undefined' + counter;
            });
            return result;
        }

        function _createData(keys, data) {
            var dataInArrayObject = data.map(function (each, idx) {
                var data = keys.reduce(function (obj, k, idx) {
                    if (!obj[k]) {
                        obj[k] = null;
                    }

                    if ( typeof each[idx] === "string") {
                        obj[k] = each[idx] ? each[idx].replace(/(\r\n|\n|\r)/gm, " ").trim(): obj[k];
                    } else {
                        obj[k] = each[idx] ? each[idx]: obj[k];
                    }
                    return obj;
                }, {});
                return data;
            });
            return dataInArrayObject;
        }
    }

})();
