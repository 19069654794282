(function() {
    'use strict';

    angular
        .module('stpApp')
        .controller('ConcurrentDialogController', ConcurrentDialogController);

    ConcurrentDialogController.$inject = ['$uibModalInstance', 'Auth', '$state', '$uibModalStack'];

    function ConcurrentDialogController ($uibModalInstance, Auth, $state, $uibModalStack) {
        var vm = this;
        vm.logout = logout;

        function logout () {
            $uibModalStack.dismissAll();
            Auth.logout();
            $state.go('home');
        }
    }
})();
