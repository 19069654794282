(function() {
    'use strict';

    angular
        .module('stpApp')
        .controller('AccessControlController', AccessControlController);

    AccessControlController.$inject = ['$stateParams', '$uibModalInstance', 'projectAccessControl', 'User', 'Project',
        'AlertService', 'HTTP_CODE_CONCURRENT_USER_ID_MISMATCH']

    function AccessControlController($stateParams, $uibModalInstance, projectAccessControl, User, Project,
        AlertService, HTTP_CODE_CONCURRENT_USER_ID_MISMATCH) {
        var vm = this;

        vm.closeDialog = closeDialog;
        vm.userAccessChanged = userAccessChanged;
        vm.projectAccessControl = projectAccessControl;
        vm.getUserLoginContains = getUserLoginContains;
        vm.userOnSelect = userOnSelect;
        vm.deleteAccessControl = deleteAccessControl;

        function userOnSelect(item) {
            var isDuplicate = vm.projectAccessControl.find(function(each){
                return each.user == item.login;
            });

            if(!isDuplicate){
                Project.saveAccessControl({
                    "canRead": false,
                    "canUpdate": false,
                    "projectId": $stateParams.id,
                    "user": item.login
                }, function(response) {
                    response.firstName = item.firstName;
                    response.lastName = item.lastName;
                    vm.projectAccessControl.push(response);
                });
            }
        }

        function getUserLoginContains(query) {
            return User.query({
                    'login.contains': query
                }).$promise.then(function(response){
                    return response;
                });
        }

        function closeDialog () {
            $uibModalInstance.dismiss('cancel');
        }

        function userAccessChanged(user) {
            vm.isSaving = true;

            if(user.canRead == false) {
                user.canUpdate = false
            }

            Project.updateAccessControl(user, onSuccess, onError);

            function onSuccess() {
                vm.isSaving = false;
            }

            function onError(err) {
                vm.isSaving = false;
                if (err.status != HTTP_CODE_CONCURRENT_USER_ID_MISMATCH) {
                    ngToast.danger(err.status + ':' + err.data.detail);
                }
            }
        }

        function deleteAccessControl(user, idx) {
            Project.deleteAccessControl({ 'id': user.id }, onSuccess, onError);

            function onSuccess(){
                vm.projectAccessControl.splice(idx, 1)
            }

            function onError(err) {
                AlertService.error(error.data.message);
            }
        }
    }
})();

