(function () {
    'use strict';

    angular
        .module('stpApp')
        .constant('ColorPaletteConstant', [
            '#FF5733', '#FFAA33', '#FFDD33', '#A1FF33', '#33FF5E',
            '#33FFA7', '#33FFEC', '#33D4FF', '#3377FF', '#333DFF',
            '#9333FF', '#D933FF', '#FF33D4', '#FF3377', '#FF333D',
            '#FF335B', '#FF4A33', '#FF7B33', '#FFC233', '#F2FF33',
            '#A3FF33', '#33FF56', '#33FF9E', '#33FFD6', '#33B3FF',
            '#3376FF', '#3356FF', '#4D33FF', '#9633FF', '#CC33FF',
            '#FF33D3', '#FF3374', '#FF334A', '#FF7049', '#FF8E49',
            '#FFAF49', '#FFC749', '#FFE649', '#C3FF49', '#9FFF49',
            '#63FF49', '#49FF6F', '#49FF9F', '#49FFC9', '#49E3FF',
            '#498FFF', '#4963FF', '#6549FF', '#8E49FF', '#AF49FF',
            '#C749FF', '#E649FF', '#FF49C3', '#FF499F', '#FF4963',
            '#FF5249', '#FF6B49', '#FF8349', '#FF9949', '#FFB349',
            '#FFD049', '#FFEC49', '#D6FF49', '#9EFF49', '#6FFF49',
            '#49FF6B', '#49FF99', '#49FFD1', '#49CBFF', '#4999FF',
            '#496FFF', '#4956FF', '#5249FF', '#6B49FF', '#8349FF',
            '#9949FF', '#B349FF', '#D049FF', '#EC49FF', '#FF49CB',
            '#FF4999', '#FF496F', '#FF635D', '#FF5D89', '#FF5DB0',
            '#FF5DD7', '#FF5DBD', '#FF5DA4', '#FF5D8A', '#FF5D72',
            '#FF5D59', '#FF5D41', '#FF6B5D', '#FF5D5E', '#FF8A5D',
            '#FFB05D', '#FFD75D', '#FFBD5D', '#FFA45D', '#FF8A5D',
            '#FF725D', '#FF595D', '#FF415D', '#FF5D63', '#FF6358'
        ]);
})();
