(function() {
    'use strict';

    angular
        .module('stpApp')
        .controller('ProjectController', ProjectController);

    ProjectController.$inject = ['$state', 'DataUtils', 'Project', 'ProjectSearch', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams', 'principalResource', 'Principal'];

    function ProjectController($state, DataUtils, Project, ProjectSearch, ParseLinks, AlertService, paginationConstants, pagingParams, principalResource, Principal) {

        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.openFile = DataUtils.openFile;
        vm.byteSize = DataUtils.byteSize;
        vm.isProjectFound = true;
        vm.login = principalResource.login;
        vm.authorities = principalResource.authorities
        
        var isAdmin = vm.authorities.includes('ROLE_ADMIN');
        isAdmin ? vm.login = null : vm.login = vm.login;

        loadAll();
        
        function loadAll () {
            if (pagingParams.search) {
                ProjectSearch.query({
                    query: pagingParams.search,
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            } else {
                Project.query({
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort(),
                    'user.equals': vm.login
                }, onSuccess, onError);
            }
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.projects = data;
                vm.page = pagingParams.page;

                if((vm.projects.length && !vm.searchQuery) || (!vm.projects.length && vm.searchQuery) || (vm.projects.length && vm.searchQuery)) {
                    vm.isProjectExist = true;
                }

                if(vm.isProjectExist && (!vm.projects.length && vm.searchQuery)){
                    vm.isProjectFound = false;
                }
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }

        function search(searchQuery) {
            if (!searchQuery){
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = '_score';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = null;
            vm.transition();
        }
    }
})();
