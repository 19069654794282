(function() {
    'use strict';

    angular
        .module('stpApp')
        .factory('licenseInvalidInterceptor', licenseInvalidInterceptor);

    licenseInvalidInterceptor.$inject = ['$q', '$injector'];

    function licenseInvalidInterceptor($q, $injector) {
        var service = {
            responseError: responseError
        };

        return service;

        function responseError(response) {
            if (response.status === 403 && response.data.type === 'http://www.jhipster.tech/problem/license-invalid') {
                var Principal = $injector.get('Principal');
                if (Principal.isAuthenticated()) {
                    var LicenseActivation = $injector.get('LicenseActivation');
                    LicenseActivation.activateLicense();
                }
            }
            return $q.reject(response);
        }
    }
})();
