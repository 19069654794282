(function () {
    'use strict';

    angular
        .module('stpApp')
        .factory('TileLayerAsyncService', TileLayerAsyncService);

    TileLayerAsyncService.$inject = ['ApplicationSetting'];

    function TileLayerAsyncService(ApplicationSetting) {
        return {
            getBaseMaps: getBaseMaps,
            getAdministrationWms: getAdministrationWms,
        };

        function getBaseMaps() {
            var _defaultBaseMap = {
                Klikpeta: L.tileLayer('https://map1.klikpeta.com/klikpeta_tiles/{z}/{x}/{y}.png', {
                    attribution: '&copy; <a href="http://klikpeta.com">Klikpeta.com</a> contributors',
                    minZoom: 0,
                    maxZoom: 20,
                    tileLayerName: 'Klikpeta',
                }),
            };

            function _createTileLayer(result, each) {
                if (each.layerType === 'TILE_LAYER' && each.isVisible) {
                    result[each.tileLayerName] = L.tileLayer(each.url, {
                        minZoom: 0,
                        maxZoom: 20,
                        tileLayerName: each.tileLayerName,
                        id: each.id ? each.id : null,
                        accessToken: each.accessToken ? each.accessToken : null,
                        attribution: each.attribution ? each.attribution : '',
                    });
                } else if (each.layerType === 'GOOGLE_LAYER' && each.isVisible) {
                    result[each.tileLayerName] = L.gridLayer.googleMutant({
                        minZoom: 0,
                        maxZoom: 20,
                        type: each.type,
                        styles: each.styles,
                        tileLayerName: 'Google Satelit',
                    });
                } else if (each.layerType === 'WMS_LAYER' && each.isVisible) {
                    result[each.tileLayerName] = L.tileLayer.wms(each.url, each.options);
                }
                return result;
            }

            function _onSuccess(data) {
                if (!data.length) {
                    return _defaultBaseMap;
                } else {
                    var __baseMaps = angular.fromJson(data[0].value);
                    _defaultBaseMap = __baseMaps.reduce(_createTileLayer, {});
                }

                return _defaultBaseMap;
            }

            function _onError() {
                return _defaultBaseMap;
            }

            return ApplicationSetting.query({ 'key.equals': 'baseMaps' })
                .$promise.then(_onSuccess)
                .catch(_onError);
        }

        function getAdministrationWms() {
            var _defaultAdministrationWms = {
                provinsi: L.tileLayer.wms(
                    'https://geoserver-00.klikpeta.com:8443/geoserver/gwc/service/wms',
                    {
                        maxZoom: 20,
                        layers: 'propinsi',
                        format: 'image/png',
                        transparent: 'true',
                        pane: 'overlayPane',
                    }
                ),
                kabupaten: L.tileLayer.wms(
                    'https://geoserver-00.klikpeta.com:8443/geoserver/gwc/service/wms',
                    {
                        maxZoom: 20,
                        layers: 'kabupaten',
                        format: 'image/png',
                        transparent: 'true',
                        pane: 'overlayPane',
                    }
                ),
                kecamatan: L.tileLayer.wms(
                    'https://geoserver-00.klikpeta.com:8443/geoserver/gwc/service/wms',
                    {
                        maxZoom: 20,
                        layers: 'kecamatan',
                        format: 'image/png',
                        transparent: 'true',
                        pane: 'overlayPane',
                    }
                ),
                kelurahan: L.tileLayer.wms(
                    'https://geoserver-00.klikpeta.com:8443/geoserver/gwc/service/wms',
                    {
                        maxZoom: 20,
                        layers: 'kelurahan',
                        format: 'image/png',
                        transparent: 'true',
                        pane: 'overlayPane',
                    }
                ),
            };

            function _createAmdLayer(result, each) {
                result[each.wmsName] = L.tileLayer.wms(each.url, each.options);
                return result;
            }

            function _onSuccess(data) {
                if (!data.length) {
                    return _defaultAdministrationWms;
                } else {
                    var __admLayers = angular.fromJson(data[0].value);
                    _defaultAdministrationWms = __admLayers.reduce(_createAmdLayer, {});
                    return _defaultAdministrationWms;
                }
            }

            function _onError() {
                return _defaultAdministrationWms;
            }

            return ApplicationSetting.query({ 'key.equals': 'admWms' })
                .$promise.then(_onSuccess)
                .catch(_onError);
        }
    }
})();
