(function () {
    'use strict';

    angular
        .module('stpApp')
        .controller('ChangeOutletFreqController', ChangeOutletFreqController);

    ChangeOutletFreqController.$inject = ['$scope', '$uibModalInstance', 'entity', 'selected', 'projectId', 'Project', 'maxDays'];

    function ChangeOutletFreqController($scope, $uibModalInstance, entity, selected, projectId, Project, maxDays) {
        var vm = this;

        vm.stop = [];
        vm.clear = clear;
        vm.save = save;
        vm.freqValidation = freqValidation;
        vm.selected = selected;
        vm.maxDays = maxDays;

        function init() {
            if (selected.size > 1) {
                vm.selected.forEach(function (eachSelected) {
                    vm.stop.push(eachSelected.properties);
                });
            } else {
                vm.stop.push(entity);

                var day = vm.stop[0].visitFreq.substring(0, vm.stop[0].visitFreq.indexOf('d'));
                var week = vm.stop[0].visitFreq.substring(vm.stop[0].visitFreq.indexOf('d') + 1, vm.stop[0].visitFreq.indexOf('w'));
                vm.day = parseInt(day);
                vm.week = parseInt(week);
                vm.name = vm.stop[0].name;
                vm.id = vm.stop[0].id;
            }
        }

        init();

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;
            var request = {
                stopIds: [],
                visitFreq: {
                    day: vm.day,
                    week: vm.week
                }
            }

            if (vm.stop.length > 1) {
                vm.stop.forEach(function (eachStop) {
                    request.stopIds.push(eachStop.stopId);
                });
            } else {
                request.stopIds.push(vm.stop[0].id);
            }

            Project.updateStopsFreq({ projectId: projectId }, request, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess(result) {
            mixpanel.track('Change Frequency Successful', {
                'stopCount': vm.stop.length,
                'targetVisitFreq': vm.day + 'd' + vm.week + 'w'
            });
            $scope.$emit('stpApp:stopUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        function freqValidation() {
            vm.invalidFreq = (vm.day > 1 && vm.week > 1) ? true : false;
        }
    }
})();
