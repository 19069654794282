(function () {
    'use strict';
    angular
        .module('stpApp')
        .factory('RoutePlanningModalFactory', RoutePlanningModalFactory);

    RoutePlanningModalFactory.$inject = ['$uibModal', 'RoutePlanningDataShare'];

    function RoutePlanningModalFactory($uibModal, RoutePlanningDataShare) {
        var vm = this;
        vm.sharedData = RoutePlanningDataShare;

        vm.service = {
            importModal: importModal,
            changeFreqModal: changeFreqModal,
            routeDetailModal: routeDetailModal,
            confirmBalancingModal: confirmBalancingModal,
            outletLockDialog: outletLockDialog,
            importStopModal: importStopModal,
            importStopModalOptimize: importStopModalOptimize,
            openCautionImportDialog: openCautionImportDialog,
            deleteTerritoryDialog: deleteTerritoryDialog,
            stopsTransferDialog: stopsTransferDialog,
            cannotDeleteTerritoryDialog: cannotDeleteTerritoryDialog,
            autoBalanceDialog: autoBalanceDialog,
            deleteStopDialog: deleteStopDialog,
            oneClickOptimization: oneClickOptimization,
            unassignStopModal: unassignStopModal,
            territoryCalculatorDialog: territoryCalculatorDialog,
            applyCalculationDialog: applyCalculationDialog,
            importRouteModal: importRouteModal,
            openImportRouteCautionDialog: openImportRouteCautionDialog
        };

        function importModal(totalVisit, dataStyle, project) {
            return $uibModal.open({
                animation: true,
                ariaLabelledBy: 'modal-title',
                ariaDescribedBy: 'modal-body',
                templateUrl: 'app/route/dialog/import-export-dialog.html',
                controller: 'ImportExportDialogController',
                controllerAs: 'vm',
                size: 'md',
                backdropClass: 'none',
                backdrop: 'static',
                resolve: {
                    totalVisit: function () {
                        return totalVisit;
                    },
                    entity: function() {
                        return project;
                    },
                    dataStyle: function () {
                        return dataStyle;
                    }
                }
            });
        }

        function changeFreqModal(feature, selectedRoute, id, maxDay) {
            return $uibModal.open({
                animation: true,
                ariaLabelledBy: 'modal-title',
                ariaDescribedBy: 'modal-body',
                templateUrl: 'app/route/change-outlet-freq/change-outlet-freq.html',
                controller: 'ChangeOutletFreqController',
                controllerAs: 'vm',
                size: 'md',
                backdropClass: 'none',
                resolve: {
                    entity: ['Stop', function (Stop) {
                        return Stop.get({ id: feature.id }).$promise;
                    }],
                    selected: function () {
                        // eslint-disable-next-line no-undef
                        var result = new Set();
                        if (selectedRoute.size) {
                            result = selectedRoute;
                        }
                        return result;
                    },
                    projectId: function () {
                        return id;
                    },
                    maxDays: function () {
                        return maxDay;
                    }
                }
            });
        }

        function routeDetailModal(filterBy, stopLimit, unassignStops) {
            return $uibModal.open({
                animation: true,
                templateUrl: 'app/route/routing-detail-list/routing-detail-list-dialog.html',
                controller: 'RoutingDetailListDialogController',
                controllerAs: 'vm',
                size: 'lg',
                backdropClass: 'none',
                backdrop: 'static',
                windowClass: 'custom-window-dialog',
                resolve: {
                    depotResource: ['Depot', '$stateParams', function (Depot, $stateParams) {
                        return Depot.query({ 'projectId': $stateParams.id }).$promise;
                    }],
                    filterBy: function () {
                        return filterBy; // ALL, OVERTIME, OVERSTOP
                    },
                    projectResource: ['Project', '$stateParams', function (Project, $stateParams) {
                        return Project.get({ id: $stateParams.id }).$promise;
                    }],
                    stopLimit: function () {
                        return stopLimit;
                    },
                    unassignStops: function () {
                        return unassignStops;
                    }
                }
            });
        }

        function confirmBalancingModal(features) {
            return $uibModal.open({
                animation: true,
                ariaLabelledBy: 'modal-title',
                ariaDescribedBy: 'modal-body',
                templateUrl: 'app/route/confirm-dialog/confirm-dialog.html',
                controller: 'ConfirmDialogController',
                controllerAs: 'vm',
                size: 'md',
                backdrop: 'static',
                resolve: {
                    stopGeojsonFeatures: function () {
                        return features;
                    },
                    view: function () {
                        return vm.sharedData.currentView;
                    }
                },
            });
        }

        function outletLockDialog(feature, selectedRoute, projectId){
            return $uibModal.open({
                animation: true,
                ariaLabelledBy: 'modal-title',
                ariaDescribedBy: 'modal-body',
                templateUrl: 'app/route/outlet-lock-dialog/outlet-lock-dialog.html',
                controller: 'OutletLockDialogController',
                controllerAs: 'vm',
                size: 'md',
                backdropClass: 'none',
                resolve: {
                    selected: function () {
                        return Array.from(selectedRoute);
                    },
                    stop: function() {
                        return feature;
                    },
                    projectId: function() {
                        return projectId;
                    }
                }
            });
        }

        function importStopModal(parsedFileResult, project) {
            return $uibModal.open({
                animation: true,
                ariaLabelledBy: 'modal-title',
                ariaDescribedBy: 'modal-body',
                templateUrl: 'app/route/import-stop-dialog/import-stop-dialog.html',
                controller: 'ImportStopDialogController',
                controllerAs: 'vm',
                size: 'xl',
                backdrop: 'static',
                resolve: {
                    parsedFileResult: parsedFileResult,
                    project: project
                },
                keyboard: false
            });
        }

        function openCautionImportDialog(params) {
            return $uibModal.open({
                animation: true,
                ariaLabelledBy: 'modal-title',
                ariaDescribedBy: 'modal-body',
                templateUrl: 'app/route/import-stop-dialog/import-stop-dialog-caution.html',
                controller: 'ImportStopDialogCautionController',
                controllerAs: 'vm',
                size: 'md',
                backdrop: 'static',
                resolve: {
                    invalidRow: function() {
                        return params.invalidRow;
                    },
                    tableData: function() {
                        return params.tableData;
                    },
                    project: function() {
                        return params.project;
                    },
                    valuesConfig: function() {
                        return params.valuesConfig;
                    }
                },
                keyboard: false
            });
        }

        function importStopModalOptimize() {
            return $uibModal.open({
                animation: true,
                ariaLabelledBy: 'modal-title',
                ariaDescribedBy: 'modal-body',
                templateUrl: 'app/route/import-stop-dialog/import-stop-dialog-optimize.html',
                controller: 'ImportStopDialogOptimizeController',
                controllerAs: 'vm',
                size: 'md',
                backdrop: 'static',
                keyboard: false
            });
        }

        function deleteTerritoryDialog(key, name, numOfLockedTerritory) {
            return $uibModal.open({
                animation: true,
                ariaLabelledBy: 'modal-title',
                ariaDescribedBy: 'modal-body',
                templateUrl: 'app/route/delete-territory/delete-territory-dialog.html',
                controller: 'DeleteTerritoryDialogController',
                controllerAs: 'vm',
                size: 'md',
                backdrop: 'static',
                resolve: {
                    key: function() {
                        return key;
                    },
                    name: function() {
                        return name;
                    },
                    numOfLockedTerritory: function() {
                        return numOfLockedTerritory;
                    }
                },
                keyboard: false
            });
        }

        function stopsTransferDialog(projectId, depot, keyOrigin, origin, territory) {
            return $uibModal.open({
                animation: true,
                ariaLabelledBy: 'modal-title',
                ariaDescribedBy: 'modal-body',
                templateUrl: 'app/route/delete-territory/stops-transfer-dialog.html',
                controller: 'StopsTransferDialogController',
                controllerAs: 'vm',
                size: 'md',
                backdrop: 'static',
                resolve: {
                    projectId: function() {
                        return projectId;
                    },
                    depot: function() {
                        return depot;
                    },
                    keyOrigin: function() {
                        return keyOrigin;
                    },
                    origin: function() {
                        return origin;
                    },
                    territory: function() {
                        return territory;
                    }
                },
                keyboard: false
            });
        }

        function cannotDeleteTerritoryDialog(params) {
            return $uibModal.open({
                animation: true,
                ariaLabelledBy: 'modal-title',
                ariaDescribedBy: 'modal-body',
                templateUrl: 'app/route/delete-territory/cannot-delete-territory-dialog.html',
                controller: 'CannotDeleteTerritoryDialogController',
                controllerAs: 'vm',
                size: 'md',
                backdrop: 'static',
                keyboard: false
            });
        }

        function autoBalanceDialog() {
            return $uibModal.open({
                animation: true,
                ariaLabelledBy: 'modal-title',
                ariaDescribedBy: 'modal-body',
                templateUrl: 'app/route/auto-balance-dialog/auto-balance-dialog.html',
                controller: 'AutoBalanceDialogController',
                controllerAs: 'vm',
                size: 'md',
                backdrop: 'static',
                keyboard: false,
                windowClass: 'auto-balance-dialog',
                resolve: {
                    sharedData: function () {
                        return vm.sharedData;
                    }
                },
            });
        }

        function deleteStopDialog(feature, selectedRoute, projectId) {
            return $uibModal.open({
                animation: true,
                ariaLabelledBy: 'modal-title',
                ariaDescribedBy: 'modal-body',
                templateUrl: 'app/route/delete-route-stop/delete-route-stop.html',
                controller: 'DeleteStopRouteController',
                controllerAs: 'vm',
                size: 'md',
                backdropClass: 'none',
                resolve: {
                    entity: ['Stop', function (Stop) {
                        return Stop.get({ id: feature.id }).$promise;
                    }],
                    selected: function () {
                        var result = new Set();
                        if (selectedRoute.size) {
                            result = Array.from(selectedRoute);
                        }
                        return result;
                    },
                }
            });
        }

        function oneClickOptimization(project, mapProp, balancingStatus) {
            return $uibModal.open({
                animation: true,
                ariaLabelledBy: 'modal-title',
                ariaDescribedBy: 'modal-body',
                templateUrl: 'app/route/one-click-optimize-dialog/one-click-optimize-dialog.html',
                controller: 'OneClickOptimzeDialogController',
                controllerAs: 'vm',
                size: 'lg',
                backdropClass: 'none',
                backdrop: 'static',
                resolve: {
                    sharedData: function () {
                        return vm.sharedData;
                    },
                    project: function() {
                        return project;
                    },
                    mapProp: function() {
                        return mapProp;
                    },
                    balancingStatus: function() {
                        return balancingStatus;
                    },
                },
                keyboard: false
            });
        }

        function unassignStopModal(stopIds, projectId) {
            return $uibModal.open({
                animation: true,
                ariaLabelledBy: 'modal-title',
                ariaDescribedBy: 'modal-body',
                templateUrl: 'app/route/unassign-dialog/unassign-dialog.html',
                controller: 'UnassignDialogController',
                controllerAs: 'vm',
                size: 'lg',
                backdropClass: 'none',
                resolve: {
                    stopIds: function() {
                        return stopIds;
                    },
                    projectId: function() {
                        return projectId;
                    }
                }
            });
        }

        function territoryCalculatorDialog(project, mapProp) {
            return $uibModal.open({
                animation: true,
                ariaLabelledBy: 'modal-title',
                ariaDescribedBy: 'modal-body',
                templateUrl: 'app/route/territory-calculator/territory-calculator-dialog.html',
                controller: 'TerritoryCalculatorDialogController',
                controllerAs: 'vm',
                size: 'md',
                backdrop: 'static',
                keyboard: false,
                windowClass: 'auto-balance-dialog',
                resolve: {
                    project: function () {
                        return project;
                    },
                    mapProp: function () {
                        return mapProp;
                    },
                },
            });
        }

        function applyCalculationDialog() {
            return $uibModal.open({
                animation: true,
                ariaLabelledBy: 'modal-title',
                ariaDescribedBy: 'modal-body',
                templateUrl: 'app/route/territory-calculator/confirm-apply-calculation-dialog.html',
                controller: 'ConfirmApplyCalculationController',
                controllerAs: 'vm',
                size: 'md',
                backdrop: 'static',
                keyboard: false,
            });
        }

        function importRouteModal(parsedFileResult, depot, project, mapProp) {
            return $uibModal.open({
                animation: true,
                ariaLabelledBy: 'modal-title',
                ariaDescribedBy: 'modal-body',
                templateUrl: 'app/route/import-route-dialog/import-route-dialog.html',
                controller: 'ImportRouteDialogController',
                controllerAs: 'vm',
                size: 'xl',
                backdrop: 'static',
                resolve: {
                    parsedFileResult: parsedFileResult,
                    project: project,
                    depot: depot,
                    mapProp: mapProp
                },
                keyboard: false
            });
        }

        function openImportRouteCautionDialog(params) {
            return $uibModal.open({
                animation: true,
                ariaLabelledBy: 'modal-title',
                ariaDescribedBy: 'modal-body',
                templateUrl: 'app/route/import-route-dialog/import-route-caution-dialog.html',
                controller: 'ImportRouteCautionDialogController',
                controllerAs: 'vm',
                size: 'md',
                backdrop: 'static',
                resolve: {
                    project: function() {
                        return params.project;
                    },
                    depot: function() {
                        return params.depot;
                    },
                    totalItems: function() {
                        return params.totalItems;
                    },
                    invalidData: function() {
                        return params.invalidData;
                    },
                    validData: function() {
                        return params.validData;
                    }

                },
                keyboard: false
            });
        }

        return vm.service;

    }
})();
