(function() {
    'use strict';

    angular
        .module('stpApp')
        .factory('DayGap', DayGap);

    function DayGap() {
        var dayGapCombination = [
            {
                maxDay: 1,
                mappings: [
                    {
                        daysInWeek: 1,
                        daysCombinations: [
                            {
                                days: [1],
                                excludedDays: [],
                            },
                        ],
                    },
                ],
            },
            {
                maxDay: 2,
                mappings: [
                    {
                        daysInWeek: 1,
                        daysCombinations: [
                            {
                                days: [1],
                                excludedDays: [],
                            },
                            {
                                days: [2],
                                excludedDays: [],
                            },
                        ],
                    },
                    {
                        daysInWeek: 2,
                        daysCombinations: [
                            {
                                days: [1, 2],
                                excludedDays: [],
                            },
                        ],
                    },
                ],
            },
            {
                maxDay: 3,
                mappings: [
                    {
                        daysInWeek: 1,
                        daysCombinations: [
                            {
                                days: [1],
                                excludedDays: [],
                            },
                            {
                                days: [2],
                                excludedDays: [],
                            },
                            {
                                days: [3],
                                excludedDays: [],
                            },
                        ],
                    },
                    {
                        daysInWeek: 2,
                        daysCombinations: [
                            {
                                days: [1, 3],
                                excludedDays: [],
                            },
                            {
                                days: [2, 3],
                                excludedDays: [3],
                            },
                        ],
                    },
                    {
                        daysInWeek: 3,
                        daysCombinations: [
                            {
                                days: [1, 2, 3],
                                excludedDays: [],
                            },
                        ],
                    },
                ],
            },
            {
                maxDay: 4,
                mappings: [
                    {
                        daysInWeek: 1,
                        daysCombinations: [
                            {
                                days: [1],
                                excludedDays: [],
                            },
                            {
                                days: [2],
                                excludedDays: [],
                            },
                            {
                                days: [3],
                                excludedDays: [],
                            },
                            {
                                days: [4],
                                excludedDays: [],
                            },
                        ],
                    },
                    {
                        daysInWeek: 2,
                        daysCombinations: [
                            {
                                days: [1, 3],
                                excludedDays: [],
                            },
                            {
                                days: [2, 4],
                                excludedDays: [],
                            },
                        ],
                    },
                    {
                        daysInWeek: 3,
                        daysCombinations: [
                            {
                                days: [1, 3, 4],
                                excludedDays: [],
                            },
                            {
                                days: [1, 2, 4],
                                excludedDays: [4],
                            },
                        ],
                    },
                    {
                        daysInWeek: 4,
                        daysCombinations: [
                            {
                                days: [1, 2, 3, 4],
                                excludedDays: [],
                            },
                        ],
                    },
                ],
            },
            {
                maxDay: 5,
                mappings: [
                    {
                        daysInWeek: 1,
                        daysCombinations: [
                            {
                                days: [1],
                                excludedDays: [],
                            },
                            {
                                days: [2],
                                excludedDays: [],
                            },
                            {
                                days: [3],
                                excludedDays: [],
                            },
                            {
                                days: [4],
                                excludedDays: [],
                            },
                            {
                                days: [5],
                                excludedDays: [],
                            },
                        ],
                    },
                    {
                        daysInWeek: 2,
                        daysCombinations: [
                            {
                                days: [1, 4],
                                excludedDays: [],
                            },
                            {
                                days: [2, 5],
                                excludedDays: [],
                            },
                            {
                                days: [3, 5],
                                excludedDays: [5],
                            },
                        ],
                    },
                    {
                        daysInWeek: 3,
                        daysCombinations: [
                            {
                                days: [1, 3, 5],
                                excludedDays: [],
                            },
                            {
                                days: [2, 4, 5],
                                excludedDays: [5],
                            },
                        ],
                    },
                    {
                        daysInWeek: 4,
                        daysCombinations: [
                            {
                                days: [1, 3, 4, 5],
                                excludedDays: [],
                            },
                            {
                                days: [2, 3, 4, 5],
                                excludedDays: [3, 4, 5],
                            },
                        ],
                    },
                    {
                        daysInWeek: 5,
                        daysCombinations: [
                            {
                                days: [1, 2, 3, 4, 5],
                                excludedDays: [],
                            },
                        ],
                    },
                ],
            },
            {
                maxDay: 6,
                mappings: [
                    {
                        daysInWeek: 1,
                        daysCombinations: [
                            {
                                days: [1],
                                excludedDays: [],
                            },
                            {
                                days: [2],
                                excludedDays: [],
                            },
                            {
                                days: [3],
                                excludedDays: [],
                            },
                            {
                                days: [4],
                                excludedDays: [],
                            },
                            {
                                days: [5],
                                excludedDays: [],
                            },
                            {
                                days: [6],
                                excludedDays: [],
                            },
                        ],
                    },
                    {
                        daysInWeek: 2,
                        daysCombinations: [
                            {
                                days: [1, 4],
                                excludedDays: [],
                            },
                            {
                                days: [2, 5],
                                excludedDays: [],
                            },
                            {
                                days: [3, 6],
                                excludedDays: [],
                            },
                        ],
                    },
                    {
                        daysInWeek: 3,
                        daysCombinations: [
                            {
                                days: [1, 3, 5],
                                excludedDays: [],
                            },
                            {
                                days: [2, 4, 6],
                                excludedDays: [],
                            },
                        ],
                    },
                    {
                        daysInWeek: 4,
                        daysCombinations: [
                            {
                                days: [1, 3, 5, 6],
                                excludedDays: [],
                            },
                            {
                                days: [2, 4, 5, 6],
                                excludedDays: [5, 6],
                            },
                        ],
                    },
                    {
                        daysInWeek: 5,
                        daysCombinations: [
                            {
                                days: [1, 3, 4, 5, 6],
                                excludedDays: [],
                            },
                            {
                                days: [2, 3, 4, 5, 6],
                                excludedDays: [3, 4, 5, 6],
                            },
                        ],
                    },
                    {
                        daysInWeek: 6,
                        daysCombinations: [
                            {
                                days: [1, 2, 3, 4, 5, 6],
                                excludedDays: [],
                            },
                        ],
                    },
                ],
            },
            {
                maxDay: 7,
                mappings: [
                    {
                        daysInWeek: 1,
                        daysCombinations: [
                            {
                                days: [1],
                                excludedDays: [],
                            },
                            {
                                days: [2],
                                excludedDays: [],
                            },
                            {
                                days: [3],
                                excludedDays: [],
                            },
                            {
                                days: [4],
                                excludedDays: [],
                            },
                            {
                                days: [5],
                                excludedDays: [],
                            },
                            {
                                days: [6],
                                excludedDays: [],
                            },
                            {
                                days: [7],
                                excludedDays: [],
                            },
                        ],
                    },
                    {
                        daysInWeek: 2,
                        daysCombinations: [
                            {
                                days: [1, 5],
                                excludedDays: [],
                            },
                            {
                                days: [2, 6],
                                excludedDays: [],
                            },
                            {
                                days: [3, 7],
                                excludedDays: [],
                            },
                            {
                                days: [4, 7],
                                excludedDays: [7],
                            },
                        ],
                    },
                    {
                        daysInWeek: 3,
                        daysCombinations: [
                            {
                                days: [1, 4, 7],
                                excludedDays: [],
                            },
                            {
                                days: [2, 5, 7],
                                excludedDays: [7],
                            },
                            {
                                days: [3, 6, 7],
                                excludedDays: [7],
                            },
                        ],
                    },
                    {
                        daysInWeek: 4,
                        daysCombinations: [
                            {
                                days: [1, 3, 5, 7],
                                excludedDays: [],
                            },
                            {
                                days: [2, 4, 6, 7],
                                excludedDays: [7],
                            },
                        ],
                    },
                    {
                        daysInWeek: 5,
                        daysCombinations: [
                            {
                                days: [1, 3, 5, 6, 7],
                                excludedDays: [],
                            },
                            {
                                days: [2, 4, 5, 6, 7],
                                excludedDays: [5, 6, 7],
                            },
                        ],
                    },
                    {
                        daysInWeek: 6,
                        daysCombinations: [
                            {
                                days: [1, 3, 4, 5, 6, 7],
                                excludedDays: [],
                            },
                            {
                                days: [2, 3, 4, 5, 6, 7],
                                excludedDays: [3, 4, 5, 6, 7],
                            },
                        ],
                    },
                    {
                        daysInWeek: 7,
                        daysCombinations: [
                            {
                                days: [1, 2, 3, 4, 5, 6, 7],
                                excludedDays: [],
                            },
                        ],
                    },
                ],
            },
        ];

        var service = {
            findDayGap: findDayGap,
            findDayOpts: findDayOpts,
        };

        return service;

        function findDayGap(maxDay, firstDay, dayFreq) {
            var findMaxDay = dayGapCombination.find(function(d){
                return d.maxDay === maxDay;
            });

            var findDayInWeek = findMaxDay.mappings.find(function(m){
                return m.daysInWeek === dayFreq;
            });

            var days = findDayInWeek.daysCombinations.find(function(dcs){
                var findFirstWeekInDays = dcs.days.find(function(d){
                    return d === firstDay;
                });
                return findFirstWeekInDays ? true : false;
            });

            return days.days;
        }

        function findDayOpts(maxDay, dayFreq) {
            var dayOpts = [];
            var findMaxDay = dayGapCombination.find(function (d) {
                return d.maxDay === maxDay;
            });

            var findDayInWeek = findMaxDay.mappings.find(function (m) {
                return m.daysInWeek === dayFreq;
            });

            findDayInWeek.daysCombinations.forEach(function (dcs) {
                var day = dcs.days[0];
                var isDayExist = dayOpts.find(function (d) {
                    return d == day;
                });

                if (!isDayExist) {
                    dayOpts.push(day);
                }
            });

            return dayOpts;
        }
    }
})();
