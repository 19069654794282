(function() {
    'use strict';
    angular
        .module('stpApp')
        .factory('BpsService', BpsService);

    BpsService.$inject = ['$resource'];

    function BpsService ($resource) {
        var resourceUrl =  'stpcore/' + 'api/jitu/';

        return $resource(resourceUrl, {}, {
            'getDataType': {
                url: resourceUrl + 'data-types',
                method: 'GET',
                isArray: true
            },
            'getYearByDataType': {
                url: resourceUrl + 'available-years',
                method: 'GET',
                isArray: true
            },
            'getCategoryByDataType': {
                url: resourceUrl + 'categories',
                method: 'GET',
                isArray: true
            },
            'getDataItemByCategory': {
                url: resourceUrl + 'data-items',
                method: 'GET',
                isArray: true
            },
        });
    }
})();
