(function () {
    "use strict";

    angular.module("stpApp").directive("balanceIndicator", balanceIndicator);

    function balanceIndicator() {
        var directive = {
            restrict: "E",
            scope: {
                indicatorType: "=indicatorType",
                value: "=value",
                total: "=total",
                count: "=count",
                isLoading: "=isLoading"
            },
            link: link,
            templateUrl: "app/route/directives/balance-indicator.html",
        };

        return directive;

        function link(scope) {
            scope.$watch('total', function(){
                init();
            });
            scope.$watch('count', function(){
                init();
            });
            scope.$watch('value', function(){
                init();
            });

            scope.balancePoint = 0;

            function getScaleColor() {
                if (scope.scale1 || scope.scale5) {
                    return '#EB5757';
                } else if(scope.scale2 || scope.scale4){
                    return '#F2994A';
                } else {
                    return '#27AE60';
                }
            }

            function getScale() {
                if (scope.scale1) {
                    return 1;
                } else if(scope.scale2) {
                    return 2;
                } else if(scope.scale3) {
                    return 3;
                } else if(scope.scale4) {
                    return 4;
                } else {
                    return 5;
                }
            }

            function getTooltipScale() {
                if (scope.scale1) {
                    return 'stpApp.route.tooltips.veryInadequate';
                } else if(scope.scale2) {
                    return 'stpApp.route.tooltips.inadequate';
                } else if(scope.scale3) {
                    return 'stpApp.route.tooltips.balanced';
                } else if(scope.scale4) {
                    return 'stpApp.route.tooltips.adequate';
                } else {
                    return 'stpApp.route.tooltips.excessive';
                }
            }

            function getIndicatorTypeTooltip() {
                if(scope.indicatorType === 'OUTLET') {
                    return 'stpApp.route.tooltips.numberOfStopData';
                } else if(scope.indicatorType === 'VISIT') {
                    return 'stpApp.route.tooltips.visits';
                } else if(scope.indicatorType === 'VALUE1') {
                    return 'Value 1';
                } else if(scope.indicatorType === 'VALUE2') {
                    return 'Value 2';
                } else if(scope.indicatorType === 'VALUE3') {
                    return 'Value 3';
                } else if(scope.indicatorType === 'DISTANCE') {
                    return 'stpApp.route.tooltips.distance';
                } else if(scope.indicatorType === 'TRAVEL_TIME') {
                    return 'stpApp.route.tooltips.travelTime';
                } else if(scope.indicatorType === 'CAPACITY') {
                    return 'stpApp.route.tooltips.capacity';
                } else {
                    return '';
                }
            }

            function init() {
                if (scope.total === 0) {
                    scope.balancePoint = 0.8;
                }

                if (scope.total > 0) {
                    scope.balancePoint = (scope.value ? scope.value : 0) * scope.count / scope.total;
                }

                scope.scale1 = scope.balancePoint >= 0 && scope.balancePoint < 0.4;
                scope.scale2 = scope.balancePoint >= 0.4 && scope.balancePoint < 0.8;
                scope.scale3 = scope.balancePoint >= 0.8 && scope.balancePoint < 1.2;
                scope.scale4 = scope.balancePoint >= 1.2 && scope.balancePoint < 1.6;
                scope.scale5 = scope.balancePoint >= 1.6;

                scope.defaultColor = '#E0E0E0';
                scope.getScaleColor = getScaleColor;
                scope.getScale = getScale;
                scope.getTooltipScale = getTooltipScale;
                scope.getIndicatorTypeTooltip = getIndicatorTypeTooltip;
            }
        }
    }
})();
