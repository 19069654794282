(function () {
    'use strict';
    angular
        .module('stpApp')
        .factory('OutletAssignmentService', OutletAssignmentService);

    OutletAssignmentService.$inject = ['$resource'];

    function OutletAssignmentService($resource) {
        var resourceUrl =  'stpcore/' + 'api/projects/:id';
        return $resource(resourceUrl, {}, {
            'assign': {
                url: resourceUrl + '/outlets%3Aassign',
                method: 'POST'
            },
            'unassign': {
                url: resourceUrl + '/outlets%3Aunassign',
                method: 'POST'
            },
        });
    }
})();
