(function() {
    'use strict';

    var parangSync = {
        templateUrl: 'app/route/parang-sync/parang-sync.html',
        controller: parangSyncController,
        controllerAs: 'vm',
        bindings: {
            onSyncSuccess: '&',
            projectId: '<'
        }
    };

    angular
        .module('stpApp')
        .component('parangSync', parangSync);

    parangSyncController.$inject = ['$uibModal', 'SfaIntegration', 'Auth', '$interval', '$timeout', '$window'];

    function parangSyncController ($uibModal, SfaIntegration, Auth, $interval, $timeout, $window) {
        var vm = this;

        /* properties */
        vm.ftrSync = {
            reset: false,
            sync: false,
            upload: true,
            openSync: false
        }
        vm.syncStatus = {
            inProgress: false,
            conflict: false,
            noConflict: false,
            success: false,
            failed: false,
            ticker: false
        }
        vm.info = {
            outletAdded: 0,
            outletRemoved: 0,
            visitFreqChanged: [
                {count: 4, from: '1d1w', to: '1d2w'},
                {count: 1, from: '1d1w', to: '1d4w'},
            ],
            maxWeekChanged: {
                from: 4,
                to: 8
            }
        }
        vm.tick = 3;
        vm.statusTitle;
        vm.statusText;

        /* method */
        vm.openSyncDialog = openSyncDialog;
        vm.openRequestSyncDialog = openRequestSyncDialog;
        vm.openResetDialog = openResetDialog;
        vm.checkDiffAndSynchronize = checkDiffAndSynchronize;
        vm.checkDiffAndSend = checkDiffAndSend;
        vm.openUploadDialog = openUploadDialog;

        function openSyncDialog() {
            var syncDialog = $uibModal.open({
                animation: true,
                templateUrl: 'app/route/parang-sync/modals/sync-dialog.html',
                controller: 'SyncDialogController',
                controllerAs: 'vm',
                resolve: {},
                keyboard: false,
                backdrop: 'static'
            });
            syncDialog.result.then(synchronize);
        }

        function openRequestSyncDialog() {
            var syncRequestDialog = $uibModal.open({
                animation: true,
                templateUrl: 'app/route/parang-sync/modals/sync-request-dialog.html',
                controller: 'SyncRequestDialogController',
                controllerAs: 'vm',
                resolve: {},
                keyboard: false,
                backdrop: 'static'
            });
            syncRequestDialog.result.then();
        }

        function openResetDialog() {
            var resetDialog = $uibModal.open({
                animation: true,
                templateUrl: 'app/route/parang-sync/modals/reset-dialog.html',
                controller: 'ResetDialogController',
                controllerAs: 'vm',
                resolve: {
                    projectId: function() {
                        return vm.projectId;
                    }
                },
                keyboard: false,
                backdrop: 'static'
            });
            resetDialog.result.then(_onModalResetClose);

            function _onModalResetClose() {
                changeSyncStatus('inProgress');
                vm.statusTitle = 'stpApp.syncParang.status.loadingTitle.reset';
                SfaIntegration.reset({projectId : vm.projectId}, null, _onResetSuccess, _onResetFailed);
            }

            function _onResetSuccess() {
                changeSyncStatus('success');
                vm.statusTitle = 'stpApp.syncParang.menu.reset';
                vm.statusText = 'stpApp.syncParang.status.success.reset';
                vm.onSyncSuccess();
            }

            function _onResetFailed(error) {
                vm.statusTitle = 'stpApp.syncParang.menu.reset';
                vm.statusText = 'stpApp.syncParang.status.failed.reset';
                changeSyncStatus('failed');
            }
        }

        function checkDiffAndSynchronize() {
            if (vm.syncStatus.conflict) {
                vm.ftrSync.openSync = true;
                return;
            }

            changeSyncStatus('inProgress');
            vm.statusTitle = 'stpApp.syncParang.status.loadingTitle.check';

            SfaIntegration.getCurrentDiff({projectId: vm.projectId}, null, _onGetCurrentDiffSuccess, _onGetCurrentDiffFailed);

            function _onGetCurrentDiffSuccess(data) {
                if (data.hasConflict) {
                    vm.info = data;
                    changeSyncStatus('conflict');
                } else {
                    synchronize();
                }
            }

            function _onGetCurrentDiffFailed() {
                vm.statusTitle = 'stpApp.syncParang.menu.synchronize';
                vm.statusText = 'stpApp.syncParang.status.failed.check';
                changeSyncStatus('failed');
            }
        }

        function checkDiffAndSend() {
            if (vm.syncStatus.conflict) {
                vm.openRequestSyncDialog();
                return;
            }

            changeSyncStatus('inProgress');
            vm.statusTitle = 'stpApp.syncParang.status.loadingTitle.check';

            SfaIntegration.getCurrentDiff({projectId: vm.projectId}, _onGetCurrentDiffSuccess, _onGetCurrentDiffFailed);

            function _onGetCurrentDiffSuccess(data) {
                if (data.hasConflict) {
                    vm.openRequestSyncDialog();
                    changeSyncStatus('conflict', false);
                    vm.info = data;
                } else {
                    vm.statusTitle = 'stpApp.syncParang.status.loadingTitle.send';
                    SfaIntegration.sendAndDeleteProject({projectId: vm.projectId}, null, _onSendAndDeleteProjectSuccess, _onSendAndDeleteProjectFailed);
                }
            }

            function _onGetCurrentDiffFailed() {
                changeSyncStatus('failed');
                vm.statusTitle = 'stpApp.syncParang.menu.sendToParang';
                vm.statusText = 'stpApp.syncParang.status.failed.check';
            }

            function _onSendAndDeleteProjectSuccess() {
                changeSyncStatus('success');
                vm.statusTitle = 'stpApp.syncParang.menu.sendToParang';
                vm.statusText = 'stpApp.syncParang.status.success.send';
                $timeout(function(){
                    changeSyncStatus('ticker');
                    vm.tickInterval = $interval(_countDownTicker, 1000);
                }, 2000)
            }

            function _onSendAndDeleteProjectFailed() {
                vm.statusTitle = 'stpApp.syncParang.menu.sendToParang';
                vm.statusText = 'stpApp.syncParang.status.failed.send';
                changeSyncStatus('failed');
            }

            function _logoutAndCloseWindow() {
                Auth.logout();
                $window.close();
            }

            function _countDownTicker() {
                vm.tick -= 1;
                if (vm.tick == 0) {
                    $interval.cancel(vm.tickInterval);
                    _logoutAndCloseWindow();
                }
            }
        }

        function changeSyncStatus(syncStatusProp, isOpened) {
            vm.ftrSync.openSync = isOpened == undefined ? true : isOpened;
            vm.syncStatus = {
                inProgress: false,
                conflict: false,
                noConflict: false,
                success: false,
                failed: false
            }
            vm.syncStatus[syncStatusProp] = true;
        }

        function synchronize() {
            if(vm.syncStatus.conflict) {
                changeSyncStatus('inProgress');
                vm.statusTitle = 'stpApp.syncParang.status.loadingTitle.sync';
            }
            SfaIntegration.synchronize({projectId: vm.projectId}, null, _onSynchronizeSuccess, _onSynchronizeFailed);
            vm.statusTitle = 'stpApp.syncParang.status.loadingTitle.sync';
            function _onSynchronizeSuccess() {
                vm.statusTitle = 'stpApp.syncParang.menu.synchronize';
                vm.statusText = 'stpApp.syncParang.status.success.sync';
                changeSyncStatus('success');
                vm.onSyncSuccess();
            }

            function _onSynchronizeFailed() {
                vm.statusTitle = 'stpApp.syncParang.menu.synchronize';
                vm.statusText = 'stpApp.syncParang.status.failed.sync';
                changeSyncStatus('failed');
            }
        }

        function openUploadDialog() {
            if (vm.syncStatus.conflict) {
                vm.openRequestSyncDialog();
                return;
            }
            var uploadDialog = $uibModal.open({
                animation: true,
                templateUrl: 'app/route/parang-sync/modals/upload-dialog.html',
                controller: 'UploadDialogController',
                controllerAs: 'vm',
                resolve: {},
                keyboard: false,
                backdrop: 'static'
            });
            uploadDialog.result.then(checkDiffAndSend);
        }
    }
})();
