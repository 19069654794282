(function () {
    'use strict';
    angular
        .module('stpApp')
        .factory('LoginFactory', LoginFactory);

    LoginFactory.$inject = ['$uibModal', 'Principal'];

    function LoginFactory($uibModal, Principal) {
        var vm = this;

        vm.service = {
            validationUser: validationUser,
        }

        function _initiateUserTracked(account) {
            if (!account) return;
            mixpanel.identify(account.id);
            var firstName = account.firstName ? account.firstName + ' ' : '';
            var lastName = account.lastName ? account.lastName : '';
            mixpanel.people.set({
                '$name': firstName + lastName,
                'Username': account.login,
                '$email': account.email
            });
        }

        function validationUser() {
            Principal.identity().then(function (account) {
                if (account) {

                    _initiateUserTracked(account);

                    // jika data user belum lengkap, open modal
                    var invalid =  account.firstName == '' || account.lastName == '' || account.email == '' || account.phoneNumber == '' || account.workplace == '' ||
                                    account.firstName == null || account.lastName == null || account.email == null || account.phoneNumber == null || account.workplace == null;
    
                    // abaikan jika role user ROLE_ONE_TIME_USER
                    var roleOneTimeUser = account.authorities.some(function (role) {
                        return role === 'ROLE_ONE_TIME_USER'
                    });
                    
                    if (roleOneTimeUser) {
                        invalid = false
                    }
    
                    if (invalid) {
                        var dialog = $uibModal.open({
                            animation: true,
                            templateUrl: 'app/admin/user-management/user-management-force-dialog.html',
                            controller: 'UserManagementDialogForceController',
                            controllerAs: 'vm',
                            size: 'lg',
                            keyboard: false,
                            backdrop: 'static',
                            resolve: {
                                entity: function() {
                                    return account;
                                },
                                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                                    $translatePartialLoader.addPart('user-management');
                                    $translatePartialLoader.addPart('settings');
                                    return $translate.refresh();
                                }]
                            }
                        });
                    }
                }
            }) 
        }

        return vm.service;

    }
})();
