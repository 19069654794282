(function() {
    'use strict';

    angular
        .module('stpApp')
        .controller('SyncRequestDialogController',SyncRequestDialogController);

    SyncRequestDialogController.$inject = ['$uibModalInstance'];

    function SyncRequestDialogController($uibModalInstance) {
        var vm = this;

        vm.clear = clear;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
    }
})();
