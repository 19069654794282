(function () {
    'use strict';

    angular
        .module('stpApp')
        .factory('ApplicationSetting', ApplicationSetting);

    ApplicationSetting.$inject = ['$resource'];

    function ApplicationSetting ($resource) {
        var resourceUrl = 'stpcore/' + 'api/application-settings/:id';

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'getAppProp': {
                url: 'stpcore/' + 'api/app-props',
                mehtod: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': {method: 'PUT'}
        });
    }
})();
