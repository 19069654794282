(function() {
    'use strict';

    angular
        .module('stpApp')
        .controller('StopDetailController', StopDetailController);

    StopDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'DataUtils', 'entity', 'Stop', 'Project'];

    function StopDetailController($scope, $rootScope, $stateParams, previousState, DataUtils, entity, Stop, Project) {
        var vm = this;

        vm.stop = entity;
        vm.previousState = previousState.name;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;
        vm.milisMultiple = 60000;

        var unsubscribe = $rootScope.$on('stpApp:stopUpdate', function(event, result) {
            vm.stop = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
