(function () {
    "use strict";

    angular
        .module("stpApp")
        .controller("ConfirmDialogController", ConfirmDialogController);

    ConfirmDialogController.$inject = ["$uibModalInstance", "stopGeojsonFeatures", "view"];

    function ConfirmDialogController($uibModalInstance, stopGeojsonFeatures, view) {
        var vm = this;

        vm.cancel = cancel;
        vm.agree = agree;
        vm.view = view;
        
        vm.isSomeStopHasMultiWeek = stopGeojsonFeatures.some(function (stopFeature) {
            var visitFreq = stopFeature.properties.stopVisitFreq;
            return visitFreq.indexOf("1w") == -1;
        });
        
        vm.isSomeStopHasMultiDay = stopGeojsonFeatures.some(function (stopFeature) {
            var visitFreq = stopFeature.properties.stopVisitFreq;
            return visitFreq.indexOf("1d") == -1;
        });
        
        function cancel() {
            $uibModalInstance.dismiss("cancel");
        }

        function agree() {
            $uibModalInstance.close("cancel");
        }
    }
})();
