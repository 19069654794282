(function () {
    'use strict';
    angular
        .module('stpApp')
        .factory('AdmarService', AdmarService);

    AdmarService.$inject = ['$resource'];

    function AdmarService($resource) {
        var resourceUrl = 'stpadmar/' + 'api/areas%3aintersectingBBox';

        return $resource(resourceUrl, {}, {
            'getAdmAreaByBBox': { method: 'POST' },
            'getAreaByPoints': {
                url: 'stpadmar/' + 'api/areas%3agetAreaIntersectingPoints',
                method: 'POST',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            }
        });
    }
})();
