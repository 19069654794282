(function() {
    'use strict';

    var jituBpsData = {
        templateUrl: 'app/route/jitu-bps-data/jitu-bps-data.html',
        controller: jituBpsDataController,
        controllerAs: 'vm',
        bindings: {
            projectProp: '<',
            project: '<',
            depot: '<',
            territories: '<',
            weeks: '<',
            days: '<',
            isVisible: '<'
        }
    };

    angular
        .module('stpApp')
        .component('jituBpsData', jituBpsData);

    jituBpsDataController.$inject = ['$scope', 'JituBpsStatistic', '$rootScope'];

    function jituBpsDataController ($scope, JituBpsStatistic, $rootScope) {
        var vm = this;

        vm.$onInit = $onInit;
        vm.$onDestroy = $onDestroy;
        vm.$onChanges = $onChanges;
        vm.bpsStatistic;
        vm.loadingBpsStatistic = false;
        vm.tempTerritories;
        vm.getJituBpsStatistic = getJituBpsStatistic;

        function $onInit() {
            vm.tempTerritories = angular.copy(vm.territories);
            vm.tempDays = angular.copy(vm.days);
            vm.tempWeeks = angular.copy(vm.weeks);
        }

        function $onChanges(changes) {
            // load bps data for first time
            if(vm.isVisible && !vm.loadingBpsStatistic && !vm.bpsStatistic) {
                getJituBpsStatistic();
            }

            // load bps data if it has update
            if(vm.isVisible && !vm.loadingBpsStatistic && vm.hasUpdate) {
                getJituBpsStatistic();
            }
        }

        function $onDestroy() {
            vm.changeView();
            vm.bpsSettingUpdate();
        }

        function getJituBpsStatistic() {
            vm.loadingBpsStatistic = true;
            vm.hasUpdate = false;
            vm.dataNotFound = false;
            vm.failedLoadData = false;


            var params = {
                depot: vm.depot,
                project: vm.project,
                territories: vm.territories,
                weeks: vm.weeks,
                days: vm.days
            }

            JituBpsStatistic.get(params, _onSuccess, _onFailed);

            function _onSuccess(data) {
                vm.bpsStatistic = data.map(function (item) {
                    return {
                        name: item.name,
                        value: item.value
                    }
                });
                vm.loadingBpsStatistic = false;
            }

            function _onFailed(error) {
                if(error.status == 404) {
                    vm.dataNotFound = true;
                } else {
                    vm.failedLoadData = true;
                }
                vm.loadingBpsStatistic = false;
            }
        }

        vm.changeView  = $scope.$on('change-view', function(){
            if(vm.tempTerritories != vm.territories) {
                vm.tempTerritories = angular.copy(vm.territories);
                triggerUpdate();
            }

            if(vm.tempWeeks != vm.weeks) {
                vm.tempTerritories = angular.copy(vm.territories);
                triggerUpdate();
            }

            if(vm.tempDays != vm.days) {
                vm.tempTerritories = angular.copy(vm.territories);
                triggerUpdate();
            }
        });
        vm.stopUpdate = $scope.$on('update-stop', triggerUpdate);

        vm.bpsSettingUpdate = $rootScope.$on('update-bps-setting', triggerUpdate);

        function triggerUpdate() {
            vm.hasUpdate = true;
        }
    }
})();
