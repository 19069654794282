(function() {
    'use strict';

    angular
        .module('stpApp')
        .controller('StopDeleteController',StopDeleteController);

    StopDeleteController.$inject = ['$uibModalInstance', 'entity', 'Stop'];

    function StopDeleteController($uibModalInstance, entity, Stop) {
        var vm = this;

        vm.stop = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            Stop.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
