(function() {
    'use strict';
    angular
        .module('stpApp')
        .factory('ExportStopRoute', ExportStopRoute);

    ExportStopRoute.$inject = ['$resource'];

    function ExportStopRoute ($resource) {

        return $resource('', {}, {
            'stop': {
                url: 'stpcore/' + 'api/stops/export/csv',
                method: 'GET',
                responseType: 'arraybuffer',
                transformResponse: function (data) {
                    return {
                        // return blob object
                        data: new Blob([data], {type: 'text/csv'})
                    }
                }
            },
            'route': {
                url: 'stpcore/' + 'api/routes/export/csv',
                method: 'GET',
                responseType: 'arraybuffer',
                transformResponse: function (data) {
                    return {
                        // return blob object
                        data: new Blob([data], {type: 'text/csv'})
                    }
                }
            },
            'routeDetail': {
                url: 'stpcore/' + 'api/projects/:projectId/daily-route-stats',
                mehtod: 'GET',
                isArray: true
            },
            'routePotentialData': {
                url: 'stpcore/' + 'api/projects/:projectId/daily-route-stats-with-potential-data',
                mehtod: 'GET',
                isArray: true
            },
            'routeLock': {
                url: 'stpcore/' + 'api/projects/:projectId/outlets',
                mehtod: 'GET',
                isArray: true
            },
            'update': { method:'PUT' }
        });
    }
})();
