(function() {
    'use strict';

    var settingPoi = {
        templateUrl: 'app/route/setting-poi/setting-poi.component.html',
        controller: settingPoiController,
        controllerAs: 'vm',
        bindings: {
            onSettingPoiSave: '&',
            project: '<'
        }
    };

    angular
        .module('stpApp')
        .component('settingPoi', settingPoi);

    settingPoiController.$inject = ['$rootScope', '$translate', 'Project', 'AlertService', '$scope'];

    function settingPoiController ($rootScope, $translate, Project, AlertService, $scope) {
        var vm = this;
        vm.$onInit = onInit;
        vm.$onChanges = onChange;

        vm.checkAllPoi = false;
        vm.search = ''
        vm.poi = [];

        vm.onCheckedAllPoi = onCheckedAllPoi;
        vm.onCheckedPoi = onCheckedPoi;
        vm.onCheckedSubClassPoi = onCheckedSubClassPoi;
        vm.onSearch = onSearch;
        vm.onSave = onSave;



        function onInit() {
            _getPoi();
            vm.currentLang = $translate.use();
        }

        function onChange() {
        }

        function onSearch() {
            if (vm.search.length) {
                vm.poi.forEach(function (poi) {
                    poi.isOpen = true
                });
            }else {
                vm.poi.forEach(function (poi) {
                    poi.isOpen = false
                });
            }
        }


        /**
         * Checked All Poi
         *
         * @returns {void}
         */
        function onCheckedAllPoi() {
            var every = vm.poi.every(function (poi) { return poi.selected });
            var some = vm.poi.some(function (poi) { return poi.selected });
            if (!every && !some) {
                vm.poi.forEach(function (poi) {
                    poi.partial = false;
                    poi.selected = true;
                    poi.subClass.forEach(function (sub) {
                        sub.selected = true;
                    });
                });
            }else if(!every && some){
                vm.poi.forEach(function (poi) {
                    poi.partial = false;
                    poi.selected = true;
                    poi.subClass.forEach(function (sub) {
                        sub.selected = true;
                    });
                });
            }else {
                vm.poi.forEach(function (poi) {
                    poi.partial = false;
                    poi.selected = false;
                    poi.subClass.forEach(function (sub) {
                        sub.selected = false;
                    });
                });
            }
        }

        /**
         * Checked poi
         *
         * @param {object} poi current poi selected
         * @returns {void}
         */
        function onCheckedPoi(poi) {
            var every = poi.subClass.every(function (sub) { return sub.selected });
            var some = poi.subClass.some(function (sub) { return sub.selected });
            if (!every && !some) {
                poi.subClass.forEach(function (sub) {
                    sub.partial = false;
                    sub.selected = true;
                });
            }else if(!every && some){
                poi.subClass.forEach(function (sub) {
                    poi.partial = false;
                    sub.selected = true;
                });
            }else {
                poi.subClass.forEach(function (sub) {
                    sub.partial = false;
                    sub.selected = false;
                });
            }

            // check every all poi selected true
            var everyAllPoi = vm.poi.every(function (poi) { return poi.selected });
            if (everyAllPoi) {
                vm.checkAllPoi = true;
            }else{
                vm.checkAllPoi = false;
            }
        }

        /**
         * Checked sub class poi
         *
         * @param {object} poi current poi selected
         * @returns {void}
         */
        function onCheckedSubClassPoi(poi) {
            var every = poi.subClass.every(function (sub) { return sub.selected });
            var some = poi.subClass.some(function (sub) { return sub.selected });
            if (!every && some) {
                poi.partial = true;
                poi.selected = false;
            }else if(!every && !some){
                poi.partial = false;
                poi.selected = false;
            }else {
                poi.partial = false;
                poi.selected = true;
            }

            // check every all poi selected true
            var everyAllPoi = vm.poi.every(function (poi) { return poi.selected });
            if (everyAllPoi) {
                vm.checkAllPoi = true;
            }else{
                vm.checkAllPoi = false;
            }
        }

        /**
         * Save setting poi
         *
         * @returns {void}
         */
        function onSave() {
            vm.project.props.selectedPOIs = vm.poi.filter(function (poi) {
                return poi.selected || poi.partial
            }).map(function (poi) {
                return {
                    id : poi.id,
                    subClassificationIds : poi.subClass.filter(function (sub) {
                        return sub.selected
                    }).map(function (sub) {
                        return sub.id
                    })
                }
            })

            Project.update(vm.project, onSaveSuccess, onSaveError);

            function onSaveSuccess (response) {
                var result = {
                    status: 200,
                    message: 'Successfully update setting poi',
                    data: response
                }
                vm.onSettingPoiSave({settingPoiSave: result});
                AlertService.closeAlert();
                AlertService.success(result.message, {}, 'top-right');
                $rootScope.$broadcast('update-poi-setting')
            }
            function onSaveError (error) {
                AlertService.closeAlert()
                AlertService.error(error.message)
            }
        }


        /**
         * Get Poi from api
         * @return {void} array POI setting
         */
        function _getPoi() {
            Project.getPoiSetting(function (data) {
                vm.poi = data.map(function (poi) {
                    var findPoi = vm.project.props.selectedPOIs.find(function (item) { return item.id === poi.id });

                    var result = {
                        id: poi.id,
                        // name: lang === 'en' ? poi.nameEn : poi.name,
                        nameEn : poi.nameEn,
                        nameId : poi.name,
                        selected : false,
                        isOpen : false,
                        partial : false,
                        subClass : poi.subClassifications.map(function (sub) {
                            var findSubPoi;
                            if (findPoi) {
                                findSubPoi = findPoi.subClassificationIds.find(function (item) { return item === sub.id });
                            }
                            return {
                                id: sub.id,
                                // name: lang === 'en' ? sub.nameEn : sub.name,
                                nameEn : sub.nameEn,
                                nameId : sub.name,
                                selected : findSubPoi ? true : false,
                            }
                        })
                    }

                    // check all subClassification selected or not
                    if (findPoi) {
                        if (findPoi.subClassificationIds.length === result.subClass.length) {
                            result.selected = true;
                        } else {
                            result.partial = true;
                        }
                    }
                    return result
                });

                // check all poi selected
                vm.checkAllPoi = vm.poi.every(function (poi) { return poi.selected });
            });
        }

        // listen language has change
        $rootScope.$on('$translateChangeSuccess', function(event, current, previous) {
            vm.currentLang = current.language;
        });
    }
})();
