(function() {
    'use strict';

    angular
        .module('stpApp')
        .factory('ConcurrentModalService', ConcurrentModalService);

    ConcurrentModalService.$inject = ['$injector'];

    function ConcurrentModalService ($injector) {
        var service = {
            openModal: openModal,
        };
        var modal = null;

        return service;

        function openModal () {
            if (modal) {
                return;
            }

            modal = $injector.get('$uibModal').open({
                templateUrl: 'app/components/concurrent-user/concurrent-user-dialog.html',
                controller: 'ConcurrentDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                keyboard : false,
            }).result.then(function() {
                modal = null;
            }, function() {
                modal = null;
            });
        }
    }
})();
