(function () {
    "use strict";

    angular.module("stpApp").config(toastConfig);

    toastConfig.$inject = ["ngToastProvider"];

    function toastConfig(ngToastProvider) {
        ngToastProvider.configure({
            verticalPosition: "top",
            horizontalPosition: "center",
            maxNumber: 3,
        });
    }
})();
