(function () {
    "use strict";

    angular
        .module('stpApp')
        .constant('drsTypeConstants', {
            'OVERSIZE': 'OVERSIZE',
            'OVERTIME': 'OVERTIME',
            'OVERCAPACITY': 'OVERCAPACITY',
            'FEASIBLE': 'FEASIBLE'
        });

    angular.module("stpApp").factory("DailyRouteStatService", DailyRouteStatService);

    DailyRouteStatService.$inject = ["$resource", 'drsTypeConstants'];



    function DailyRouteStatService ($resource, drsTypeConstants) {
        var resourceUrl =  'stpcore/' + 'api/projects/:projectId';

        return $resource(resourceUrl, {}, {
            'getListOfPerDayStats': { //
                url: resourceUrl + '/depots/:depotId/perday-route-stats',
                method: 'GET',
                isArray: true,
                transformResponse : function(data, headersGetter, status) {
                    if (status === 200) {
                        var responseData = angular.fromJson(data);
                        responseData.forEach(function(r){
                            var _finishTimeDate = moment("1970-01-01 " + r.finishAtDepotTime).add(parseInt(r.overnight), "days");
                            var _endTimeDate = moment("1970-01-01 " + r.dayTimeWindow.endTime);

                            if (_finishTimeDate.isAfter(_endTimeDate)) {
                                r.type = drsTypeConstants.OVERTIME;
                            } else {
                                r.type = drsTypeConstants.FEASIBLE;
                            }
                        });
                        return responseData;
                    } else {
                        return data;
                    }
                }
            },
            'getListOvertimeSummaries':{
                url: resourceUrl + '/daily-route-stats/overtime-summaries',
                mehtod: 'GET',
                isArray: true
            },
            'getListOversizeSummaries':{
                url: resourceUrl + '/daily-route-stats/oversize-summaries',
                mehtod: 'GET',
                isArray: true
            },
            'getListOvercapacitySummaries':{
                url: resourceUrl + '/daily-route-stats/overcapacity-summaries',
                mehtod: 'GET',
                isArray: true
            },
            'getListOfPerDayOvercapacityRoutes':{ //
                url: resourceUrl + '/depots/:depotId/overcapacity-routes',
                mehtod: 'GET',
                isArray: true,
                transformResponse : function(data, headersGetter, status) {
                    if (status === 200) {
                        var responseData = angular.fromJson(data);
                        responseData.forEach(function(r){r.type = drsTypeConstants.OVERCAPACITY;});
                        return responseData;
                    } else {
                        return data;
                    }
                }
            },
            'getListOfPerDayOversizedRoutes': { //
                url: resourceUrl + '/depots/:depotId/oversize-routes',
                method: 'GET',
                isArray: true,
                transformResponse : function(data, headersGetter, status) {
                    if (status === 200) {
                        var responseData = angular.fromJson(data);

                        responseData.forEach(function(r){
                            r.totalTravelDurationHumanized = '';
                            r.totalTravelDistanceHumanized = '';
                            r.startTime = '';
                            r.type = drsTypeConstants.OVERSIZE;
                            r.finishAtDepotTime = '';
                            r.endTime = '';
                        });
                        return responseData;
                    } else {
                        return data;
                    }
                }
            },
            'generateDailyRouteStat': {
                url: resourceUrl + '%3AgenerateDailyRouteStat',
                method: 'POST',
                isArray: true
            },
        });
    }
})();
