(function () {
    'use strict';

    angular
        .module('stpApp')
        .controller('LicensingController', LicensingController);

    LicensingController.$inject = ['$timeout', 'Licensing', 'ParseLinks', 'AlertService', '$state', 'pagingParams', 'paginationConstants', 'JhiLanguageService'];

    function LicensingController ($timeout, Licensing, ParseLinks, AlertService, $state, pagingParams, paginationConstants, JhiLanguageService) {
        var vm = this;

        vm.loadAll = loadAll;
        vm.license = {};
        vm.loadPage = loadPage;
        vm.transition = transition;

        // license activation
        vm.enterNewLicense = false;
        vm.activationFailed = false;
        vm.activationFinished = false;
        vm.licenseCode = "";
        vm.licenseFile;
        vm.licenseFileContent = "";
        vm.machineCode = "";

        vm.activate = activate;
        vm.resetActivationProcess = resetActivationProcess;
        vm.prepareActivation = prepareActivation;
        vm.calculateLicenseRemain = calculateLicenseRemain;
        vm.licenseStatus = licenseStatus;
        vm.readFile = readFile;
        vm.licenseCodeChanged = licenseCodeChanged;
        vm.licenseFileChanged = licenseFileChanged;
        vm.activateBtnPressed = activateBtnPressed;
        vm.readyToActivate = readyToActivate;

        loadAll();

        function activateBtnPressed () {
            var key = vm.licenseCode.length > 1 ? vm.licenseCode : vm.licenseFileContent.length > 1 ? vm.licenseFileContent : "";
            activate(key);
        }

        function readyToActivate () {
            return vm.licenseCode.length > 1 || vm.licenseFileContent.length > 1;
        }

        function licenseCodeChanged () {
            clearFileInput();
        }

        function clearFileInput () {
            vm.licenseFile = null;
            vm.licenseFileContent = "";
        }

        function licenseFileChanged (file) {
            clearCodeInput();
            readFile(file);
        }

        function clearCodeInput () {
            vm.licenseCode = "";
        }

        function readFile (licenseFile) {
            vm.licenseFile = licenseFile;
            var fileReader = new FileReader();
            fileReader.readAsText(vm.licenseFile, "UTF-8");
            fileReader.onload = function(evt) {
                vm.licenseFileContent = fileReader.result;
            }
        }

        function loadAll () {
            Licensing.query({}, onSuccess, onError);
            Licensing.getMachineCode({}, onMachineCodeRetrieved, onMachineCodeRetrieveError);
        }

        function onMachineCodeRetrieved (data) {
            vm.machineCode = data.plainText;
        }

        function onMachineCodeRetrieveError () {
           // do nothing
        }

        function onSuccess (data, headers) {
            vm.license = data;
            vm.license.remaining = vm.calculateLicenseRemain(vm.license.expiredDate);
        }

        function onError (error) {
            AlertService.error(error.data.message);
        }

        function loadPage (page) {
            vm.page = page;
            vm.transition();
        }

        function transition () {
            $state.transitionTo($state.$current, {});
        }

        function prepareActivation () {
            vm.enterNewLicense = true;
        }

        function resetActivationProcess () {
            vm.enterNewLicense = false;
            vm.licenseCode = "";
        }

        function licenseStatus (status) {
            return status ? 'active' : 'inactive';
        }

        function calculateLicenseRemain (date) {
            var date1 = new Date();
            var date2 = new Date(date);
            var diffTime = date2 - date1;
            if (diffTime < 0) {
                return;
            }
            var diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
            return diffDays;
        }

        function activate (licenseKey) {
            vm.activationFailed = false;

            Licensing.update({ key: licenseKey }, function (res) {
                loadAll();
                resetActivationProcess();
            },
                function (errRes) {
                    vm.activationFailed = true;
                    onError(errRes);
                });
        }
    }
})();
