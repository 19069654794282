(function(){
    'use strict';

    angular
        .module('stpApp')
        .controller('TerritoryCalculatorDialogController', TerritoryCalculatorDialogController);

    TerritoryCalculatorDialogController.$inject = ['$uibModalInstance', 'project', 'mapProp', 'RoutePlanningDataShare', 'Depot', 'MapProperty', 'Project', '$q', 'ColorPaletteConstant', 'RoutePlanningModalFactory', '$sce'];

    function TerritoryCalculatorDialogController($uibModalInstance, project, mapProp, RoutePlanningDataShare, Depot, MapProperty, Project, $q, ColorPaletteConstant, RoutePlanningModalFactory, $sce) {
        var vm = this;
        vm.isSaving = false;
        vm.project = project;
        vm.mapProp = angular.copy(mapProp);
        vm.form = {
            betweenOutletTravelTimeAverageInMinutes: 0,
            dayTimeWindows: [],
            depotToOutletTravelTimeAverageInMinutes: 0,
            expectedNumberOfReload: 0,
            reloadDurationInMinutes: 0,
            useCapacity: false
        };
        vm.isSelectedAllDay = false;
        vm.isCalculating = false;
        vm.sharedData = RoutePlanningDataShare;
        vm.activeDepotId = vm.sharedData.filters.depot;
        vm.territories = vm.mapProp.style.depots[vm.activeDepotId].territories;
        vm.currentTerritorySize = Object.keys(vm.territories).length;
        vm.recommendationTerritorySize = 0;
        vm.recommendationActive = false;
        vm.isApplyCalculation = false;
        vm.colors = ColorPaletteConstant;
        vm.stopCount = vm.sharedData.stops.features.length;
        vm.stemTooltip = $sce.trustAsHtml('<div class="text-left"><ul class="ml-4 p-2"><li class="p-1">'
            +'Stem AM is the time required from departing from depot location to reaching the first stop.</li>'
            +'<li class="p-1">Stem PM is the time required from leaving the last stop to returning to the depot location.</li></ul></div>');

        vm.close = close;
        vm.dateSelected = dateSelected;
        vm.deleteSelectedDate = deleteSelectedDate;
        vm.selectAllDay = selectAllDay;
        vm.isAnyDaySelected = isAnyDaySelected;
        vm.dateSize = 0;
        vm.startTimeAdd = moment.unix(0).utc().format('YYYY-MM-DDTHH:mm:ss');
        vm.endTimeAdd = moment.unix(0).utc().format('YYYY-MM-DDTHH:mm:ss');
        vm.increaseDateSize = increaseDateSize;
        vm.decreaseDateSize = decreaseDateSize;
        vm.addWorkingDays = addWorkingDays;
        vm.isInvalidTimeAdd = isInvalidTimeAdd;
        vm.modifySelectedWorkingDay = modifySelectedWorkingDay;
        vm.calculate = calculate;
        vm.isTimeWindowsInvalid = isTimeWindowsInvalid;
        // vm.applyCalculation = applyCalculation;
        vm.openConfirmDialog = openConfirmDialog;

        init();

        function close() {
            $uibModalInstance.dismiss();
        }

        function init() {
            vm.form.reloadDurationInMinutes = vm.project.props.reloadDurationInMin;
            vm.form.useCapacity = vm.project.props.useCapacity;
            vm.form.dayTimeWindows = vm.project.props.dayTimeWindows.map(function(tw) {
                return {day: tw.day, startTime: convertEpochSecondsToDate(tw.startTime), endTime: convertEpochSecondsToDate(tw.endTime), selected: false};
            });
        }

        function dateSelected() {
            resetIsAllDaySelected();
        }

        function deleteSelectedDate() {
            vm.form.dayTimeWindows = vm.form.dayTimeWindows.filter(function(tw){return !tw.selected;});
            resetDayNumber();
            resetIsAllDaySelected();
        }

        function isAnyDaySelected() {
            return vm.form.dayTimeWindows.some(function(tw){return tw.selected;});
        }

        function selectAllDay() {
            vm.form.dayTimeWindows.forEach(function(tw){tw.selected = vm.isSelectedAllDay;});
        }

        function convertEpochSecondsToDate(epochSeconds) {
            return moment.unix(epochSeconds).utc().format('YYYY-MM-DDTHH:mm:ss');
        }

        function increaseDateSize() {
            if (vm.dateSize + vm.form.dayTimeWindows.length < 7) {
                vm.dateSize += 1;
            }
        }

        function decreaseDateSize() {
            if (vm.dateSize > 0) {
                vm.dateSize -= 1;
            }
        }

        function convertDateToEpochSeconds(date) {
            var localTime = moment();
            var offset = localTime.utcOffset() / 60;
            return moment(date).add(offset, 'h').unix();
        }

        function resetIsAllDaySelected() {
            if(!vm.form.dayTimeWindows.length) {
                vm.isSelectedAllDay = false;
            } else {
                vm.isSelectedAllDay = vm.form.dayTimeWindows.every(function(tw){return tw.selected;});
            }
        }

        function resetDayNumber() {
            if(!vm.form.dayTimeWindows.length) {
                return;
            }
            vm.form.dayTimeWindows.forEach(function(tw, idx){
                tw.day = idx + 1;
            });
        }

        function addWorkingDays() {
            if (!vm.dateSize) {
                return;
            }
            for (var i = 0; i < vm.dateSize; i++) {
                vm.form.dayTimeWindows.push(
                    {day: 0, startTime: vm.startTimeAdd, endTime: vm.endTimeAdd, selected: false}
                );
                resetDayNumber();
            }
            vm.dateSize = 0;
        }

        function isInvalidTimeAdd() {
            return moment(vm.startTimeAdd).isAfter(moment(vm.endTimeAdd));
        }

        function modifySelectedWorkingDay() {
            vm.form.dayTimeWindows.forEach(function(tw){
                tw.startTime = vm.startTimeAdd;
                tw.endTime = vm.endTimeAdd;
                tw.selected = false;
            });
            resetIsAllDaySelected();
        }

        function calculate() {
            vm.isCalculating = true;
            var request = {
                betweenOutletTravelTimeAverageInMinutes: vm.form.betweenOutletTravelTimeAverageInMinutes,
                dayTimeWindows: vm.form.dayTimeWindows.map(function(tw){
                    return {
                        day: tw.day,
                        startTime: convertDateToEpochSeconds(moment(tw.startTime)),
                        endTime: convertDateToEpochSeconds(moment(tw.endTime)),
                    };
                }),
                depotToOutletTravelTimeAverageInMinutes: vm.form.depotToOutletTravelTimeAverageInMinutes,
                expectedNumberOfReload: vm.form.expectedNumberOfReload,
                reloadDurationInMinutes: vm.form.reloadDurationInMinutes,
                useCapacity: vm.form.useCapacity
            };
            Depot.calculateTerritory({projectId: vm.project.id, id: vm.activeDepotId}, request).$promise.then(function(d){
                vm.isCalculating = false;
                vm.recommendationTerritorySize = d.value;
                vm.recommendationActive = true;
            }, function(e){
                vm.isCalculating = false;
            });
        }

        function isTimeWindowsInvalid() {
            var isInvalid = false;

            for (var i = 0; i < vm.form.dayTimeWindows.length; i++) {
                var twStart = vm.form.dayTimeWindows[i].startTime;
                var twEnd = vm.form.dayTimeWindows[i].endTime;
                var dateStart = moment(twStart);
                var dateEnd = moment(twEnd);

                if (dateStart.isAfter(dateEnd)) {
                    isInvalid = true;
                    break;
                } else {
                    isInvalid = false;
                }
            }

            return isInvalid;
        }

        function openConfirmDialog() {
            var confirmDialog = RoutePlanningModalFactory.applyCalculationDialog();

            confirmDialog.result.then(function(r){
                applyCalculation();
            });
        }

        function applyCalculation() {
            vm.isApplyCalculation = true;

            var requests = [];

            // if current territory less than recommendation then create new one
            if (vm.recommendationTerritorySize > vm.currentTerritorySize) {
                for (var i = 1; i <= vm.recommendationTerritorySize; i++) {
                    if (!vm.territories[i]) {
                        vm.territories[i] = {color: vm.colors[i], name: '', loadCapacity: 0};
                    }
                }
            }

            // overide map prop
            vm.mapProp.style.depots[vm.activeDepotId].territories = vm.territories;

            // overide project props
            vm.project.props.reloadDurationInMin = vm.form.reloadDurationInMinutes;
            vm.project.props.useCapacity = vm.form.useCapacity;
            vm.project.props.dayTimeWindows = vm.form.dayTimeWindows.map(function(tw){
                return {
                    day : tw.day,
                    endTime: convertDateToEpochSeconds(tw.endTime),
                    startTime: convertDateToEpochSeconds(tw.startTime)
                };
            });


            requests.push(MapProperty.update(vm.mapProp).$promise);
            requests.push(Project.update(vm.project).$promise);

            $q.all(requests).then(
                function(){
                    vm.isApplyCalculation = false;
                    $uibModalInstance.close('reload');
                },
                function(){
                    vm.isApplyCalculation = false;
                }
            );
        }
    }
})();
