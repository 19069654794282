(function () {
    'use strict';
    angular
        .module('stpApp')
        .factory('RoutePlanningLeafletObjectFactory', RoutePlanningLeafletObjectFactory);

    RoutePlanningLeafletObjectFactory.$inject = ['RoutePlanningConstants', 'MultiColorIconFactory', 'RoutePlanningDataShare', '$translate'];

    function RoutePlanningLeafletObjectFactory(RoutePlanningConstants, MultiColorIconFactory, RoutePlanningDataShare, $translate) {
        var vm = this;
        var CONST = RoutePlanningConstants;
        var SlicedCircle = MultiColorIconFactory.SlicedCircle();
        var RectWithText = MultiColorIconFactory.RectWithText();
        var CircleWithLock = MultiColorIconFactory.CircleWithLock();
        var UnassignStop = MultiColorIconFactory.UnassignStop();

        vm.sharedData = RoutePlanningDataShare;

        vm.service = {
            selectedLayerStyle: selectedLayerStyle,
            pointIconFactory: pointIconFactory,
            metersPerPixel: metersPerPixel,
            setPointStyle: setPointStyle,
            getRouteLayer: getRouteLayer,
            dynamicLabel: dynamicLabel,
            getAdmLayer: getAdmLayer,
            depotIcon: depotIcon,
            getMap: getMap,
            createStopTooltipsContent: createStopTooltipsContent,
            getUnassignStopLayer: getUnassignStopLayer
        };

        function pointIconFactory(depotId) {
            return {
                week: function (weeks, latlng) {
                    var value = 100 / weeks.length;
                    var markerOption = weeks.map(function (week) {
                        return {
                            iconColor: vm.sharedData.dataStyle[CONST.DEPOTS][depotId][CONST.WEEKS][week].color,
                            value: value
                        };
                    });
                    var iconColor = markerOption.map(function (item) {
                        return item.iconColor;
                    });
                    if (weeks.length > 4) {
                        return { icon: new RectWithText(latlng, { color: '#000', weight: 1, opacity: 1, fillColor: iconColor[0], sides: 14, textValue: weeks.length, fillOpacity: 1 }), colors: iconColor };
                    } else {
                        return { icon: new SlicedCircle(latlng, { color: '#000', weight: 1, opacity: 1, markerOption: markerOption, radius: 6 }), colors: iconColor };
                    }
                },
                day: function (days, latlng) {
                    var value = 100 / days.length;
                    days.sort();
                    var markerOption = days.map(function (day) {
                        return {
                            iconColor: vm.sharedData.dataStyle.days[day].color,
                            value: value
                        };
                    });
                    var iconColor = markerOption.map(function (item) {
                        return item.iconColor;
                    });
                    if (days.length > 4) {
                        return { icon: new RectWithText(latlng, { color: '#000', weight: 1, opacity: 1, fillColor: iconColor[0], sides: 14, textValue: days.length, fillOpacity: 1 }), colors: iconColor };
                    } else {
                        return { icon: new SlicedCircle(latlng, { color: '#000', weight: 1, opacity: 1, markerOption: markerOption, radius: 6 }), colors: iconColor };
                    }
                }
            };
        }

        function createDepotTooltipsContent(prop) {
            var tooltip =
            '<div class="stop-header">'
                +'<div class="d-flex py-2 px-3 align-items-center font-weight-bold">'
                    +'<i class="fa fa-home mr-2"></i>'
                    +'<div>DEPOT</div>'
                +'</div>'
            +'</div>'
            +'<div class="p-3">'
                +'<div class="mb-2">'
                    +'<div class="font-weight-bold">'+ prop.name+'</div>'
                    +'<div class="font-weight-bold" style="color:#3189A2">'+ prop.externalId+'</div>'
                +'</div>'
            +'</div>';
            return tooltip;
        }

        function createStopTooltipsContent(feature) {
            var prop = feature.properties;
            var iconColors = feature.properties.iconColors;
            var stopInfo = {
                name : prop.stopName ? prop.stopName : '',
                externalId : prop.stopExternalId ? prop.stopExternalId : '',
                visitFreq : prop.stopVisitFreq ? prop.stopVisitFreq : '',
                value : prop.stopProps.value ? prop.stopProps.value : 0,
                value2 : prop.stopProps.value2 ? prop.stopProps.value2 : 0,
                value3 : prop.stopProps.value3 ? prop.stopProps.value3 : 0,
                notes : prop.stopProps.notes ? prop.stopProps.notes : ''
            };

            function createMultiColorDot (colors) {
                if (!colors || colors.length <= 1) {return '';}

                var dotElm = '';
                for (var i=0; i< colors.length; i ++) {
                    dotElm += '<span class="dot" style="background-color:'+ colors[i] +'"></span>';
                }
                return dotElm;
            }

            var tooltipsContent = ''
            +'<div class="stop-header">'
              +'<div class="d-flex py-2 px-3 align-items-center font-weight-bold">'
                +'<img src="content/images/outlet-white.svg" class="mr-2" />'
                +'<div>'+$translate.instant('stpApp.route.tooltips.stopPoint')+'</div>'
              +'</div>'
            +'</div>'
            +'<div class="p-3">'
                +'<div class="mb-2">'
                  +'<div class="font-weight-bold">'+ stopInfo.name+'</div>'
                  +'<div class="font-weight-bold" style="color:#3189A2">'+ stopInfo.externalId+'</div>'
                +'</div>'
                +'<div class="mb-2">'
                  +'<span class="mr-2 font-weight-bold">'+$translate.instant('stpApp.route.tooltips.visitFreq')+':</span>'
                  +'<span>'+ stopInfo.visitFreq+'</span>'
                +'</div>'
                +'<div class="mb-2">'
                  +'<span class="mr-2 font-weight-bold">'+$translate.instant('stpApp.route.tooltips.notes')+':</span>'
                  +'<span>'+ (stopInfo.notes ? stopInfo.notes : '-')+'</span>'
                +'</div>'
                +'<div class="mb-2 d-flex">'
                    +createMultiColorDot(iconColors)
                +'</div>'
                +createValue(stopInfo)
            +'</div>';

            return tooltipsContent;
        }

        function createUnassignTooltipsContent(feature) {
            var prop = feature.properties;
            var stop = {
                id: prop.id,
                name : prop.name ? prop.name : '',
                reason: prop.reason,
                depot: prop.depot,
                externalId: prop.externalId,
                visitFreq: prop.visitFreq,
                value: prop.value,
                value2: prop.value2,
                value3: prop.value3,
            };
            var reasons = "";
            var translatedReasons = [];

            if (stop.reason.length) {
                translatedReasons = stop.reason.split(",").map(function(x){return $translate.instant('unplannedReason.'+x);});
                translatedReasons = translatedReasons.filter(function(item, idx){
                    return translatedReasons.indexOf(item) === idx;
                });

                translatedReasons.forEach(function(x){
                    reasons += '<li>'+x+'</li>';
                });
            }

            var tooltipsContent = '<div class="stop-header">'
              +'<div class="d-flex py-2 px-3 align-items-center font-weight-bold">'
                +'<img src="content/images/outlet-white.svg" class="mr-2" />'
                +'<div>UNASSIGN STOP POINT</div>'
              +'</div>'
            +'</div>'
            +'<div class="p-3">'
                +'<div class="mb-2">'
                  +'<div class="font-weight-bold">'+ stop.name+'</div>'
                  +'<div class="font-weight-bold" style="color:#3189A2">'+ stop.externalId+'</div>'
                +'</div>'
                +'<div class="mb-2">'
                  +'<span class="mr-2 font-weight-bold">Visit Frequency:</span>'
                  +'<span>'+ stop.visitFreq+'</span>'
                +'</div>'
                +'<div class="mb-2">'
                  +'<span class="mr-2 font-weight-bold">Notes:</span>'
                  +'<span>'+ (stop.notes ? stop.notes : '-')+'</span>'
                +'</div>'
                +'<div class="px-3 pt-3" style="background: #F6F6F6;">'
                    +'<div class="mb-2">REASONS</div>'
                    +'<ul class="px-4 mb-0">' + reasons +'</ul>'
                +'</div>'
                + createValue(stop)
            +'</div>';

            return tooltipsContent;
        }

        function setPointStyle(feature) {
            var data;
            var value;
            var markerOption;
            var iconColor;
            var lock;
            if (vm.sharedData.currentView === CONST.TERRITORY) {
                lock = feature.properties.territoryLocked;
                if (vm.sharedData.dataStyle[CONST.DEPOTS][vm.sharedData.filters.depot]["territories"][feature.properties.routes[0].territory]) {
                    return { fillColor: vm.sharedData.dataStyle[CONST.DEPOTS][vm.sharedData.filters.depot]["territories"][feature.properties.routes[0].territory].color, lock: lock };
                } else {
                    return { fillColor: "#000000", lock: lock  };
                }
            } else if (vm.sharedData.currentView === CONST.WEEK) {
                lock = feature.properties.weeksLocked;
                if (feature.properties.iconData) {
                    data = feature.properties.iconData;
                    value = 100 / data.length;
                    markerOption = data.map(function (week) {
                        return {
                            iconColor: vm.sharedData.dataStyle[CONST.DEPOTS][vm.sharedData.filters.depot][CONST.WEEKS][week].color,
                            value: value
                        };
                    });
                    iconColor = markerOption.map(function (item) {
                        return item.iconColor;
                    });
                    feature.properties.iconColors = iconColor;
                    if (data.length > 4) {
                        return { fillColor: iconColor[0], sides: 14, textValue: data.length, lock: lock };
                    } else if (data.length === 1) {
                        return { markerOption: markerOption, radius: 6, lock: lock  };
                    } else {
                        return { markerOption: markerOption, radius: 6, lock: lock };
                    }
                }
            } else if (vm.sharedData.currentView === CONST.DAY) {
                lock = feature.properties.daysLocked;
                if (feature.properties.iconData) {
                    data = feature.properties.iconData;
                    value = 100 / data.length;
                    markerOption = data.map(function (day, i) {
                        return {
                            iconColor: vm.sharedData.dataStyle.days[day].color,
                            value: value
                        };
                    });
                    iconColor = markerOption.map(function (item) {
                        return item.iconColor;
                    });
                    feature.properties.iconColors = iconColor;
                    if (data.length > 4) {
                        return { color: '#000', weight: 1, opacity: 1, fillColor: iconColor[0], sides: 14, textValue: data.length, lock: lock };
                    } else if (data.length === 1) {
                        return { markerOption: markerOption, radius: 6, lock: lock  };
                    } else {
                        return { color: '#000', weight: 1, opacity: 1, markerOption: markerOption, radius: 6, lock: lock };
                    }
                } else if (!vm.sharedData.filters.day) {
                    return { fillColor: vm.sharedData.dataStyle["days"][feature.properties.routes[0].day].color, lock: lock };
                }
            } else {
                lock = feature.properties.depotLocked;
                if (feature.properties.depotId) {
                    return { fillColor: vm.sharedData.dataStyle[CONST.DEPOTS][feature.properties.depotId].properties.color, lock: lock };
                } else {
                    return { fillColor: "#e1e1e1", lock: lock };
                }
            }
        }

        function depotIcon(el, depotColor, moveStop) {
            var depotMarker = L.marker([el.lat, el.lon], {
                icon: L.divIcon({
                    className: 'map-marker',
                    iconSize: [22, 22],
                    iconAnchor: [10, 13],
                    html:
                        '<div style="background-color:' +
                        depotColor +
                        '"><i class="fa fa-home fa-invers" style="line-height: 14.2pt"></i></div>',
                }),
                id: el.id,
            })
            .bindTooltip(createDepotTooltipsContent(el), {direction: 'top', className: 'stop-tooltips', opacity: 1})
            .bindContextMenu({
                contextmenu: true,
                contextmenuInheritItems: false,
                contextmenuItems: [
                    {
                        text: $translate.instant('stpApp.route.contextMenu.move'),
                        index: 1,
                        icon: 'content/images/arrows-alt.png',
                        callback: function (e) {
                            moveStop(depotMarker, true);
                        },
                        disabled: vm.sharedData.movingMode || !moveStop,
                    },
                ],
            })
            .on('mouseover', function(){
                    depotMarker.setTooltipContent(createDepotTooltipsContent(el));
            });

            return depotMarker;
        }

        var labelTextCollision = new L.LabelTextCollision({
            collisionFlg: false
        });

        function dynamicLabel(geoData, selectedLabel) {
            return L.geoJSON(geoData, {
                pointToLayer: function (feature, latlng) {
                    return L.circleMarker(latlng, {
                        renderer: labelTextCollision,
                        radius: 0,
                        opacity: 0,
                        fillOpacity: 0,
                        text: feature.properties[selectedLabel].toString().toLowerCase(),
                        textStyle: {
                            stroke: true,
                            strokeColor: '#e1e1e1',
                            color: '#000',
                            font: "bold 11px Arial",
                            offsetX: feature.properties[selectedLabel].length * -3,
                            offsetY: 20,
                        }
                    });
                },
            });
        }

        /* find meter/pixel */
        function metersPerPixel(latitude, zoomLevel) {
            var earthCircumference = 40075017;
            var latitudeRadians = latitude * (Math.PI / 180);
            return earthCircumference * Math.cos(latitudeRadians) / Math.pow(2, zoomLevel + 8);
        }

        function getRouteLayer(findGroupDay, findGroupWeek, updateScope, openChangeFreqDialog, moveStop, openOutletLockDialog, moveToNewTerritory, openDeleteRouteStopDialog, openUnassignDialog) {
            return L.geoJSON([], {
                onEachFeature: function (feature, layer) {
                    layer.bindTooltip(createStopTooltipsContent(feature), {direction: 'top', className: 'stop-tooltips', opacity: 1});

                    // click event
                    layer.on('click', function (e) {
                        var a = metersPerPixel(e.latlng.lat, vm.sharedData.map.getZoom());
                        var center = e.latlng;
                        var radius = 6 * a;
                        vm.sharedData.routeLayer.eachLayer(function (eachRoute) {
                            var candidate = eachRoute.getLatLng();
                            var distance = candidate.distanceTo(center);

                            if (distance <= radius) {
                                eachRoute.setStyle(selectedLayerStyle(true));
                                vm.sharedData.selectedRoute.add(eachRoute.feature);
                            }
                        });
                        vm.sharedData.disableTab = (vm.sharedData.selectedRoute.size == 0);
                        if (vm.sharedData.dayTab == 2) {
                            findGroupDay();
                        }
                        if (vm.sharedData.weekTab == 2) {
                            findGroupWeek();
                        }
                        updateScope();
                    });

                    // mouse over event
                    layer.on('mouseover', function(){
                        layer.setTooltipContent(createStopTooltipsContent(feature));
                    });

                    // context menu
                    layer.bindContextMenu({
                        contextmenu: true,
                        contextmenuInheritItems: false,
                        contextmenuItems: [
                            {
                                text: $translate.instant('stpApp.route.contextMenu.moveToNewTerritory'),
                                index: 0,
                                icon:'content/images/move-out.svg',
                                callback: function () {
                                    moveToNewTerritory();
                                },
                                disabled: vm.sharedData.movingMode || vm.sharedData.currentView === 'depot'
                            },
                            {
                                text: $translate.instant('stpApp.route.contextMenu.changeFrequency'),
                                index: 1,
                                icon:'content/images/door-open.png',
                                callback: function () {
                                    openChangeFreqDialog(feature);
                                },
                                disabled: vm.sharedData.movingMode
                            },
                            {
                                text: $translate.instant('stpApp.route.contextMenu.move'),
                                index: 2,
                                icon:'content/images/arrows-alt.png',
                                callback: function (e) {
                                    moveStop(layer, false);
                                },
                                disabled: vm.sharedData.movingMode
                            },
                            {
                                text: $translate.instant('stpApp.route.contextMenu.lockUnlock'),
                                index: 3,
                                icon: 'content/images/bold-lock.png',
                                callback: function (e) {
                                    openOutletLockDialog(feature);
                                }
                            },
                            {
                                text: $translate.instant('stpApp.route.contextMenu.deleteStop'),
                                index: 4,
                                iconCls: 'fa fa-trash fa-lg',
                                callback: function (e) {
                                    openDeleteRouteStopDialog(feature);
                                }
                            },
                            {
                                text: $translate.instant('stpApp.route.contextMenu.unassignStop'),
                                index: 5,
                                icon: 'content/images/unassign-stop.svg',
                                callback: function (e) {
                                    var stopIds = Array.from(vm.sharedData.selectedRoute).map(function(x){return x.properties.stopId;});
                                    stopIds = stopIds.length ? stopIds : [feature.properties.stopId];
                                    openUnassignDialog(stopIds);
                                }
                            }
                        ]
                    });
                },
                pointToLayer: function (feature, latlng) {
                    var routes = feature.properties.routes;

                    var datas = routes.map(function (route) {
                        if (vm.sharedData.currentView === CONST.DAY || vm.sharedData.currentView === CONST.WEEK) {
                            return route[vm.sharedData.currentView];
                        }
                    });

                    var fillColor = '#c0c0c0';

                    var marker = new CircleWithLock(latlng, {
                        radius: 6,
                        fillColor: fillColor,
                        color: '#000',
                        weight: 1,
                        opacity: 1,
                        fillOpacity: 1,
                    });

                    datas = datas.filter(function (c, index) {
                        return datas.indexOf(c) === index;
                    });

                    feature.properties.iconData = datas;
                    if (vm.sharedData.currentView === CONST.WEEK || vm.sharedData.currentView === CONST.DAY) {
                        if (!datas.length) {
                            feature.properties.iconData = null;
                            return marker;
                        }
                        var iconMarker = pointIconFactory(vm.sharedData.filters.depot)[vm.sharedData.currentView](datas, latlng);
                        feature.properties.iconColors = iconMarker.colors;
                        return iconMarker.icon;
                    } else {
                        return marker;
                    }
                }
            });
        }

        function getAdmLayer(updateScope, findGroupDay, findGroupWeek) {
            var defaultStyle = {
                weight: 2,
                fillOpacity: 0.1,
                opacity: 1
            };

            var hoverAndSelectedStyle = {
                fillOpacity: 0.5
            };

            return L.geoJSON([], {
                style: function () {
                    return defaultStyle;
                },
                onEachFeature: function (feature, layer) {
                    layer.on('click', function (e) {
                        vm.sharedData.unassignLayer.eachLayer(function (poin) {
                            var contains = turf.inside(poin.toGeoJSON(), layer.toGeoJSON());
                            if (contains) {
                                poin.setStyle(selectedLayerStyle(true));
                                vm.sharedData.selectedUnassignStop.add(poin.feature);
                            }
                        });

                        vm.sharedData.routeLayer.eachLayer(function (poin) {
                            var contains = turf.inside(poin.toGeoJSON(), layer.toGeoJSON());
                            if (contains) {
                                poin.setStyle(selectedLayerStyle(true));
                                vm.sharedData.selectedRoute.add(poin.feature);
                            }
                        });
                        vm.sharedData.disableTab = (vm.sharedData.selectedRoute.size === 0);
                        if (vm.sharedData.dayTab == 2) {
                            findGroupDay();
                        }
                        if (vm.sharedData.weekTab == 2) {
                            findGroupWeek();
                        }
                        updateScope();
                        layer.setStyle(defaultStyle);
                    });

                    layer.on('mouseover', function (e) {
                        layer.setStyle(hoverAndSelectedStyle);
                    });

                    layer.on('mouseout', function (e) {
                        layer.setStyle(defaultStyle);
                    });
                }
            });
        }

        function getUnassignStopLayer(assignToRoute) {
            return L.geoJSON([], {
                onEachFeature: function (feature, layer) {
                    // bind tooltip
                    layer.bindTooltip(createUnassignTooltipsContent(feature), {direction: 'top', className: 'stop-tooltips', 'opacity': 1});

                    // click event
                    layer.on('click', function (e) {
                        var a = metersPerPixel(e.latlng.lat, vm.sharedData.map.getZoom());
                        var center = e.latlng;
                        var radius = 6 * a;

                        vm.sharedData.unassignLayer.eachLayer(function (eachRoute) {
                            var candidate = eachRoute.getLatLng();
                            var distance = candidate.distanceTo(center);

                            if (distance <= radius) {
                                eachRoute.setStyle(selectedLayerStyle(true));
                                vm.sharedData.selectedUnassignStop.add(eachRoute.feature);
                            }
                        });
                    });

                    // context menu
                    layer.bindContextMenu({
                        contextmenu: true,
                        contextmenuInheritItems: false,
                        contextmenuItems: [
                            {
                                text: 'Apply to route',
                                index: 0,
                                icon:'content/images/move-out.svg',
                                callback: function () {
                                    var stopIds = Array.from(vm.sharedData.selectedUnassignStop).map(function(x){return x.properties.id;});
                                    stopIds = stopIds.length ? stopIds : [feature.properties.id];
                                    assignToRoute(stopIds);
                                },
                            },
                        ]
                    });
                },
                pointToLayer: function (feature, latlng) {
                    var marker = new UnassignStop(latlng, {
                        radius: 6,
                        fillColor: '#333333',
                        color: '#000000',
                        weight: 1,
                        opacity: 1,
                        fillOpacity: 1,
                    });

                    return marker;
                }
            });
        }

        function getMap(layers) {
            return L.map("map", {
                renderer: L.canvas(),
                touchExtend: false,
                doubleClickZoom: false,
                zoomControl: false,
                layers: layers,
                contextmenu: true
            });
        }

        function selectedLayerStyle(isSelected) {
            return {
                color: isSelected ? "#FFFFFF" : "#000",
                weight: isSelected ? 2 : 0.5,
            };
        }

        function createValue(stopInfo) {
            var valueElm = '';

            if (!vm.sharedData.numOfValues) {
                return valueElm;
            }

            if (vm.sharedData.numOfValues >= 1) {
                valueElm += '<div class="d-flex px-3 py-2 mr-2" style="border-radius: 4px;background: #E0E0E0;">'
                +'<img class="mr-2" src="content/images/value1-black.svg" style="width: 16px;height: 16px;"/>'
                +'<span class="font-weight-bold">'+(new Intl.NumberFormat('en-US').format(stopInfo.value))+'</span>'
                +'</div>';
            }

            if (vm.sharedData.numOfValues >= 2) {
                valueElm += '<div class="d-flex px-3 py-2 mr-2" style="border-radius: 4px;background: #E0E0E0;">'
                +'<img class="mr-2" src="content/images/value2-black.svg" style="width: 16px;height: 16px;"/>'
                +'<span class="font-weight-bold">'+(new Intl.NumberFormat('en-US').format(stopInfo.value2))+'</span>'
                +'</div>';
            }

            if (vm.sharedData.numOfValues >= 3) {
                valueElm += '<div class="d-flex px-3 py-2" style="border-radius: 4px;background: #E0E0E0;">'
                +'<img class="mr-2" src="content/images/value3-black.svg" style="width: 16px;height: 16px;"/>'
                +'<span class="font-weight-bold">'+(new Intl.NumberFormat('en-US').format(stopInfo.value3))+'</span>'
                +'</div>';
            }

            return '<div class="px-3 py-3" style="background: #F6F6F6;">'
                +'<div class="mb-2">VALUE</div>'
                +'<div class="d-flex">' + valueElm +'</div>'
                +'</div>';
        }

        return vm.service;
    }
})();
