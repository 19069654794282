(function () {
    'use strict';

    angular
        .module('stpApp')
        .controller('DeleteStopRouteController', DeleteStopRouteController);

    DeleteStopRouteController.$inject = ['$scope', '$uibModalInstance', 'entity', 'selected', 'Stop'];

    function DeleteStopRouteController($scope, $uibModalInstance, entity, selected, Stop) {
        var vm = this;

        vm.stops = [];
        vm.clear = clear;
        vm.deleteStop = deleteStop;
        vm.isLoading = false;

        function init() {
            if (selected.length > 1) {
                vm.stops = selected.map(function(s) {
                    return {id : s.properties.stopId, name: s.properties.stopName};
                });
            } else {
                vm.stops.push(entity);
            }
        }

        init();

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function deleteStop() {
            vm.isLoading = true;
            Stop.bulkDelete({},vm.stops.map(function(stop) { return stop.id}), onSaveSuccess, onSaveError);
        }

        function onSaveSuccess(result) {
            $uibModalInstance.close(result);
            vm.isLoading = false;
        }

        function onSaveError() {
            vm.isLoading = false;
        }
    }
})();
