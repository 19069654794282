(function () {
    'use strict';

    angular
        .module('stpApp')
        .controller('OutletLockDialogController', OutletLockDialogController);

    OutletLockDialogController.$inject = ['$scope', '$uibModalInstance','selected', 'stop', 'projectId', 'Project'];

    function OutletLockDialogController($scope, $uibModalInstance, selected, stop, projectId, Project) {
        var vm = this;

        vm.clear = clear;
        vm.save = save;
        vm.selected = selected.length ? selected : [stop];
        vm.depotLocked = false;
        vm.territoryLocked = false;
        vm.weeksLocked = false;
        vm.daysLocked = false;
        if (vm.selected.length === 1) {
            vm.depotLocked = stop.properties.depotLocked;
            vm.territoryLocked = stop.properties.territoryLocked;
            vm.weeksLocked = stop.properties.weeksLocked;
            vm.daysLocked = stop.properties.daysLocked;
        }

        vm.isSaving = false

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;
            var request = {
                ids: vm.selected.map(function (each) {
                    return each.id;
                }),
                depotLocked: vm.depotLocked ? true : false,
                territoryLocked: vm.territoryLocked ? true : false,
                weeksLocked: vm.weeksLocked ? true : false,
                daysLocked: vm.daysLocked ? true : false,
            };

            Project.lockStops({ projectId: projectId }, request, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess(result) {
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }
    }
})();
