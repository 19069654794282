(function(){
    'use strict';

    angular
        .module('stpApp')
        .controller('ImportStopDialogController', ImportStopDialogController);

    ImportStopDialogController.$inject = ['$uibModalInstance', 'parsedFileResult', 'project', 'RoutePlanningModalFactory'];

    function ImportStopDialogController($uibModalInstance,  parsedFileResult, project, RoutePlanningModalFactory) {
        var vm = this;
        vm.project = project;
        vm.close = close;
        vm.isSaving = false;
        vm.keys = parsedFileResult.keys ? parsedFileResult.keys : [];
        vm.data = parsedFileResult.data ? parsedFileResult.data : [];
        vm.keyMapper = {
            'external_id': null,
            'name': null,
            'notes': null,
            'latitude': null,
            'longitude': null,
            'value': null,
            'value2': null,
            'value3': null,
            'visit_freq': null,
            'type': null,
            'in_stop_duration': null,
            'time_window': null
        };
        vm.columnAliases = {
            'external_id': ['external_id', 'external', 'id'],
            'name': ['name', 'nama'],
            'notes': ['notes', 'catatan'],
            'latitude': ['latitude', 'lat', 'y', 'y coordinate'],
            'longitude': ['longitude', 'lon', 'long', 'x', 'x coordinate'],
            'value': ['value1', 'value 1','value', 'val'],
            'value2': ['value2', 'value 2', 'val'],
            'value3': ['value3', 'value 3', 'val'],
            'visit_freq': ['visit_freq', 'visit', 'frequency'],
            'type': ['type', 'tipe'],
            'in_stop_duration': ['in_stop_duration', 'duration', 'in outlet time'],
            'time_window': ['time_window', 'time', 'window', 'time window'],
        };
        vm.mode = 'MAPPER';
        vm.mapperForm = {};
        vm.tableFrom = {};
        vm.tableData = [];
        vm.invalidRow = 0;
        vm.page = 1;
        vm.totalItems = 0;
        vm.itemsPerPage = 10;
        vm.filterValidData = '';
        vm.isShowValid = false;
        vm.isHeaderDuplicate = false;
        vm.valuesConfig = [];

        vm.createTableData = createTableData;
        vm.onMapperChange = onMapperChange;
        vm.validateRow = validateRow;
        vm.uploadFile = uploadFile;
        vm.onTextInputChanged = onTextInputChanged;
        vm.onShowInvalid = onShowInvalid;
        vm.stopSummary = {
            depotCount: 0,
            outletCount: 0
        };
        vm.addValue = addValue;
        vm.removeValue = removeValue;
        simpleAutoMapper();

        function close() {
            $uibModalInstance.dismiss();
        }

        function simpleAutoMapper() {
            Object.keys(vm.keyMapper).forEach(function(mapper){
                var idx = vm.keys.findIndex(function(key){
                    var similarCandidate = vm.columnAliases[mapper].filter(function(alias){
                        if (mapper == 'value' || mapper == 'value2' || mapper == 'value3') {
                            return stringSimilarity.compareTwoStrings(key.toLowerCase(), alias.toLowerCase()) >= 0.9;
                        }
                        return stringSimilarity.compareTwoStrings(key.toLowerCase(), alias.toLowerCase()) >= 0.7;
                    });
                    return similarCandidate.length > 0;
                });
                if (idx >=0) {
                    vm.keyMapper[mapper] = vm.keys[idx];
                }
            });
        }

        function createTableData() {
            if(vm.tableData.length) {
                vm.mode = 'DATA';
                return;
            }
            vm.tableData = vm.data
                .map(function (each) {
                    var externalId = each[vm.keyMapper['external_id']];
                    var name = each[vm.keyMapper['name']];
                    var latitude = each[vm.keyMapper['latitude']];
                    var longitude = each[vm.keyMapper['longitude']];
                    var notes = each[vm.keyMapper['notes']];
                    var value = each[vm.keyMapper['value']];
                    var value2 = each[vm.keyMapper['value2']];
                    var value3 = each[vm.keyMapper['value3']];
                    var visitFreq = each[vm.keyMapper['visit_freq']];
                    var type = each[vm.keyMapper['type']] ? each[vm.keyMapper['type']].toString().toUpperCase() : each[vm.keyMapper['type']];
                    var inStopDuration = each[vm.keyMapper['in_stop_duration']];
                    var timeWindow = each[vm.keyMapper['time_window']];
                    var timeWindowValidation = validateTimeWindow(type, timeWindow);

                    return {
                        external_id: {
                            util: {
                                valid: externalId ? true : false,
                                editMode: false,
                            },
                            data: externalId,
                        },
                        name: {
                            util: {
                                valid: name && name.toString().length <= 50,
                                editMode: false,
                            },
                            data: name,
                        },
                        notes: {
                            util: {
                                valid: true,
                                editMode: false,
                            },
                            data: notes,
                        },
                        latitude: {
                            util: {
                                valid: latitude && latitude >= -90 && latitude <= 90,
                                editMode: false,
                            },
                            data: latitude,
                        },
                        longitude: {
                            util: {
                                valid: longitude && longitude >= -180 && longitude <= 180,
                                editMode: false,
                            },
                            data: longitude,
                        },
                        value: {
                            util: {
                                valid: validateValue(type, value, 0),
                                editMode: false,
                            },
                            data: value,
                        },
                        value2: {
                            util: {
                                valid: validateValue(type, value2, 1),
                                editMode: false,
                            },
                            data: value2,
                        },
                        value3: {
                            util: {
                                valid: validateValue(type, value3, 2),
                                editMode: false,
                            },
                            data: value3,
                        },
                        visit_freq: {
                            util: {
                                valid: validateVisitFrequency(type, visitFreq),
                                editMode: false,
                            },
                            data: visitFreq ? visitFreq.toString().toLowerCase() : visitFreq,
                        },
                        type: {
                            util: {
                                valid: type ? type === 'OUTLET' || type === 'DEPOT' || type === 'TRANSIT' : false,
                                editMode: false,
                            },
                            data: type,
                        },
                        in_stop_duration: {
                            util: {
                                valid: validateInStopDuration(type, inStopDuration),
                                editMode: false,
                            },
                            data: inStopDuration,
                        },
                        time_window: {
                            util: {
                                valid: timeWindowValidation.value,
                                editMode: false,
                                errorKey: timeWindowValidation.errorKey
                            },
                            data: timeWindow,
                        }
                    };
                });

            validateDuplicateExternalId();

            vm.totalItems = vm.tableData.length;
            vm.mode = 'DATA';

            countInvalidRow();
            countStopSummary();
        }

        function validateVisitFrequency(type, visitFreq) {
            if (type === 'DEPOT' || type === 'TRANSIT') {
                return true;
            }

            if (typeof visitFreq !== 'string') {
                return false;
            }

            var pattern = /^(\d+[dD]{1}?)(\d+[wW]{1}?)$/;
            var visitValue = visitFreq ? visitFreq.toString().split(/[dD]|[wW]/) : [];
            var validPattern = pattern.test(visitFreq);
            var validSingleDay = validPattern && (visitValue[0] == 1 && visitValue[1] > 1);
            var validSingleWeek = validPattern && (visitValue[0] > 0 && visitValue[0] <= 7 && visitValue[1] == 1);

            return (validSingleDay || validSingleWeek);
        }

        function validateInStopDuration(type, duration) {

            var floatPattern = new RegExp('[0-9]+[\.,][0-9]+', 'g');

            if (type === 'DEPOT' || type === 'TRANSIT') {
                return true;
            } else if (!duration) {
                return true;
            } else if (isNaN(duration)) {
                return false;
            } else if (floatPattern.test(duration)) {
                return false;
            } else {
                return true;
            }
        }

        function validateTimeWindow(type, timeWindow) {

            var timeWindowPattern = /^(?:[01]\d|2[0-3]):[0-5]\d-(?:[01]\d|2[0-3]):[0-5]\d$/;

            if (type === 'DEPOT' || type === 'TRANSIT') {
                return {
                    value: true,
                    errorKey: '',
                };
            } else if (!timeWindow) {
                return {
                    value: true,
                    errorKey: '',
                };
            } else if (!timeWindowPattern.test(timeWindow)) {
                return {
                    value: false,
                    errorKey: 'INVALID_FORMAT',
                };
            } else if (timeWindowPattern.test(timeWindow)) {
                var arrayTime = timeWindow.split("-");
                var startTime = moment(arrayTime[0], 'HH:mm');
                var endTime = moment(arrayTime[1], 'HH:mm');
                return {
                    value: startTime.isBefore(endTime),
                    errorKey: startTime.isBefore(endTime) ? '' : 'INVALID_START_TIME'
                };
            } else {
                return {
                    value: true,
                    errorKey: '',
                };
            }
        }

        function onMapperChange() {
            vm.tableData = [];
            vm.page = 1;
            vm.isHeaderDuplicate = false;
        }

        function validateRow(rowData) {
            var timeWindowValidation = validateTimeWindow(rowData.type.data, rowData.time_window.data);

            rowData.type.util.valid = rowData.type.data === 'OUTLET' || rowData.type.data === 'DEPOT' || rowData.type.data === 'TRANSIT';
            rowData.external_id.util.valid = rowData.external_id.data ? true : false;
            rowData.name.util.valid = rowData.name.data && rowData.name.data.toString().length <= 50;
            rowData.latitude.util.valid = rowData.latitude.data && (rowData.latitude.data >= -90 && rowData.latitude.data <= 90);
            rowData.longitude.util.valid = rowData.longitude.data && (rowData.longitude.data >= -180 && rowData.longitude.data <= 180);
            rowData.value.util.valid = validateValue(rowData.type.data, rowData.value.data, 0);
            rowData.value2.util.valid = validateValue(rowData.type.data, rowData.value2.data, 1);
            rowData.value3.util.valid = validateValue(rowData.type.data, rowData.value3.data, 2);
            rowData.in_stop_duration.util.valid = validateInStopDuration(rowData.type.data, rowData.in_stop_duration.data);
            rowData.visit_freq.util.valid = validateVisitFrequency(rowData.type.data, rowData.visit_freq.data);
            rowData.time_window.util.valid = timeWindowValidation.value;
            rowData.time_window.util.errorKey = timeWindowValidation.errorKey;

            validateDuplicateExternalId();
            countInvalidRow();
            countStopSummary();

            // update pagination on filtered condition
            if (vm.isShowValid) {
                vm.totalItems = vm.invalidRow;

                if (vm.page > 1) {
                    vm.page = vm.page - 1 * vm.itemsPerPage < vm.totalItems.length ? vm.page - 1 : vm.page;
                }
            }
        }

        function uploadFile() {
            if (vm.invalidRow == vm.tableData.length) {
                return;
            }

            var cautionImportDialog = RoutePlanningModalFactory.openCautionImportDialog({
                project: vm.project,
                tableData: vm.tableData,
                invalidRow: vm.invalidRow,
                valuesConfig: vm.valuesConfig
            });
            cautionImportDialog.result.then(
                function () {
                    $uibModalInstance.close('RELOAD');
                },
                function () {}
            );
        }

        function onTextInputChanged(e, row, item) {
            var keyCode = e.originalEvent.keyCode;
            if (keyCode == 13) {
                validateRow(row);
                item.util.editMode = false;
                return;
            }
            validateRow(row);
        }

        function countInvalidRow() {
            vm.invalidRow = vm.tableData.reduce(function(sumInvalid, each){
                sumInvalid = !each.valid ? sumInvalid + 1 : sumInvalid;
                return sumInvalid;
            },0);
        }

        function countStopSummary() {
            vm.stopSummary = vm.tableData.reduce(function(summary, each){
                summary.depotCount = each.type.data === 'DEPOT' && each.valid ? summary.depotCount + 1 : summary.depotCount;
                summary.outletCount = each.type.data === 'OUTLET' && each.valid ? summary.outletCount + 1 : summary.outletCount;
                return summary;
            },{depotCount: 0, outletCount: 0});
        }

        function onShowInvalid() {
            vm.filterValidData = vm.isShowValid ? false : '';
            vm.totalItems = vm.isShowValid ? vm.invalidRow : vm.tableData.length;
            vm.page = 1;
        }

        function validateDuplicateExternalId() {
            var externalIdIndex = vm.tableData.reduce(function (exIdIndex, curr, index) {
                var externalIdData = curr.external_id.data;
                if (!exIdIndex[externalIdData]) {
                    exIdIndex[externalIdData] = [index];
                } else {
                    exIdIndex[externalIdData].push(index);
                }
                return exIdIndex;
            }, {});

            vm.tableData.forEach(function(each){
                var externalIdData = each.external_id.data;
                each.external_id.util.valid = externalIdData ? externalIdIndex[externalIdData].length > 1 ? false : true : false;

                each.valid =
                    each.type.util.valid &&
                    each.external_id.util.valid &&
                    each.name.util.valid &&
                    each.latitude.util.valid &&
                    each.longitude.util.valid &&
                    each.value.util.valid &&
                    each.value2.util.valid &&
                    each.value3.util.valid &&
                    each.in_stop_duration.util.valid &&
                    each.visit_freq.util.valid &&
                    each.time_window.util.valid;
            });
        }

        function validateValue(type, value, idx) {
            if (vm.valuesConfig[idx]) {
                return type === 'OUTLET' ? !value ? true : !isNaN(value) : true;
            } else {
                return true;
            }
        }

        function addValue() {
            if (vm.valuesConfig.length < 3) {
                vm.valuesConfig.push(true);
            }
        }

        function removeValue() {
            vm.valuesConfig.pop();
        }
    }
})();
