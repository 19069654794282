(function () {
    "use strict";

    angular.module("stpApp").directive("routePlanningMenuHeader", routePlanningMenuHeader);

    function routePlanningMenuHeader() {
        var directive = {
            restrict: "E",
            scope: {
                type: "=",
                onLoading:"=",
                autoBalance: "&",
                autoBalanceDialog: "&",
                onSelectedMode: "=",
                changeMode: "&",
                numOfValues: '=numOfValues',
            },
            templateUrl: "app/route/directives/route-planning-menu-header.html",
        };

        return directive;
    }
})();
