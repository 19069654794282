(function(){
    'use strict';

    angular
        .module('stpApp')
        .controller('ImportStopDialogCautionController', ImportStopDialogCautionController);

    ImportStopDialogCautionController.$inject = ['$uibModalInstance', 'tableData', 'invalidRow', 'project', 'Upload', 'FileSaver', 'valuesConfig'];

    function ImportStopDialogCautionController($uibModalInstance, tableData, invalidRow, project, Upload, FileSaver, valuesConfig) {
        var vm = this;
        vm.close = close;
        vm.isSaving = false;
        vm.invalidRow = invalidRow;
        vm.tableData = tableData;
        vm.project = project;
        vm.valuesConfig = valuesConfig;
        vm.upload = upload;
        vm.isDownloaded = false;

        function close() {
            $uibModalInstance.dismiss();
        }

        function upload() {
            if (vm.invalidRow) {
                downloadInvalidData();
            }

            var cleanData = vm.tableData
                .filter(function (each) {
                    return each.valid;
                })
                .map(function(data) {
                    return convertTableData(data);
                });
            var csv = Papa.unparse(cleanData);
            var file = new Blob([csv], {type: 'text/csv'});

            vm.isSaving = true;

            mixpanel.time_event('Import Successful');
            Upload.upload({
                url: 'stpcore/api/stops/import',
                data: {
                    projectId: vm.project.id,
                    file: file,
                },
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                },
            }).then(
                function (res) {
                    mixpanel.track('Import Successful', {'dataType': 'Stop'});
                    vm.isSaving = false;
                    $uibModalInstance.close();
                },
                function (err) {
                    var message = err.data.status == 500 ? 'internalServer' : err.data.message;
                    mixpanel.track('Import Failed', {
                        'dataType': 'Stop',
                        'errorMessage': message
                    });
                    vm.isSaving = false;
                }
            );
        }

        function downloadInvalidData() {
            if (vm.isDownloaded) {
                return;
            }
            var invalidData = vm.tableData
                .filter(function (each) {
                    return !each.valid;
                })
                .map(function (data) {
                    return convertTableData(data, true);
                });
            var csv = Papa.unparse(invalidData);
            var file = new Blob([csv], {type: 'text/csv'});
            FileSaver.saveAs(file, 'invalid-data.csv');
            vm.isDownloaded = true;
        }

        function convertTableData(data, withReason) {
            var result  = {
                external_id: data.external_id.data,
                name: data.name.data,
                notes: data.notes.data,
                latitude: data.latitude.data,
                longitude: data.longitude.data,
                value: valueData(data.type.data, data.value.data, 0),
                value2: valueData(data.type.data, data.value2.data, 1),
                value3: valueData(data.type.data, data.value3.data, 2),
                visit_freq: data.visit_freq.data ? data.visit_freq.data.toLowerCase() : data.visit_freq.data,
                type: data.type.data,
                in_stop_duration: data.type.data === 'OUTLET' ? data.in_stop_duration.data : 0,
                time_window: data.type.data === 'OUTLET' ? data.time_window.data : '',
            };

            if (withReason) {
                result.unacceptable_reason = [];
                result.unacceptable_reason = createUnacceptableReason(data);
            }

            return result;
        }

        function createUnacceptableReason(data) {
            var reasons = [];

            // error required
            if (!data.external_id.data) {
                reasons.push('External Id : External Id is required');
            }

            if (!data.type.data) {
                reasons.push('Type : Type is required');
            }

            if (!data.name.data) {
                reasons.push('Stop Name : Stop Name Id is required');
            }

            if (!data.latitude.data) {
                reasons.push('Latitude : Latitude Id is required');
            }

            if (!data.longitude.data) {
                reasons.push('Longitude : Longitude Id is required');
            }

            if (!data.visit_freq.data) {
                reasons.push('Visit Frequency: Visit Frequency is required');
            }


            // error not valid
            if (data.external_id.data && !data.external_id.util.valid) {
                reasons.push('External Id : External Id is duplicate ');
            }

            if (data.type.data && !data.type.util.valid) {
                reasons.push('Type : Allowed input: DEPOT or OUTLET or TRANSIT');
            }

            if (data.name.data && !data.name.util.valid) {
                reasons.push('Stop Name : Stop Name cannot be more than 50 characters');
            }

            if (data.latitude.data && !data.latitude.util.valid) {
                reasons.push('Latitude : Invalid Latitude');
            }

            if (data.longitude.data && !data.longitude.util.valid) {
                reasons.push('Longitude : Invalid Longitude');
            }

            if (data.visit_freq.data && !data.visit_freq.util.valid) {
                reasons.push('Visit Frequency: (1-7)d1w or 1d(number)w');
            }

            if (!data.value.util.valid) {
                reasons.push('Value: Only number');
            }

            if (!data.value2.util.valid) {
                reasons.push('Value2: Only number');
            }

            if (!data.value3.util.valid) {
                reasons.push('Value3: Only number');
            }

            if (!data.in_stop_duration.util.valid) {
                reasons.push('In Stop Duration: Only non-decimal numbers');
            }

            if (data.time_window.util.errorKey === 'INVALID_FORMAT') {
                reasons.push('Time Window: Invalid time range format (00:00-23:59)');
            }

            if (data.time_window.util.errorKey === 'INVALID_START_TIME') {
                reasons.push('Time Window: Start time must be less than end time');
            }

            return reasons.join(", ");
        }

        function valueData(type, value, idx) {
            if (vm.valuesConfig[idx]) {
                return type === 'OUTLET' ? value : 0;
            } else {
                return '';
            }
        }

    }
})();
