(function() {
    'use strict';

    angular
        .module('stpApp')
        .factory('MapPropertySearch', MapPropertySearch);

    MapPropertySearch.$inject = ['$resource'];

    function MapPropertySearch($resource) {
        var resourceUrl =  'stpcore/' + 'api/_search/map-properties/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
