(function() {
    'use strict';
    angular
        .module('stpApp')
        .factory('Routings', Routings);

        Routings.$inject = ['$resource'];

    function Routings ($resource) {
        var tailUrlRoute = 'overview=false&alternatives=true&steps=true&hints=';
        var tailUrlTrip = 'overview=false&source=first&destination=last';
        var result = {
            service: service
        };

        return result;

        function service(hints, osrm, type, waypoints) {
            if(osrm) {
                var urlLeg = '';
                var urlOsrm = '';
                var urlTrip = '';

                if(type == 'route') {
                    (waypoints) ? urlLeg = waypoints + '?' : urlLeg = urlLeg;
                    (hints) ? urlLeg += tailUrlRoute + hints : urlLeg = urlLeg;

                    urlOsrm = osrm + '/' + type + '/v1/driving/' + urlLeg
                } else if(type == 'trip') {
                    if(waypoints) {
                        urlLeg = waypoints + '?' + tailUrlTrip;
                    }

                    urlTrip = osrm + '/' + type + '/v1/driving/' + urlLeg
                }
            }

            return $resource(null, {}, {
                'getFastestRoute': {
                    url: urlTrip,
                    method: 'GET',
                    transformResponse: function (data) {
                        if (data) {
                            data = angular.fromJson(data);
                        }
                        return data;
                    }
                },
                'getLegs': {
                    url: urlOsrm+'&continue_straight=false',
                    method: 'GET',
                    transformResponse: function (data) {
                        if (data) {
                            data = angular.fromJson(data);
                        }
                        return data;
                    }
                },
                'getOsrmAddress': {
                    url: 'stpcore/' + 'api/application-settings/',
                    method: 'GET',
                    isArray: true
                }
            });
        }
    }
})();
