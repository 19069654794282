(function () {
    'use strict';

    angular
        .module('stpApp')
        .directive('routeMenuInfo', routeMenuInfo);

    routeMenuInfo.$inject = [];

    function routeMenuInfo() {
        var directive = {
            restrict: 'E',
            scope: {
                totalValue: '=totalValue',
                totalValue2: '=totalValue2',
                totalValue3: '=totalValue3',
                totalStopStatistic: '=totalStopStatistic',
                totalStop: '=totalStop',
                totalVisit: '=totalVisit',
                selectedRouteSize: '=selectedRouteSize',
                list: '=list',
                tabView: '=tabView',
                stopLimit: '=stopLimit',
                numOfValues: '=numOfValues',
            },
            link: link,
            transclude: true,
            templateUrl: 'app/route/directives/route-menu-info.html'
        };

        return directive;

        function link(scope, element, attrs) {
            scope.activeValue = 1;
            scope.chooseActiveValue = chooseActiveValue

            function chooseActiveValue (flag) {
                scope.activeValue = flag;
            }
        }
    }
})();
