(function () {
    'use strict';

    angular.module('stpApp').service('FeatureToggleService', FeatureToggleService);

    function FeatureToggleService() {
        this.features = []; // Store features for toggle; use array string
        this.setFeatures = setFeatures;
        this.getFeatures = getFeatures;
        this.featureToggle = featureToggle;
        this.findFeature = findFeature;

        // Set new feature to toggle; use array string
        function setFeatures(features) {
            if (this.features !== features) {
                this.features = features;
            }
        }

        // Find 1 feature inside store
        function findFeature(featureName) {
            var result = this.features.find(function(feature) {
                return feature === featureName;
            });
            return !!result;
        }

        // Get all features in store
        function getFeatures() {
            return this.features;
        }

        // Get toggle feature with param of feature name as string
        function featureToggle(features) {
            if (!this.features || !this.features.length) {
                return false;
            }
            for (var i = 0; i < this.features.length; i++) {
                if (this.features[i] && this.features[i].indexOf(features) !== -1) {
                    return true;
                }
            }
            return false;
        }
    }
})();
