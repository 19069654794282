(function () {
    'use strict';

    angular
        .module('stpApp')
        .directive('routePlanningDepot', routePlanningDepot);

    routePlanningDepot.$inject = ['Route'];

    function routePlanningDepot(Route) {
        var directive = {
            restrict: 'E',
            scope: true,
            templateUrl: 'app/route/directives/route-planning-depot.html',
            link: link
        };

        return directive;

        function link(scope, element, attrs) {
            var vm = scope.vm;
            vm.totalListDepot = vm.getDepots().length || vm.sharedData.totalList.depot;
            vm.isAllStopDepotHide = false;

            vm.onShowHideStopsDepot = onShowHideStopsDepot;
            vm.onShowHideAllStopsDepot = onShowHideAllStopsDepot;

            function onShowHideAllStopsDepot(showHide) {
                for (var item in vm.toggleLayer) {
                    if (Object.hasOwnProperty.call(vm.toggleLayer, item)) {
                        vm.toggleLayer[item] = showHide;
                    }
                }
                if (!showHide) {
                    vm.isAllStopDepotHide = true;
                }else {
                    vm.isAllStopDepotHide = false;
                }
                vm.onToggleLayerChange();
            }

            function onShowHideStopsDepot() {
                var stops = Object.entries(vm.toggleLayer);
                var allStop = stops.every(function (stop) { return stop[1]; });
                if (allStop) {
                    vm.isAllStopDepotHide = false;
                }else{
                    vm.isAllStopDepotHide = true;
                }
            }
        }
    }

})();
