(function () {
    'use strict';

    angular
        .module('stpApp')
        .controller('LicenseActivationController', LicenseActivationController);

    LicenseActivationController.$inject = ['AlertService', 'Licensing', 'LicenseActivation', '$timeout'];

    function LicenseActivationController (AlertService, Licensing, LicenseActivation, $timeout) {

        var vm = this;

        vm.licenseCode  = '';
        vm.licenseFile;
        vm.licenseFileContent = '';
        vm.machineCode = '';
        vm.activationSuccess = false;
        vm.activationIsInProgress = false;

        vm.activate = activate;
        vm.readFile = readFile;
        vm.licenseCodeChanged = licenseCodeChanged;
        vm.licenseFileChanged = licenseFileChanged;
        vm.activateBtnPressed = activateBtnPressed;
        vm.readyToActivate = readyToActivate;
        vm.shouldDisableActivateBtn = shouldDisableActivateBtn;

        Licensing.getMachineCode({}, onMachineCodeRetrieved);

        function onMachineCodeRetrieved (data) {
            vm.machineCode = data.plainText;
        }

        function shouldDisableActivateBtn() {
            return !vm.readyToActivate() && vm.activationIsInProgress;
        }

        function activateBtnPressed () {
            var key = vm.licenseCode.length > 1 ? vm.licenseCode : vm.licenseFileContent.length > 1 ? vm.licenseFileContent : "";
            activate(key);
        }

        function readyToActivate () {
            return vm.licenseCode.length > 1 || vm.licenseFileContent.length > 1;
        }

        function licenseCodeChanged () {
            clearFileInput();
        }

        function clearFileInput () {
            vm.licenseFile = null;
            vm.licenseFileContent = "";
        }

        function licenseFileChanged (file) {
            clearCodeInput();
            readFile(file);
        }

        function clearCodeInput () {
            vm.licenseCode = "";
        }

        function readFile (licenseFile) {
            vm.licenseFile = licenseFile;
            var fileReader = new FileReader();
            fileReader.readAsText(vm.licenseFile, "UTF-8");
            fileReader.onload = function(evt) {
                vm.licenseFileContent = fileReader.result;
            }
        }

        function activate (licenseKey) {
            vm.activationIsInProgress = true;
            Licensing.update({ key: licenseKey }, onSuccess, onError);
        }

        function onSuccess (data, headers) {
            vm.activationSuccess = true;
            vm.activationIsInProgress = false;
            $timeout(function () {
                LicenseActivation.activateLicenseCallback();
            }, 3000);
        }

        function onError (error) {
            vm.activationIsInProgress = false;
            AlertService.error(error.data.message);
        }

    }
})();
