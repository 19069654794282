(function () {
    'use strict';
    angular.module('stpApp').factory('TransitPoint', TransitPoint);

    TransitPoint.$inject = ['$resource'];

    function TransitPoint($resource) {
        var resourceUrl = 'stpcore/' + 'api/projects/:projectId/transit-points';

        return $resource( resourceUrl, {}, {
                create: { method: 'POST' },
                query: {
                    method: 'GET',
                    isArray: true
                },
                delete: {
                    method: 'DELETE',
                    url: resourceUrl + '/:id'
                },
                setToRoute: {
                    method: 'POST',
                    url: resourceUrl + '%3AupdateRouteStartEndPoint'
                },
                getTransitByDailyRoutes: {
                    method: 'POST',
                    url: resourceUrl + '%3AgetRouteStartEndPoint',
                    transformResponse: function (data) {
                        if (data) {
                            data = angular.fromJson(data);
                        }
                        return data;
                    },
                },
            }
        );
    }
})();
