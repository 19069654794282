(function () {
    'use strict';

    angular
        .module('stpApp')
        .directive('focusOnShow', focusOnShow);

    focusOnShow.$inject = ['$timeout'];

    function focusOnShow($timeout) {
        var directive = {
            restrict: 'A',
            link: function ($scope, $element, $attr) {
                if ($attr.ngShow) {
                    $scope.$watch($attr.ngShow, function (newValue) {
                        if (newValue) {
                            $timeout(function () {
                                $element[0].focus();
                            }, 0);
                        }
                    });
                }
                if ($attr.ngHide) {
                    $scope.$watch($attr.ngHide, function (newValue) {
                        if (!newValue) {
                            $timeout(function () {
                                $element[0].focus();
                            }, 0);
                        }
                    });
                }
            },
        };

        return directive;
    }
})();
