(function() {
    'use strict';

    angular
        .module('stpApp')
        .factory('errorHandlerInterceptor', errorHandlerInterceptor);

    errorHandlerInterceptor.$inject = ['$q', '$rootScope', 'ConcurrentModalService', 'HTTP_CODE_CONCURRENT_USER_ID_MISMATCH'];

    function errorHandlerInterceptor ($q, $rootScope, ConcurrentModalService, HTTP_CODE_CONCURRENT_USER_ID_MISMATCH) {
        var service = {
            responseError: responseError
        };

        return service;

        function responseError (response) {

            if (response.status === HTTP_CODE_CONCURRENT_USER_ID_MISMATCH) {
                ConcurrentModalService.openModal();
                return $q.reject(response);
            }

            if (!(response.status === 401 && (response.data === '' || (response.data.path && response.data.path.indexOf('/api/account') === 0 )))) {
                $rootScope.$emit('stpApp.httpError', response);
            }
            return $q.reject(response);
        }
    }
})();
