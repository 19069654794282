(function () {
    'use strict';
    angular
        .module('stpApp')
        .factory('Route', Route);

    Route.$inject = ['$resource'];

    function Route ($resource) {
        var resourceUrl = 'stpcore/' + 'api/routes/:id';
        var resourceRouteUrl = 'stpcore/api/routes';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method: 'PUT' },
            'getGeoJson': {
                url: resourceRouteUrl + '/geojson',
                method: 'GET'
            },
            'updateTerritoryRoutes': {
                url: resourceRouteUrl + '/territory',
                method: 'PUT'
            },
            'updateWeekRoutes': {
                url: resourceRouteUrl + '/week',
                method: 'PUT'
            },
            'updateDepotRoutes': {
                url: 'stpcore/' + 'api/projects/:projectId/depots/:id%3AmapOutletsToDepot',
                method: 'POST'
            },
            'updateDayRoutes': {
                url: resourceRouteUrl + '/day',
                method: 'PUT'
            },
            'getSummary': {
                url: resourceRouteUrl + '/summary',
                method: 'GET',
                isArray: true
            },
            'updateRouteSeq': {
                url: resourceUrl + '/seq',
                method: 'PUT',
            },
            'resetRouteSeq': {
                url: resourceRouteUrl + '/reset-route-sequence',
                method: 'PUT',
                params: {
                    projectId: '@projectId',
                    territory: '@territory',
                    week: '@week',
                    day: '@day',
                    depot: '@depot'
                }
            },
            'balancingTerritory': {
                url: resourceRouteUrl + '/balance',
                method: 'PUT'
            },
            'balancingDepot': {
                url: resourceRouteUrl + '%3AbalanceDepot',
                method: 'POST'
            },
            'balancingWeek': {
                url: resourceRouteUrl + '%3AbalanceWeek',
                method: 'POST'
            },
            'balancingDay': {
                url: resourceRouteUrl + '%3AbalanceDay',
                method: 'POST'
            },
            'lockStops': {
                url: resourceRouteUrl + '/lockStops',
                method: 'POST'
            },
            'territoryMerge': {
                url: resourceRouteUrl + '/territory%3Amerge',
                method: 'POST'
            },
            'autoBalance': {
                url: resourceRouteUrl + '%3Abalance',
                params: {
                    depotId: '@depotId',
                    projectId: '@projectId',
                },
                method: 'POST'
            },
            'partialImport': {
                params: {
                    projectId: '@projectId',
                },
                url: 'stpcore/' + 'api/projects/:projectId/routes%3ApartialImport',
                method: 'POST'
            }
        });
    }
})();
