(function() {
    'use strict';

    angular
        .module('stpApp')
        .controller('MapPropertyDialogController', MapPropertyDialogController);

    MapPropertyDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', '$q', 'DataUtils', 'entity', 'MapProperty', 'Project'];

    function MapPropertyDialogController ($timeout, $scope, $stateParams, $uibModalInstance, $q, DataUtils, entity, MapProperty, Project) {
        var vm = this;

        vm.mapProperty = entity;
        vm.clear = clear;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;
        vm.save = save;
        vm.projects = Project.query({filter: 'mapproperty-is-null'});
        $q.all([vm.mapProperty.$promise, vm.projects.$promise]).then(function() {
            if (!vm.mapProperty.projectId) {
                return $q.reject();
            }
            return Project.get({id : vm.mapProperty.projectId}).$promise;
        }).then(function(project) {
            vm.projects.push(project);
        });

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.mapProperty.id !== null) {
                MapProperty.update(vm.mapProperty, onSaveSuccess, onSaveError);
            } else {
                MapProperty.save(vm.mapProperty, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('stpApp:mapPropertyUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();
