;(function() {
    'use strict';
    angular
        .module('stpApp')
        .factory('Depot', Depot);

    Depot.$inject = ['$resource'];

    function Depot ($resource) {
        var resourceUrl =  'stpcore/' + 'api/projects/:projectId/depots/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'getDepotStatsSummary': {
                url: 'stpcore/' + 'api/projects/:projectId/depot-stats-summary',
                method: 'GET', isArray: true
            },
            'update': { method:'PUT' },
            'calculateTerritory': {
                url: 'stpcore/' + 'api/projects/:projectId/depots/:id%3AgetTerritoryRecommendation',
                method:'POST',
                responseType: "json",
                isArray: false,
                transformResponse: function(data) {
                    return {value: data};
                }
            }
        });
    }
})();
