(function () {
    'use strict';

    angular
        .module('stpApp')
        .constant('RoutePlanningConstants', {
            TERRITORY: 'territory',
            TERRITORIES: 'territories',
            DEPOT: 'depot',
            DEPOTS: 'depots',
            WEEK: 'week',
            WEEKS: 'weeks',
            DAY: 'day',
            DAYS: 'days',
            POINT: 'POINT',
            MODEOPTIONS: [
                { key: "KELURAHAN", name: "Select by Kelurahan", translate: "stpApp.route.tooltips.selectBy.KELURAHAN" },
                { key: "KECAMATAN", name: "Select by Kecamatan ", translate: "stpApp.route.tooltips.selectBy.KECAMATAN" },
                { key: "KABUPATEN", name: "Select by Kabupaten", translate: "stpApp.route.tooltips.selectBy.KABUPATEN" },
                { key: "PROVINSI", name: "Select by Provinsi", translate: "stpApp.route.tooltips.selectBy.PROVINSI" },
                { key: "POINT", name: "Select by Point", translate: "stpApp.route.tooltips.selectBy.POINT" }
            ]
        });
})();
