(function() {
    'use strict';

    angular
        .module('stpApp')
        .controller('ResetDialogController',ResetDialogController);

    ResetDialogController.$inject = ['$uibModalInstance'];

    function ResetDialogController($uibModalInstance) {
        var vm = this;

        vm.clear = clear;
        vm.reset = reset;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function reset () {
            $uibModalInstance.close();
        }
    }
})();
