(function(){
    'use strict';

    angular
        .module('stpApp')
        .controller('StopsTransferDialogController', StopsTransferDialogController);

    StopsTransferDialogController.$inject = ['$uibModalInstance', 'Route', 'projectId', 'depot', 'keyOrigin', 'origin', 'territory'];

    function StopsTransferDialogController($uibModalInstance, Route, projectId, depot, keyOrigin, origin, territory) {
        var vm = this;
        vm.isSaving = false
        vm.origin = null
        vm.territories = null

        vm.form = {
            origin: null,  // origin of territory
            destination: null // destination of territory
        }
        vm.valid = {
            unique: {
                originDestination : false
            }
        }

        vm.close = close;
        vm.save = save;
        vm.validation = validation;

        vm.$onInit = function () {
            _updateForm()
        }

        function close() {
            $uibModalInstance.dismiss();
        }

        function save() {
            vm.isSaving = true
            var body = _createFromForm(vm.form)
            var params = null
            Route.territoryMerge(params, body, _success, _error);

            function _success() {
                vm.isSaving = false
                $uibModalInstance.close(true);
            }

            function _error(err) {
                console.error(err.message);
            }
        }

        function validation() {
            if (vm.form.origin == vm.form.destination) {
                vm.isSaving = true
                vm.valid.unique.originDestination = true
                return
            }
            vm.isSaving = false
            vm.valid.unique.originDestination = false
        }

        function _updateForm() {
            vm.form.origin = keyOrigin
            vm.origin = angular.copy(origin)
            vm.territories = angular.copy(territory)
            vm.origin.name = 'Territory '+ keyOrigin + (origin.name ? ' - ' + origin.name : '') 
        }

        function _createFromForm(form) {
            return {
                projectId: projectId,
                depotId: Number(depot),
                origin: Number(form.origin),
                destination: Number(form.destination)
            }
        }
    }
})();
