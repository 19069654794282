(function() {
    'use strict';

    angular
        .module('stpApp')
        .factory('RouteDayWeekGen', RouteDayWeekGen);

    RouteDayWeekGen.$inject = ['DayGap'];

    function RouteDayWeekGen(DayGap) {
        var service = {
            visitMatrix : visitMatrix
        };

        return service;

        function visitMatrix(dw, fd, fw, maxDay, maxWeek, uniqueDays) {
            var visitLength = (maxWeek / dw[1]) * dw[0];
            var visits = [];
            var week = fw;
            var day = fd;
            var days = uniqueDays ? uniqueDays : DayGap.findDayGap(maxDay, fd, dw[0]);

            if (dw[0] === 1) {
                for (var i = 0; i < visitLength; i++) {
                    if (week > maxWeek) {
                        week = 1;
                    }

                    if (day > maxDay) {
                        day = 1;
                    }

                    visits.push({
                        w: week,
                        d: day,
                    });

                    if (dw[1] > 1) {
                        if (week + dw[1] > maxWeek) {
                            week = week + dw[1] - maxWeek;
                        } else {
                            week += dw[1];
                        }
                    }

                    if (dw[1] === 1) {
                        week += 1;
                    }
                }
            }

            if (dw[0] > 1) {
                for (var _i = 0; _i < maxWeek; _i++) {
                    if (week > maxWeek) {
                        week = 1;
                    }

                    for (var j = 0; j < dw[0]; j++) {
                        visits.push({ w: week, d: days[j] });
                    }

                    week += 1;
                }
            }

            return visits;
        }
    }
})();
