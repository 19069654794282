(function () {
    'use strict';

    angular
        .module('stpApp')
        .factory('OsrmAddress', OsrmAddress);

    OsrmAddress.$inject = ['ApplicationSetting'];

    function OsrmAddress (ApplicationSetting) {
        var osrmAddressKey = 'osrmAddress';
        var filterBy = 'key.equals';

        var reqParam = {};
        reqParam[filterBy] = osrmAddressKey;

        return {
            getAddress: getAddress
        };

        function getAddress(){
            function _onSuccess(data){
                return data.osrmAddress ? data.osrmAddress : 'https://osrm.klikpeta.com';
            }

            function _onError() {
                return 'osrmAddres not found';
            }

            return ApplicationSetting.getAppProp().$promise.then(_onSuccess, _onError);
        }
    }
})();
