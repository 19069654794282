(function() {
    'use strict';

    var bpsSetting = {
        templateUrl: 'app/route/bps-setting/bps-setting.component.html',
        controller: bpsSettingController,
        controllerAs: 'vm',
        bindings: {
            onBpsSave: '&',
            project: '<',
        }
    };

    angular
        .module('stpApp')
        .component('bpsSetting', bpsSetting);

    bpsSettingController.$inject = ['$scope', '$rootScope', 'BpsService', 'Project', 'AlertService'];

    function bpsSettingController ($scope, $rootScope, BpsService, Project, AlertService) {
        var vm = this;

        vm.years = [];
        vm.rootList = [];
        vm.isSaving = false;
        vm.checkAllPoi = false;
        vm.loadListError = false;

        vm.$onInit = onInit;
        vm.changeSelectedStatus = changeSelectedStatus;
        vm.changeAllRootStatus = changeAllRootStatus;
        vm.checkParentSelectedStatus = checkParentSelectedStatus;
        vm.onSearch = onSearch;
        vm.updateProject = updateProject;
        vm.loadList = loadList;

        function onInit() {
            if(!vm.project || !vm.project.props)  {
                return;
            }

            if (!vm.project.props.selectedDemographics){
                vm.project.props.selectedDemographics = [];
            }

            loadList();
        }

        function updateProject() {
            var selectedBps = [];

            vm.isSaving = true;

            vm.rootList.forEach(function(eachRoot){
                eachRoot.child.forEach(function(leaf1){
                    var selectedLeaf2 = leaf1.child.filter(function(leaf2){
                        return leaf2.selected;
                    });
                    selectedBps = selectedBps.concat(selectedLeaf2);
                });
            });

            selectedBps = selectedBps.map(function(eachSelectedBps){
                return {
                    dataTypeId: eachSelectedBps.categoryId.dataTypeId.id,
                    categoryId: eachSelectedBps.categoryId.id,
                    dataItemId: eachSelectedBps.id,
                    name: eachSelectedBps.categoryId.category + '_' + eachSelectedBps.columnAlias,
                    year: vm.years[0]
                }
            });

            if (!vm.project.props.selectedDemographics){
                vm.project.props.selectedDemographics = [];
            }

            vm.project.props.selectedDemographics = selectedBps;

            Project.update(vm.project).$promise.then(function(){
                vm.isSaving = false;
                var result = {
                    status: 200,
                    message: 'Successfully update setting BPS',
                }
                AlertService.closeAlert();
                AlertService.success(result.message, {}, 'top-right');
                $rootScope.$broadcast('update-bps-setting')
            });
        }

        function changeSelectedStatus(item) {
            if(!item.child || !item.child.length) {
                return;
            }

            item.child.forEach(function(eachChild) {
                eachChild.selected = item.selected;
                changeSelectedStatus(eachChild);
            });
            item.partial = false;
        }

        function changeAllRootStatus(flag) {
            vm.rootList.forEach(function(root) {
                root.selected = flag;
                changeSelectedStatus(root);
            });
        }

        function checkParentSelectedStatus(root) {
            root.child.forEach(function(leaf1){
                leaf1.selected = leaf1.child.every(function(leaf2){
                    return leaf2.selected;
                });
                leaf1.partial = leaf1.selected ? false : leaf1.child.some(function(leaf2){
                    return leaf2.selected;
                });
            });

            root.selected = root.child.every(function(leaf1){
                return leaf1.selected;
            });
            root.partial = root.selected ? false : root.child.some(function(leaf1){
                return leaf1.selected || leaf1.partial;
            });

            vm.checkAllPoi = vm.rootList.every(function(each){
                return each.selected;
            });
        }

        function onSearch() {
            var isOpen = vm.search.length ? true : false;
            vm.rootList.forEach(function(root){
                root.isOpen = isOpen;
                root.child.forEach(function(leaf1){
                    leaf1.isOpen = isOpen;
                });
            });
        }

        function loadList() {
            vm.loadListError = false;
            BpsService.getDataType().$promise.then(function(root){
                root.forEach(function(eachRoot){
                    eachRoot.child = [];
                    BpsService.getCategoryByDataType({dataTypeId: eachRoot.id}).$promise.then(function(leaf1){
                        eachRoot.child = leaf1;
                        leaf1.forEach(function(eachLeaf1){
                            eachLeaf1.child = [];
                            BpsService.getDataItemByCategory({categoryId: eachLeaf1.id}).$promise.then(function(leaf2){
                                eachLeaf1.child = leaf2;
                                eachLeaf1.child.forEach(function(eachLeaf2){
                                    var isOnList = vm.project.props.selectedDemographics.find(function(eachBps){
                                        return eachBps.dataItemId == eachLeaf2.id;
                                    });
                                    eachLeaf2.selected = isOnList ? true : false;
                                });
                                checkParentSelectedStatus(eachRoot);
                            }, onLoadListError);
                        });
                    }, onLoadListError);
                });
                BpsService.getYearByDataType({dataTypeName: root[0].tableAlias}, function(years){
                    vm.years = years;
                });
                vm.rootList = root;
            }, onLoadListError);
        }

        function onLoadListError() {
            vm.loadListError = true;
        }
    }
})();
