(function () {
    "use strict";

    angular
        .module("stpApp")
        .controller("RoutingDetailListDialogController", RoutingDetailListDialogController);

    RoutingDetailListDialogController.$inject = ["$stateParams", "$uibModalInstance", "DailyRouteStatService", "depotResource", "Route", "filterBy", "AlertService"
        , "projectResource", "FormatterUtil", "$q", "stopLimit", "RoutePlanningDataShare", "unassignStops", "$filter", "OutletAssignmentService", "Project", "$translate"];

    function RoutingDetailListDialogController($stateParams, $uibModalInstance, DailyRouteStatService, depotResource, Route, filterBy, AlertService
        , projectResource, FormatterUtil, $q, stopLimit, RoutePlanningDataShare, unassignStops, $filter, OutletAssignmentService, Project, $translate) {
        var vm = this;

        vm.sharedData = RoutePlanningDataShare;

        // actions
        vm.clear = clear;
        vm.loadDailyRoute = loadDailyRoute;
        vm.openRoute = openRoute;
        vm.setShortestTrip = setShortestTrip;
        vm.getInStopDuration = getInStopDuration;
        vm.getTimeWindow = getTimeWindow;
        vm.changeTab = changeTab;
        vm.isCheckAll = false;
        vm.checkAllUnassignStop = checkAllUnassignStop;
        vm.filterUnassignStop = filterUnassignStop;
        vm.depotChange = depotChange;
        vm.checkUnassignStop = checkUnassignStop;
        vm.filterDailyRoute = filterDailyRoute;
        vm.applyToRoute = applyToRoute;

        // models
        vm.filterBy = filterBy;
        vm.sharedData.depots = depotResource;
        vm.selectedDepot = vm.sharedData.filters.depot ? vm.sharedData.depots.find(function(x){return x.id == vm.sharedData.filters.depot}) : vm.sharedData.depots[0];
        vm.stopLimit = stopLimit;
        vm.tooltipValue = {
            stopLimit: stopLimit
        };
        vm.project = projectResource;
        vm.routeDetailListsData = [];
        vm.routeDetailLists = [];
        vm.territoryCount = Object.keys(vm.sharedData.dataStyle.depots[vm.selectedDepot.id].territories).length;
        vm.weekCount = Object.keys(vm.sharedData.dataStyle.depots[vm.selectedDepot.id].weeks).length;
        vm.dailyRouteFilter = {
            territory: '',
            week: '',
            day: '',
            stopCount: '',
            totalTravelDurationHumanized: '',
            totalTravelDistanceHumanized: '',
            totalInOutletTimeDurationHumanized: '',
            startTime: '',
            finishAtDepotTime: '',
            endTime: '',
            type: '',
        };
        vm.mainUnassignStopsData = unassignStops;
        vm.predicate = "territory";
        vm.reverse = true;
        vm.itemsPerPage = 10;
        vm.page = 1;
        vm.totalItems = 0;
        vm.isLoadingList = false;
        vm.tabIndex = 1;
        vm.unassignStopById = enrichUnassignStops(vm.mainUnassignStopsData);
        vm.unassignStops = [];
        vm.unassignStopFilter = {
            externalId: '',
            name: '',
            visitFreq: '',
            value: '',
            inStopDuration: '',
            timeWindow: '',
            notes: '',
        };
        vm.selectedUassignStop = [];
        vm.isApplyToRoute = false;
        vm.isReloadViolation = false;

        vm.loadDailyRoute();

        function clear() {
            $uibModalInstance.dismiss(vm.isReloadViolation);
        }

        function loadDailyRoute() {
            vm.isLoadingList = true;
            vm.travelingTimeSummaries = {
                total: 0,
                avg: 0,
                totalHumanized: 'N/A',
                avgHumanized: 'N/A'
            };
            vm.distanceSummaries = {
                total: 0,
                avg: 0,
                totalHumanized: 'N/A',
                avgHumanized: 'N/A'
            };
            vm.timeSummaries = {
                fastestFinishTime : 'N/A',
                avgFinishTime : 'N/A',
            };
            vm.stopSummaries = {
                total: 0,
                avg: 0,
            };
            vm.routeSummaries = {
                overtime: 0,
                exceed: 0,
            };
            vm.tripSummaries = {
                total: 0,
                avg: 0,
                max: 0,
            };

            var _deferredData = [];
            var _useCapacity = vm.project.props.useCapacity;
            var _paramsPerDayStats = {
                projectId: vm.selectedDepot.projectId,
                depotId: vm.selectedDepot.id,
                refresh: _useCapacity ? false : true
            };
            var _paramsPerDayStatOversize = {
                projectId: vm.selectedDepot.projectId,
                depotId: vm.selectedDepot.id,
            };
            var _listResolver = $q.defer();
            var _perDayStats = DailyRouteStatService.getListOfPerDayStats(_paramsPerDayStats).$promise.catch(function(){return [];});
            var _perDayStatsOversize = DailyRouteStatService.getListOfPerDayOversizedRoutes(_paramsPerDayStatOversize).$promise.catch(function(){return [];});
            var _perDayStatsOvercapacity = DailyRouteStatService.getListOfPerDayOvercapacityRoutes(_paramsPerDayStatOversize).$promise.catch(function(){return [];});
            var _promises = [_perDayStats, _perDayStatsOversize, _perDayStatsOvercapacity];
            $q.all(_promises).then(_onLoadPerDayStatsSuccess, _onLoadPerDayStatsError);

            function _onLoadPerDayStatsSuccess(dataList){
                vm.isLoadingList = false;
                vm.routeDetailListsData = _flattenArray(dataList);
                vm.routeDetailListsData.forEach(__enrichRouteDetailLists);
                countSummaries(vm.routeDetailListsData);

                vm.routeDetailLists = vm.routeDetailListsData;
                vm.totalItems = vm.tabIndex === 1 ? vm.routeDetailLists.length : vm.unassignStops.length;

                // promise resolve
                _deferredData = vm.routeDetailListsData;
                _listResolver.resolve(_deferredData);

                function __enrichRouteDetailLists(eachData){
                    if(eachData.finishAtDepotTime){
                        var __finishTimeDate = moment("1970-01-01 " + eachData.finishAtDepotTime).add(parseInt(eachData.overnight), "days");
                        var __endTimeDate = moment("1970-01-01 " + eachData.dayTimeWindow.endTime);
                        var __startTimeDate = moment("1970-01-01 " + eachData.dayTimeWindow.startTime);
                        eachData.totalTravelDistanceHumanized = FormatterUtil.humanizeDistanceFormat(eachData.totalTravelDistance);
                        eachData.totalTravelDurationHumanized = FormatterUtil.humanizeDurationFormat(eachData.totalTravelDuration);
                        eachData.finishAtDepotTime = __finishTimeDate.toDate();
                        eachData.endTime = __endTimeDate.toDate();
                        eachData.startTime = __startTimeDate.toDate();
                        eachData.isOvertime = __finishTimeDate.isAfter(__endTimeDate);
                        eachData.totalInOutletTime = Math.abs(__finishTimeDate.diff(__startTimeDate, 'seconds') - eachData.totalTravelDuration);
                        eachData.totalInOutletTimeDurationHumanized = FormatterUtil.humanizeDurationFormat(eachData.totalInOutletTime);
                    } else {
                        eachData.isOvertime = false;
                        eachData.distanceCost = 0;
                    }
                    eachData.id = eachData.territory + "" + eachData.week + "" + eachData.day;
                    eachData.overcapacity = vm.filterBy === 'OVERCAPACITY'? true : eachData.overcapacity;
                }

                vm.filterDailyRoute();
            }

            function _onLoadPerDayStatsError(error) {
                AlertService.error(error.data.exception);
                vm.isLoadingList = false;
            }

            function _flattenArray(dataList) {
                var flattenedArray = [];
                dataList[2].forEach(function(each){
                    each.overcapacity = true;
                });

                dataList.forEach(function(subArray) {
                    flattenedArray = flattenedArray.concat(subArray);
                });
                return flattenedArray;
            }

            return _listResolver.promise;
        }

        function openRoute(route) {
            var closeParams = {
                territory: route.territory,
                week: route.week,
                day: route.day,
                depot: vm.selectedDepot.id,
                view: vm.sharedData.currentView
            };
            $uibModalInstance.close(closeParams);
        }

        function setShortestTrip(route) {
            var selectedRouteId = route.id;

            route.onProgress = true;

            var setShortestTripParams = {
                projectId: $stateParams.id,
                territory: route.territory,
                week: route.week,
                day: route.day,
                depot: vm.selectedDepot ? vm.selectedDepot.id : null,
            }

            mixpanel.time_event('Set Shortest Trip Successful')

            Route.resetRouteSeq(setShortestTripParams, onSetShortestTripSuccess, onSetShortestTripError);

            function onSetShortestTripSuccess(data) {
                vm.isReloadViolation = true;
                mixpanel.track('Set Shortest Trip Successful', {
                    'isAllRoutes': false,
                    'stopCount': route.stopCount,
                });
                var newList = loadDailyRoute();
                newList.then(onLoadNewListSuccess);

                function onLoadNewListSuccess(data){

                    var selectedData = data.find(function(x){
                        return x.id == selectedRouteId;
                    });

                    var isShowBlueAlert = !selectedData ? true : selectedData.isOvertime ? false : true;

                    route.onProgress = false;

                    if (isShowBlueAlert) {
                        AlertService.info("Congratulations! now finish time lower than end time.");
                    } else {
                        AlertService.warning("Finish time is still greater than end time. Make sure there are not too many stop on the road");
                    }
                }
            }

            function onSetShortestTripError(error) {
                AlertService.error(error.data.message);
                route.onProgress = false;
            }
        }

        function countSummaries(dailyRouteList) {
            if(!dailyRouteList || !dailyRouteList.length) {
                return;
            }

            var routeCount = dailyRouteList.filter(function(d) {
                return d.type !== 'OVERSIZE';
            });

            // traveling time
            vm.travelingTimeSummaries.total = dailyRouteList.reduce(function(total, each){
                if(each.totalTravelDuration) {
                    total += each.totalTravelDuration;
                }
                return total;
            },0);
            vm.travelingTimeSummaries.totalHumanized = FormatterUtil.humanizeDurationFormat(vm.travelingTimeSummaries.total);
            vm.travelingTimeSummaries.avg = vm.travelingTimeSummaries.total / routeCount.length;
            vm.travelingTimeSummaries.avgHumanized = FormatterUtil.humanizeDurationFormat(vm.travelingTimeSummaries.avg);

            // distance
            vm.distanceSummaries.total = dailyRouteList.reduce(function(total, each){
                if(each.totalTravelDistance) {
                    total += each.totalTravelDistance;
                }
                return total;
            },0);
            vm.distanceSummaries.totalHumanized = FormatterUtil.humanizeDistanceFormat(vm.distanceSummaries.total);
            vm.distanceSummaries.avg = vm.distanceSummaries.total / routeCount.length;
            vm.distanceSummaries.avgHumanized = FormatterUtil.humanizeDistanceFormat(vm.distanceSummaries.avg);

            // stop
            vm.stopSummaries.total = dailyRouteList.reduce(function(sum, each){
                sum += each.stopCount;
                return sum;
            }, 0);

            vm.stopSummaries.avg = vm.stopSummaries.total / dailyRouteList.length;
            vm.stopSummaries.avg = Math.round(vm.stopSummaries.avg);

            // time
            var hasFinishTime = dailyRouteList.filter(function(each){
                return each.finishAtDepotTime;
            });

            var hasFinishTimeInMs = hasFinishTime.map(function(each){
                return moment(each.finishAtDepotTime).valueOf();
            });

            var fastestFinishTimeInMs = hasFinishTimeInMs.reduce(function(min, each) {
                min = min > each ? each : min;
                return min;
            }, Infinity);

            var totalFinishTimeInMs = hasFinishTime.reduce(function(total, each){
                total += moment(each.finishAtDepotTime).valueOf();
                return total;
            }, 0);

            vm.timeSummaries.fastestFinishTime = moment(fastestFinishTimeInMs).format('HH:mm');
            vm.timeSummaries.avgFinishTime = moment(totalFinishTimeInMs / hasFinishTime.length).format('HH:mm');

            // route
            vm.routeSummaries.overtime = dailyRouteList.filter(function(e){return e.type === 'OVERTIME';}).length;
            vm.routeSummaries.exceed = dailyRouteList.filter(function(e){return e.type === 'OVERSIZE';}).length;

            // trip
            vm.tripSummaries.max = dailyRouteList.reduce(function(max, each) {
                if (each.type !== 'OVERSIZE') {
                    max = max < each.numOfTrip ? each.numOfTrip : max;
                }
                return max;
            }, 0);

            vm.tripSummaries.total = dailyRouteList.reduce(function(sum, each) {
                if (each.type !== 'OVERSIZE') {
                    sum += each.numOfTrip;
                }
                return sum;
            }, 0);
            vm.tripSummaries.avg = vm.tripSummaries.total / routeCount.length;
            vm.tripSummaries.avg = Math.round(vm.tripSummaries.avg);
        }

        function getInStopDuration(unassignStop) {
            if (!unassignStop.inStopDuration) {
                return 0;
            }
            return  (unassignStop.inStopDuration/1000)/60;
        }

        function getTimeWindow(timewindow) {
            if (!timewindow) {
                return '';
            }

            var startTime = timewindow.startTime;
            var endTime = timewindow.endTime;

            if ( !startTime || !endTime) {
                return '';
            }

            return moment.unix(startTime).utc().format('HH:mm') + ' - ' + moment.unix(endTime).utc().format('HH:mm');

        }

        function changeTab(index) {
            vm.predicate = index === 1 ? "territory": "externalId";
            vm.tabIndex = index;
            vm.reverse = true;
            vm.page = 1;

            vm.totalItems = index === 1 ? vm.routeDetailLists.length : vm.unassignStops.length;
            resetUnassignFilter();
            resetDailyRouteFilter();
            vm.filterUnassignStop();
        }

        function checkAllUnassignStop() {
            vm.unassignStops.forEach(function(u){
                u.checked = vm.isCheckAll;
            });

            vm.selectedUassignStop = vm.unassignStops.filter(function(u){return u.checked;});
        }

        function checkUnassignStop() {
            if (!vm.unassignStops.length) {
                vm.isCheckAll = false;
                return;
            }

            vm.isCheckAll = vm.unassignStops.every(function(u) {
                return u.checked;
            });

            vm.selectedUassignStop = vm.unassignStops.filter(function(u){return u.checked;});
        }

        function filterUnassignStop() {
            vm.unassignStops = $filter('filter')(vm.unassignStopById, vm.unassignStopFilter);
            vm.totalItems = vm.tabIndex === 1 ? vm.routeDetailLists.length : vm.unassignStops.length;
            vm.checkUnassignStop();
        }

        function filterDailyRoute() {
            if(!vm.routeDetailListsData.length) {
                return;
            }

            vm.routeDetailLists = $filter('filter')(vm.routeDetailListsData, vm.dailyRouteFilter);
            vm.totalItems = vm.tabIndex === 1 ? vm.routeDetailLists.length : vm.unassignStops.length;
        }

        function enrichUnassignStops(unassingStops) {
            if (!unassingStops.length) {
                return [];
            }

            var enrichData = unassingStops.map(function(us) {
                return {
                    depotId: us.depot.id,
                    externalId: us.externalId,
                    id: us.id,
                    inStopDuration: getInStopDuration(us),
                    lat: us.lat,
                    lon: us.lon,
                    name: us.name,
                    projectId: us.projectId,
                    projectName: us.projectName,
                    notes: us.props.notes,
                    value: us.props.value ? us.props.value : 0,
                    value2: us.props.value2 ? us.props.value2 : 0,
                    value3: us.props.value3 ? us.props.value3 : 0,
                    reasons: us.reason ? us.reason.split(",").map(function(x){return $translate.instant('unplannedReason.' + x);}).filter(function(value, index, self){
                        return self.indexOf(value) === index;
                    }) : [],
                    visitFreq: us.visitFreq,
                    timeWindow: getTimeWindow(us.props.timeWindow)
                };
            });

            return enrichData.filter(function(eData) {
                return eData.depotId === vm.selectedDepot.id;
            });
        }

        function resetUnassignFilter() {
            vm.unassignStopFilter = {
                externalId: '',
                name: '',
                visitFreq: '',
                value: '',
                value2: '',
                value3: '',
                inStopDuration: '',
                timeWindow: '',
                notes: '',
            };
        }

        function resetDailyRouteFilter(isResetType) {
            vm.dailyRouteFilter = {
                territory: '',
                week: '',
                day: '',
                stopCount: '',
                totalTravelDurationHumanized: '',
                totalTravelDistanceHumanized: '',
                totalInOutletTimeDurationHumanized: '',
                startTime: '',
                finishAtDepotTime: '',
                endTime: '',
                type: isResetType ? '' : vm.dailyRouteFilter.type
            };
        }

        function depotChange() {
            resetUnassignFilter();
            resetDailyRouteFilter(true);
            vm.loadDailyRoute();
            vm.unassignStopById = enrichUnassignStops(vm.mainUnassignStopsData);
            vm.filterUnassignStop();
        }

        function applyToRoute() {
            vm.isApplyToRoute = true;
            var stopIds = vm.selectedUassignStop.map(function(su){
                return su.id;
            });

            OutletAssignmentService.assign({id: vm.project.id},{outletIds: stopIds}).$promise.then(
                function(){
                    vm.selectedUassignStop = [];
                    vm.isApplyToRoute = false;
                    vm.isReloadViolation = true;
                    vm.loadDailyRoute();
                    loadUnassignStop();
                },
                function() {
                    vm.isApplyToRoute = false;
                }
            );
        }

        function loadUnassignStop() {
            Project.getUnassignStop({ projectId: vm.project.id }, function(data){
                vm.mainUnassignStopsData = data;
                vm.unassignStopById = enrichUnassignStops(vm.mainUnassignStopsData);
                resetUnassignFilter();
                vm.filterUnassignStop();
            });
        }
    }
})();
