(function() {
    'use strict';

    var speedMultiplier = {
        templateUrl: 'app/route/speed-multiplier/speed-multiplier.component.html',
        controller: speedMultiplierController,
        controllerAs: 'vm',
        bindings: {
            project: '='
        }
    };

    angular
        .module('stpApp')
        .component('speedMultiplier', speedMultiplier);

    speedMultiplierController.$inject = ['$scope'];

    function speedMultiplierController ($scope) {
        var vm = this;

        vm.$onInit = onInit;
        vm.$onChanges = onChange;

        vm.speedHumanize = speedHumanize;
        vm.setTravelTimeMultiplier = setTravelTimeMultiplier;

        // value mapper
        vm.travelTimeMultiplierMap = [2, 1.75, 1.5, 1.25, 1, .875, .75, .625, .5];
        vm.humanizeMap = [100, 75, 50, 25, 0, 25, 50, 75, 100];

        function onInit() {
            vm.travelTimeMultiplier = angular.isNumber(vm.project.props.travelTimeMultiplier)
            ? convertTimeMultiplierFromServer(vm.project.props.travelTimeMultiplier)
            : 5;
        }

        function onChange() {
            vm.travelTimeMultiplier = angular.isNumber(vm.project.props.travelTimeMultiplier)
            ? convertTimeMultiplierFromServer(vm.project.props.travelTimeMultiplier)
            : 5;
        }

        function speedHumanize(idx) {
            return vm.humanizeMap[idx - 1];
        }

        function setTravelTimeMultiplier() {
            var travelTimeMultiplier = convertTimeMultiplierToServer(vm.travelTimeMultiplier);
            vm.project.props.travelTimeMultiplier = travelTimeMultiplier;
        }

        function convertTimeMultiplierFromServer(speed) {
            var idx = vm.travelTimeMultiplierMap.findIndex(function(s) {
                return s === speed;
            });

            return idx + 1;
        }

        function convertTimeMultiplierToServer(idx) {
            return vm.travelTimeMultiplierMap[idx - 1];
        }
    }
})();
