(function() {
    'use strict';

    var depotGravity = {
        templateUrl: 'app/route/depot-gravity/depot-gravity.component.html',
        controller: depotGravityController,
        controllerAs: 'vm',
        bindings: {
            onDataReady: '&',
            stops: '<',
            depots: '<'
        }
    };

    angular
        .module('stpApp')
        .component('depotGravity', depotGravity);

    depotGravityController.$inject = ['$scope', 'AdmarService'];

    function depotGravityController ($scope, AdmarService) {
        var vm = this;
        vm.$onInit = onInit;
        vm.$onChanges = onChange;
        vm.distanceSegments = [];
        vm.BBoxString = '';
        vm.onBatasWilayahChange = onBatasWilayahChange;
        vm.isLoadAdmar = false;
        vm.colors = ['#018A2C', '#FCDE02', '#DB0A13'];
        vm.depotGravity = [];

        function onInit() {
            vm.batasWilayah = 'KECAMATAN';
            vm.showDepotGravity = false;
        }

        function onChange() {
            if(vm.stops && vm.depots) {
                vm.BBoxString = getBBox();
                vm.distanceSegments = createDistanceSegment();
                vm.onBatasWilayahChange();
            }
        }

        function getBBox() {
            if (!vm.depots || !vm.depots.length || !vm.stops) {
                return null;
            }

            function _convertDepotToFeature(depot) {
                var feature = {
                    "type": 'Feature',
                    "geometry" : {
                        "type": 'Point',
                        "coordinates": [depot.lon, depot.lat]
                    },
                    "properties": {}
                }
                return feature;
            }

            var featureCollection = {
                type: 'FeatureCollection',
                features: vm.stops.features.concat(vm.depots.map(_convertDepotToFeature)),
            };
            var bbox = turf.bbox(featureCollection);

            return bbox;
        }

        function createDistanceSegment() {
            if (!vm.depots || !vm.depots.length || !vm.stops) {
                return [];
            }

            var count = 3;
            var distanceSegment = [];
            var maxDistance = findMaximumDistance();
            var step = Number((maxDistance / count).toFixed(0));
            var maxValue = step;
            var minValue = 0;

            for (var i = 0; i < count; i++) {
                distanceSegment[i] = {
                    maxValue : maxValue,
                    minValue: minValue,
                    color: vm.colors[i]
                }

                minValue = maxValue + 0.1;
                maxValue = maxValue + step;
            }

            return distanceSegment;
        }

        function findMaximumDistance() {
            if (!vm.depots || !vm.depots.length || !vm.stops) {
                return 0;
            }

            function _filterStopByDepotId(id) {
                var stops = vm.stops.features.filter(function(feature){
                    return feature.properties.depotId == id;
                });

                return stops;
            }

            var maxDistance = 0;

            // find maxDistance
            vm.depots.forEach(function(depot) {
                var from = [depot.lon, depot.lat];
                var stops = _filterStopByDepotId(depot.id);

                stops.forEach(function(features) {
                    var to = features.geometry.coordinates;
                    var distanceFromDepot = Number(turf.distance(from, to).toFixed(0));
                    maxDistance = maxDistance > distanceFromDepot ? maxDistance : distanceFromDepot;
                });
            });

            return maxDistance;
        }

        function onBatasWilayahChange() {
            if (!vm.BBoxString || !vm.showDepotGravity) {
                vm.depotGravity = [];
                vm.onDataReady({depotGravityLayers: vm.depotGravity});
                return;
            }

            var params = {
                areaType: vm.batasWilayah,
                boundingBox: vm.BBoxString
            };

            vm.isLoadAdmar = true;

            AdmarService.getAdmAreaByBBox(params, function(batasWilayah) {

                var boundaries = vm.depots.map(function (depot) {
                    var from = [depot.lon, depot.lat];
                    return batasWilayah.features.map(function (basWil) {
                        var to = turf.centroid(basWil);
                        var distanceFromDepot = turf.distance(from, to);
                        var result = angular.copy(basWil);
                        var color = vm.distanceSegments.reduce(function (acc, curr) {
                            if (distanceFromDepot <= curr.maxValue + 0.1  && distanceFromDepot >= curr.minValue) {
                                acc = curr.color;
                            }
                            return acc;
                        }, null);
                        result.properties = {
                            distanceFromDepot: distanceFromDepot,
                            color: color,
                            depotId: depot.id
                        };
                        return result;
                    });
                }).flat()

                vm.depotGravity = vm.colors.map(function (color) {
                    var geom = {
                        type: 'FeatureCollection',
                        features: boundaries.filter(function (boundary) {
                            return boundary.properties.color === color;
                        }),
                    };

                    var geomLayer = new L.geoJSON(geom, {
                        style: function (feature) {
                            return {
                                fill: feature.properties.color ? true : false,
                                fillColor: feature.properties.color,
                                color: feature.properties.color,
                                weight: 1,
                                opacity: 1,
                                fillOpacity: 0.9,
                            };
                        },
                    });

                    return geomLayer;
                });

                vm.onDataReady({depotGravityLayers: vm.depotGravity});
                vm.isLoadAdmar = false;
            }, function(error){
                vm.isLoadAdmar = false;
            });

        }
    }
})();
