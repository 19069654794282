(function() {
    'use strict';

    var jituPoiData = {
        templateUrl: 'app/route/jitu-poi-data/jitu-poi-data.html',
        controller: jituPoiDataController,
        controllerAs: 'vm',
        bindings: {
            projectProp: '<',
            project: '<',
            depot: '<',
            territories: '<',
            weeks: '<',
            days: '<',
            isVisible: '<'
        }
    };

    angular
        .module('stpApp')
        .component('jituPoiData', jituPoiData);

    jituPoiDataController.$inject = ['$scope', 'JituPoiStatistic', '$rootScope'];

    function jituPoiDataController ($scope, JituPoiStatistic, $rootScope) {
        var vm = this;

        vm.$onInit = $onInit;
        vm.$onDestroy = $onDestroy;
        vm.$onChanges = $onChanges;
        vm.poiStatistic;
        vm.loadingPoiStatistic = false;
        vm.tempTerritories;
        vm.getJituPoiStatistic = getJituPoiStatistic;
        vm.dataNotFound = false;
        vm.failedLoadData = false;

        function $onInit() {
            vm.tempTerritories = angular.copy(vm.territories);
            vm.tempDays = angular.copy(vm.days);
            vm.tempWeeks = angular.copy(vm.weeks);
        }

        function $onChanges(changes) {
            // load poi data for first time
            if(vm.isVisible && !vm.loadingPoiStatistic && !vm.poiStatistic) {
                getJituPoiStatistic();
            }

            // load poi data if it has update
            if(vm.isVisible && !vm.loadingPoiStatistic && vm.hasUpdate) {
                getJituPoiStatistic();
            }
        }

        function $onDestroy() {
            vm.changeView();
            vm.poiSettingUpdate();
        }

        function getJituPoiStatistic() {
            vm.loadingPoiStatistic = true;
            vm.hasUpdate = false;
            vm.dataNotFound = false;
            vm.failedLoadData = false;

            var params = {
                depot: vm.depot,
                project: vm.project,
                territories: vm.territories,
                weeks: vm.weeks,
                days: vm.days
            }
            JituPoiStatistic.get(params, _onSuccess, _onFailed);

            function _onSuccess(data) {
                vm.poiStatistic = data;
                vm.loadingPoiStatistic = false;
            }

            function _onFailed(error) {
                if(error.status == 404) {
                    vm.dataNotFound = true;
                } else {
                    vm.failedLoadData = true;
                }
                vm.loadingPoiStatistic = false;
            }
        }

        vm.changeView  = $scope.$on('change-view', function(){
            if(vm.tempTerritories != vm.territories) {
                vm.tempTerritories = angular.copy(vm.territories);
                triggerUpdate();
            }

            if(vm.tempWeeks != vm.weeks) {
                vm.tempTerritories = angular.copy(vm.territories);
                triggerUpdate();
            }

            if(vm.tempDays != vm.days) {
                vm.tempTerritories = angular.copy(vm.territories);
                triggerUpdate();
            }
        });
        vm.stopUpdate = $scope.$on('update-stop', triggerUpdate);

        vm.poiSettingUpdate = $rootScope.$on('update-poi-setting', triggerUpdate);

        function triggerUpdate() {
            vm.hasUpdate = true;
        }
    }
})();
