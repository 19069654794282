(function() {
    'use strict';

    angular
        .module('stpApp')
        .factory('StopSearch', StopSearch);

    StopSearch.$inject = ['$resource'];

    function StopSearch($resource) {
        var resourceUrl =  'stpcore/' + 'api/_search/stops/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
