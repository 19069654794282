(function () {
    'use strict';

    angular
        .module('stpApp')
        .directive('featureToggle', featureToggle);

    featureToggle.$inject = ['FeatureToggleService'];

    function featureToggle(FeatureToggleService) {
        var directive = {
            restrict: 'A',
            link: linkFunc
        };

        return directive;

        function linkFunc(scope, element, attrs) {
            var feature = attrs.featureToggle.replace(/\s+/g, '');

            var setVisible = function () {
                    element.removeClass('hidden');
                },
                setHidden = function () {
                    element.addClass('hidden');
                },
                defineVisibility = function (reset) {

                    if (reset) {
                        setVisible();
                    }

                    var result = FeatureToggleService.featureToggle(feature);
                    if (result) {
                        setVisible();
                    } else {
                        setHidden();
                    }
                };

            if (feature) {
                defineVisibility(true);

                scope.$watch(function() {
                    return FeatureToggleService.getFeatures();
                }, function() {
                    defineVisibility(true);
                });
            }
        }
    }
})();
