(function() {
    'use strict';
    angular
        .module('stpApp')
        .factory('Licensing', Licensing);

    Licensing.$inject = ['$resource'];

    function Licensing ($resource) {
        var resourceUrl =  'stpcore/' + 'licensing';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET'},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' },
            'getMachineCode': {
                method: 'GET',
                url: resourceUrl + '/machine-code',
                transformResponse: function (resp) {
                    return {
                        plainText: resp
                    }
                }
            }
        });
        }
})();
