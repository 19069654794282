(function() {
    'use strict';
    angular
        .module('stpApp')
        .factory('DailyRouteService', DailyRouteService);

    DailyRouteService.$inject = ['$resource'];

    function DailyRouteService ($resource) {
        var resourceUrl =  'stpcore/' + 'api/projects/:projectId/daily-route-stats';

        return $resource(resourceUrl, {}, {
            'getDailySummaryAfter': {
                url: resourceUrl + '%3AsummaryAfter',
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'getDailySummaryBefore': {
                url: resourceUrl + '%3AsummaryBefore',
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'query': {
                method: 'GET',
                isArray: true
            },
            'getPerDayDetails': {
                url: resourceUrl + '%3AperDayDetails',
                method: 'POST',
                isArray: true
            }
        });
    }
})();
