(function() {
    'use strict';

    angular
        .module('stpApp')
        .controller('SyncDialogController',SyncDialogController);

    SyncDialogController.$inject = ['$uibModalInstance'];

    function SyncDialogController($uibModalInstance) {
        var vm = this;

        vm.clear = clear;
        vm.synchronize = synchronize;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function synchronize () {
            $uibModalInstance.close();
        }
    }
})();
