(function(){
    'use strict';

    angular
        .module('stpApp')
        .controller('DeleteTerritoryDialogController', DeleteTerritoryDialogController);

    DeleteTerritoryDialogController.$inject = ['$uibModalInstance', 'key', 'name', 'numOfLockedTerritory'];

    function DeleteTerritoryDialogController($uibModalInstance, key, name, numOfLockedTerritory) {
        var vm = this;
        vm.close = close;
        vm.deleteTerritory = deleteTerritory;
        vm.key = key
        vm.name = name
        vm.numOfLockedTerritory = numOfLockedTerritory;
        vm.keyName = '';
        vm.keyName += vm.key;
        vm.keyName += vm.name ? ' - ' + vm.name : '';

        function close() {
            $uibModalInstance.dismiss();
        }

        function deleteTerritory() {
            $uibModalInstance.close(true);
        }
    }
})();
