(function () {
    'use strict';

    angular
        .module('stpApp')
        .directive('routePlanningWeek', routePlanningWeek);

    routePlanningWeek.$inject = ['Route', 'RoutePlanningConstants'];

    function routePlanningWeek (Route, RoutePlanningConstants) {
        var directive = {
            restrict: 'E',
            scope: true,
            templateUrl: 'app/route/directives/route-planning-week.html',
            link: link
        };

        return directive;

        function link (scope, element, attrs) {
            var vm = scope.vm;
            var CONST = RoutePlanningConstants;
            vm.totalListWeek = vm.getFilters().depot ? Object.keys(vm.getDataStyles().depots[vm.getFilters().depot].weeks).length : vm.sharedData.totalList.week;

            vm.$onDestroy = $onDestroy;

            vm.getGroupWeek = getGroupWeek;
            vm.getWeekStatus = getWeekStatus;
            vm.getWeekConfigIsValid = getWeekConfigIsValid;
            vm.isOneWeekExist = isOneWeekExist;
            vm.isOneWeek = isOneWeek;
            vm.selectWeekNum = selectWeekNum;
            vm.groupWeekLength = groupWeekLength;
            vm.allDataChecked = allDataChecked;

            function $onDestroy() {
                vm.changeView();
            }

            function getGroupWeek () {
                return vm.sharedData.groupWeek;
            }
            function getWeekStatus () {
                return vm.sharedData.weekStatus;
            }

            function getWeekConfigIsValid () {
                return vm.sharedData.weekConfigIsValid;
            }

            function isOneWeekExist () {
                return Object.keys(vm.sharedData.groupWeek).some(function (key) { return isOneWeek(key); });
            }

            function isOneWeek (val) {
                return val.includes("1w");
            }

            function groupWeekLength () {
                return Object.keys(vm.sharedData.groupWeek).length;
            }

            function selectWeekNum (val) {
                var totalWeek = Object.keys(vm.sharedData.dataStyle.depots[vm.sharedData.filters.depot][CONST.WEEKS]).length;
                var weeks = totalWeek / val.split('d')[1].split('w')[0];
                return weeks;
            }

            function allDataChecked() {
                return Object.keys(vm.sharedData.weekConfig).every(function (key){
                    return vm.sharedData.weekConfigIsValid[key];
                });
            }

            vm.changeView = scope.$on('change-view', function(){
                vm.totalListWeek = vm.sharedData.totalList.week
            });
        }
    }

})();
