(function(){
    'use strict';

    angular
        .module('stpApp')
        .controller('ImportStopDialogOptimizeController', ImportStopDialogOptimizeController);

    ImportStopDialogOptimizeController.$inject = ['$uibModalInstance'];

    function ImportStopDialogOptimizeController($uibModalInstance) {
        var vm = this;
        vm.close = close;
        vm.save = save;

        function close() {
            $uibModalInstance.dismiss();
        }

        function save() {
            $uibModalInstance.close();
        }

    }
})();
