(function() {
    'use strict';
    angular
        .module('stpApp')
        .factory('Project', Project);

    Project.$inject = ['$resource'];

    function Project ($resource) {
        var resourceUrl =  'stpcore/' + 'api/projects/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' },
            'accessControl': {
                url: 'stpcore/' + 'api/project-access-controls/',
                method: 'GET',
                isArray: true
            },
            'saveAccessControl': {
                url: 'stpcore/' + 'api/project-access-controls/',
                method: 'POST',
            },
            'updateAccessControl': {
                url: 'stpcore/' + 'api/project-access-controls/',
                method: 'PUT',
            },
            'deleteAccessControl': {
                url: 'stpcore/' + 'api/project-access-controls/:id',
                method: 'DELETE',
            },
            'updateTime': {
                url: 'stpcore/' + 'api/projects',
                method: 'PUT'
            },
            'updateStopsFreq': {
                url : 'stpcore/' + 'api/projects/:projectId/visit-frequencies',
                method: 'PUT'
            },
            'getEnv': {
                url : 'stpcore/' + 'api/app-props',
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'getPoiSetting': {
                method: 'GET',
                // url : resourceUrlPostman + 'classifications/findAll',
                url : 'stpcore/' + 'api/jitu/poi-classifications',
                isArray: true
            },
            'lockStops': {
                url : 'stpcore/' + 'api/projects/:projectId%3AlockOutlets',
                method: 'POST'
            },
            'oneClickBalancing': {
                url : 'stpcore/' + 'api/projects/:projectId/one-click-distance-balancing',
                method: 'POST'
            },
            'oneClickPizzaBalancing': {
                url : 'stpcore/' + 'api/projects/:projectId/one-click-pizza-balancing',
                method: 'POST'
            },
            'getUnassignStop': {
                url : 'stpcore/' + 'api/projects/:projectId/one-click-balancing/unplanned-outlets',
                method: 'GET',
                isArray: true
            },
            'balancingStatus': {
                url : 'stpcore/' + 'api/projects/:projectId/one-click-balancing-status',
                method: 'GET',
            },
            'getOutlets': {
                url: 'stpcore/' + 'api/projects/:projectId/outlets',
                mehtod: 'GET',
                isArray: true
            },
        });
    }
})();
