(function () {
    "use strict";

    angular.module("stpApp").config(stateConfig);

    stateConfig.$inject = ["$stateProvider"];

    function stateConfig($stateProvider) {
        $stateProvider
            .state("route-plan", {
                parent: "app",
                url: "/route-plan/{id}?view&depot&territory&week&day&isEditTransitPoint&token",
                data: {
                    authorities: ["ROLE_USER", "ROLE_ONE_TIME_USER"],
                    pageTitle: "route.home.title"
                },
                views: {
                    "content@": {
                        templateUrl: "app/route/route-planning.html",
                        controller: "RoutePlanningController",
                        controllerAs: "vm"
                    }
                },
                params: {
                    territory: null,
                    week: null,
                    day: null,
                    depot: null,
                    view: null,
                    showOverTime: null,
                    token: null,
                },
                resolve: {
                    translatePartialLoader: [
                        "$translate",
                        "$translatePartialLoader",
                        function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart("route");
                            $translatePartialLoader.addPart("project");
                            $translatePartialLoader.addPart("routings");
                            $translatePartialLoader.addPart("stop");
                            $translatePartialLoader.addPart("syncParang");
                            $translatePartialLoader.addPart("jituSummary");
                            $translatePartialLoader.addPart("unplanned-reason");
                            return $translate.refresh();
                        }
                    ],
                    projectResource: ["$stateParams", "Project", function ($stateParams, Project) {
                        return Project.get({ id: $stateParams.id }).$promise;
                    }
                    ],
                    pagingParams: ["$stateParams", function ($stateParams) {
                        return {
                            territory: $stateParams.territory,
                            week: $stateParams.week,
                            day: $stateParams.day,
                            view: $stateParams.view
                        };
                    }
                    ],
                    mapPropertyResource: ["$stateParams", "MapProperty", function ($stateParams, MapProperty) {
                        return MapProperty.query({
                            "projectId.equals": $stateParams.id
                        }).$promise;
                    }
                    ],
                    startPoinResource: ["$stateParams", "Depot", function ($stateParams, Depot) {
                        return Depot.query({
                            "projectId": $stateParams.id,
                            "size": 2000
                        }).$promise;
                    }
                    ],
                    appEnv: ["Project", function (Project) {
                        return Project.getEnv().$promise;
                    }],
                    depotStatsSummary: ["$stateParams", "Depot", function ($stateParams, Depot) {
                        return Depot.getDepotStatsSummary({
                            "projectId": $stateParams.id
                        }).$promise;
                    }],
                    baseMapTileLayers: ["TileLayerAsyncService", function (TileLayerAsyncService) {
                        return TileLayerAsyncService.getBaseMaps();
                    }],
                    admTileLayers: ["TileLayerAsyncService", function (TileLayerAsyncService) {
                        return TileLayerAsyncService.getAdministrationWms();
                    }],
                    isOneTimeUser: ["Principal", function (Principal) {
                        return Principal.identity().then(function(account) {
                            if(!account) {return false}
                            var isOneTimeUser = account.authorities.find(function(role){
                                return role === 'ROLE_ONE_TIME_USER'
                            });
                            return isOneTimeUser ? true : false;
                        });
                    }],
                    oneClickFeatureToggle: ["ApplicationSetting", function(ApplicationSetting){
                        return ApplicationSetting.query({
                            "key.equals": "ftr-one-click-balancing"
                        }).$promise.then(function(data){
                            var _featureValue = false;
                            if(data.length) {
                                _featureValue = data[0].value === 'true' ? true : false;
                            }
                            return _featureValue;
                        });
                    }],
                }
            })
            .state("route-plan.routings", {
                parents: "route-plan",
                url: "/routings",
                data: {
                    authorities: ["ROLE_USER", "ROLE_ONE_TIME_USER"],
                    pageTitle: "routings.home.title"
                },
                views: {
                    "content@": {
                        templateUrl: "app/route/routings/routings.html",
                        controller: "RoutingsController",
                        controllerAs: "vm"
                    }
                },
                resolve: {
                    translatePartialLoader: [
                        "$translate",
                        "$translatePartialLoader",
                        function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart("routings");
                            return $translate.refresh();
                        }
                    ],
                    mapPropertyResource: [
                        "$stateParams",
                        "MapProperty",
                        function ($stateParams, MapProperty) {
                            return MapProperty.query({
                                "projectId.equals": $stateParams.id
                            }).$promise;
                        }
                    ],
                    depotResource: ["$stateParams", "Depot", function ($stateParams, Depot) {
                        return Depot.get({
                            "projectId": $stateParams.id,
                            "id": $stateParams.depot
                        }).$promise;
                    }],
                    avoidTollsFeatureToggleVal: ["ApplicationSetting", function(ApplicationSetting){
                        return ApplicationSetting.query({
                            "key.equals": "ftr-route-options"
                        }).$promise.then(function(data){
                            var _featureValue = false;
                            if(data.length) {
                                _featureValue = data[0].value === 'true' ? true : false
                            }
                            return _featureValue;
                        });
                    }],
                    transitList: ["$stateParams", "Depot", "TransitPoint", function ($stateParams, Depot, TransitPoint) {
                        var pathParams = {projectId: $stateParams.id};
                        var bodyRequest = {
                            depotId: $stateParams.depot,
                            territory: $stateParams.territory,
                            week: $stateParams.week,
                            day: $stateParams.day,
                        }

                        return Depot.query(pathParams).$promise.then(
                            function (res) {
                                var depots = res.map(function (each) {
                                    return Object.assign({ pointType: 'DEPOT' }, each);
                                });
                                return TransitPoint.query(pathParams, bodyRequest).$promise.then(function(res){
                                    var transits = res.map(function (each) {
                                        return Object.assign({pointType: 'TRANSIT'}, each);
                                    });
                                    return depots.concat(transits);
                                }, function(error){
                                    return depots;
                                });

                            },
                            function (error) {
                                return [];
                            }
                        );
                    }],
                    currentTransitPoint: ["$stateParams", "TransitPoint", function ($stateParams, TransitPoint){
                        var pathParams = { projectId: $stateParams.id };
                        var bodyRequest = {
                            depotId: $stateParams.depot,
                            territory: $stateParams.territory,
                            week: $stateParams.week,
                            day: $stateParams.day,
                        }
                        return TransitPoint.getTransitByDailyRoutes(pathParams, bodyRequest).$promise.then(function(data){
                            return {
                                endPointId: data.endPointId,
                                endPointType: data.endPointType,
                                startPointId: data.startPointId,
                                startPointType: data.startPointType,
                            };
                        }, function(error){
                            return null;
                        })
                    }],
                    isOneTimeUser: ["Principal", function (Principal) {
                        return Principal.identity().then(function(account) {
                            if(!account) {return false}
                            var isOneTimeUser = account.authorities.find(function(role){
                                return role === 'ROLE_ONE_TIME_USER'
                            });
                            return isOneTimeUser ? true : false;
                        });
                    }]
                }
            })
            .state("route-plan.user-control", {
                parents: "route-plan",
                url: "/user-control",
                data: {
                    authorities: ["ROLE_USER"],
                    pageTitle: "routings.home.title"
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/route/access-control/access-control.html',
                        controller: 'AccessControlController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            translatePartialLoader: [
                                "$translate",
                                "$translatePartialLoader",
                                function ($translate, $translatePartialLoader) {
                                    $translatePartialLoader.addPart("access-control");
                                    return $translate.refresh();
                                }
                            ],
                            projectAccessControl: ['Project', function (Project) {
                                return Project.accessControl({ "projectId.equals": $stateParams.id }).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('^', {}, { reload: false });
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state("route-plan.day-setting", {
                parents: "route-plan",
                url: "/day-setting",
                data: {
                    authorities: ["ROLE_USER", "ROLE_ONE_TIME_USER"],
                    pageTitle: "route.home.daySetting"
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/route/day-setting/day-setting.html',
                        controller: 'DaySettingController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'md',
                        resolve: {
                            projectResource: ['Project', function (Project) {
                                return Project.get({ "id": $stateParams.id }).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('^', {}, { notify: true, reload: false });
                    }, function () {
                        $state.go('^');
                    });
                }]
            });
    }
})();
