(function() {
    // by faris - start
    'use strict';

    angular
        .module('stpApp')
        .controller('ImportExportDialogController', ImportExportDialogController);

    ImportExportDialogController.$inject = ['$uibModalInstance', '$translate', 'Upload', 'ExportStopRoute', 'totalVisit', 'entity', 'dataStyle', '$q', 'RoutePlanningDataShare', 'ParseFileService'];

    function ImportExportDialogController ($uibModalInstance, $translate, Upload, ExportStopRoute, totalVisit, entity, dataStyle, $q, RoutePlanningDataShare, ParseFileService) {
        var vm = this;

        vm.closeDialog = closeDialog;
        vm.clearAlert = clearAlert;
        vm.project = entity;

        vm.indexTab = 'IMPORT';
        vm.checkFile = checkFile;

        vm.startImportExport = startImportExport;

        vm.radioImportSelected = "STOP_DATA";
        vm.radioExportSelected = "Stop Data";
        vm.dataStyle = dataStyle;
        vm.stopId = '';
        vm.exportRouteDetails = exportRouteDetails;
        vm.exportRouteLocks = exportRouteLocks;
        vm.header = [
            "territory_name",
            "territory",
            "week",
            "day",
            "sequence",
            "depot_name",
            "start_location_name",
            "finish_location_name",
            "external_stop_id",
            "stop_name",
            "stop_note",
            "stop_latitude",
            "stop_longitude",
            "arrival_time",
            "stop_duration",
            "leave_time",
            "travel_km",
            "travel_time",
            "overnight",
            "distance_cost",
            "kelurahan",
            "kecamatan",
            "kabupaten",
            "provinsi",
        ];
        vm.headerWithCapacity = [
            "territory_name",
            "territory",
            "week",
            "day",
            "sequence",
            "reload",
            "depot_name",
            "start_location_name",
            "finish_location_name",
            "external_stop_id",
            "stop_name",
            "stop_note",
            "stop_latitude",
            "stop_longitude",
            "qty",
            "qty_remaining",
            "arrival_time",
            "stop_duration",
            "leave_time",
            "travel_km",
            "travel_time",
            "overnight",
            "distance_cost",
            "kelurahan",
            "kecamatan",
            "kabupaten",
            "provinsi",
        ];
        vm.headersCsvLock = [
            "external_stop_id",
            "depot_lock",
            "territory_lock",
            "week_lock",
            "day_lock",
        ];
        vm.header = headersCsv
        vm.filename = vm.project.id + '_' + vm.project.name.replace(/ /g,"_") + '_' + 'route_detail.csv';
        vm.filenameLock = vm.project.id + '_' + vm.project.name.replace(/ /g,"_") + '_' + 'route_lock.csv';
        vm.totalItems = totalVisit;
        vm.accept = '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel';
        vm.importRadioChange = importRadioChange;

        var reloadRouteplan = false;
        vm.depotOpts = RoutePlanningDataShare.depots;
        vm.selectedDepot = vm.depotOpts[0];

        function closeDialog () {
            $uibModalInstance.dismiss(reloadRouteplan);
        }

        function checkFile(file) {
            var maxFileSizeInBytes = 12e+6;
            if(file && file.size > maxFileSizeInBytes) {
                fileIsInvalid();
                vm.message = "The file size is too large, the file size must less than " + maxFileSizeInBytes/1e+6 + "MB";
            } else if(file && !isCsv(file)) {
                fileIsInvalid();
                vm.message = "The file type is not allowed";

            } else {
                vm.alertImport = false;
                vm.alertExport = false;

                return true;
            }
        }

        function isCsv(file) {
            if (vm.radioImportSelected == 'STOP_DATA' || vm.radioImportSelected == 'ROUTE_DATA_BY_DEPOT') {
                return (
                    file &&
                    (
                        file.type == 'text/csv' ||
                        file.type == 'application/vnd.ms-excel' ||
                        file.type == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
                        file.name.endsWith('.csv') ||
                        file.name.endsWith('.xlxs')
                    )
                );
            } else {
                return file && (file.type == "text/csv" || file.type == "application/vnd.ms-excel" || file.name.endsWith(".csv"));
            }
        }

        function fileIsInvalid() {
            vm.alertImport = true;
            vm.fileImport = null;

            $("#fileImportTooLarge").show(50);
            return false;
        }

        function clearAlert() {
            $("#fileImportTooLarge").hide();
            $("#fileImportErr").hide();
            $("#fileExportTooLarge").hide();
            $("#fileExportErr").hide();
            $("#fileExportOK").hide();
            $("#fileImportOK").hide();
        }

        function startImportExport() {
            clearAlert();
            vm.shake = false;
            reloadRouteplan = true;

            if (vm.indexTab == 'IMPORT') {
                var isValid = checkFile(vm.fileImport);

                if (isValid && vm.radioImportSelected == 'STOP_DATA') {
                    parseFile();
                } else if (isValid && vm.radioImportSelected == 'ROUTE_DATA') {
                    vm.isSaving = true;
                    importRouteData(vm.fileImport);
                } else if (isValid && vm.radioImportSelected == 'ROUTE_LOCK') {
                    vm.isSaving = true;
                    importRouteLockData(vm.fileImport);
                } else if (isValid && vm.radioImportSelected == 'ROUTE_DATA_BY_DEPOT') {
                    parseFile();
                }
            }

            if (vm.indexTab == 'EXPORT') {
                vm.isSaving = true;
                if (vm.radioExportSelected == 'Stop Data') {
                    mixpanel.time_event('Export Successful');
                    ExportStopRoute.stop({ projectId: vm.project.id }, onSuccess, onError);
                } else if (vm.radioExportSelected == 'Route Data') {
                    mixpanel.time_event('Export Successful');
                    ExportStopRoute.route({ projectId: vm.project.id }, onSuccess, onError);
                }

                function onSuccess(response) {
                    mixpanel.track('Export Successful', {
                        dataType:
                            vm.radioExportSelected == 'Stop Data'
                                ? 'Stop'
                                : vm.radioExportSelected == 'Route Data'
                                ? 'Route'
                                : 'not-defined',
                    });
                    vm.isSaving = false;
                    var type = vm.radioExportSelected;
                    var filename = vm.project.id + '_' + vm.project.name.replace(/ /g, '_') + '_';

                    vm.exportSuccess = true;
                    type == 'Stop Data' ? (filename += 'stop.csv') : (filename += 'route.csv');

                    saveAs(response.data, filename, true);

                    $('#fileExportOK').show(50);
                }

                function onError(response) {
                    vm.isSaving = false;
                    vm.responseExportStatus = response.status;
                    vm.responseExportStatusText = response.statusText;
                    vm.responseExportData = response.data;
                    vm.exportError = true;

                    $('#fileExportErr').show(50);
                }
            }
        }

        function importRouteLockData(fileImport) {
            mixpanel.time_event('Import Successful');
            Upload.upload({
                url: 'stpcore/api/projects/'+vm.project.id+'%3AoutletLockImport',
                data: {
                    projectId: vm.project.id,
                    file: fileImport,
                },
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            }).then(function () {
                mixpanel.track('Import Successful', {
                    'dataType': 'Route Lock'
                });
                vm.isSaving = false;
                vm.importSuccess = true;
                vm.fileImport = '';

                $("#fileImportOK").show(50);

            }, function (response) {
                var message = response.data.status == 500 ? 'internalServer' : response.data.message;
                var params = response.data.params;
                mixpanel.track('Import Failed', {
                    'dataType': 'Route Lock',
                    'errorMessage': message
                });
                vm.isSaving = false;
                vm.responseImportData = generateErrorTranslation(message, params);
                vm.importError = true;

                if (message == 'exceedOutletsLimit') {
                    vm.shake = true;
                } else {
                    $("#fileImportErr").show(50);

                }
            }, function (evt) {
                var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
                file.progress = 'Progress: ' + progressPercentage + '%';
            });
        }

        function importRouteData(fileImport) {
            mixpanel.time_event('Import Successful');
            Upload.upload({
                url: 'stpcore/api/routes/import',
                data: {
                    projectId: vm.project.id,
                    file: fileImport,
                },
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            }).then(function () {
                mixpanel.track('Import Successful', {
                    'dataType': 'Route'
                });
                vm.isSaving = false;
                vm.importSuccess = true;
                vm.fileImport = '';

                $("#fileImportOK").show(50);

            }, function (response) {
                var message = response.data.status == 500 ? 'internalServer' : response.data.message;
                var params = response.data.params;
                mixpanel.track('Import Failed', {
                    'dataType': 'Route',
                    'errorMessage': message
                });
                vm.isSaving = false;
                vm.responseImportData = generateErrorTranslation(message, params);
                vm.importError = true;

                if (message == 'exceedOutletsLimit') {
                    vm.shake = true;
                } else {
                    $("#fileImportErr").show(50);

                }
            }, function (evt) {
                var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
                file.progress = 'Progress: ' + progressPercentage + '%';
            });
        }

        function generateErrorTranslation(message, params){
            var result;
            var translate = 'stpApp.routings.error.import.' + message;
            result = $translate.instant(translate, params);
            return result;
        }

        function headersCsv() {
            if (vm.project.props.useCapacity) {
                return vm.headerWithCapacity;
            }
            return vm.header;
        }

        function exportRouteDetails() {
            vm.isSaving = true;
            vm.exportArray = [];
            var deferRouteDetails = $q.defer();
            var useCapacity = vm.project.props.useCapacity;

            mixpanel.time_event('Export Successful');
            ExportStopRoute.routePotentialData({
                projectId: vm.project.id,
                refresh: false
            }, function(data){

                vm.isSaving = false;
                if (useCapacity) {
                    data.forEach(function(x, index, arr){
                        x.afterStopCapacity = x.afterStopCapacity ? x.afterStopCapacity : 0
                        x.qty = index === 0 ? 0 : x.afterStopCapacity - arr[index - 1].afterStopCapacity

                        var exportObject = {
                            territory_name: x.territoryName,
                            territory: x.territory,
                            week: x.week,
                            day: x.day,
                            seq: x.sequence,
                            reload: x.qty > 0 ? true : false,
                            depot_name: x.depotName,
                            start_location_name: x.startPointName,
                            finish_location_name: x.endPointName,
                            ex_stop_id: x.externalStopId,
                            stop_name: x.stopName,
                            stop_note: x.stopNote,
                            stop_lat: x.stopLatitude,
                            stop_lon: x.stopLongitude,
                            qty: x.qty,
                            qty_remaining: x.afterStopCapacity,
                            arrival_time: x.arrivalTime,
                            stop_duration: x.stopDuration,
                            leave_time: x.leaveTime,
                            travel_distance: Math.round((x.travelKm + Number.EPSILON) * 100) / 100,
                            travel_duration: x.travelTime,
                            overnight: x.overnight,
                            distance_cost: x.distanceCost,
                            kelurahan_code: x.kelurahanCode,
                            kelurahan: x.kelurahan,
                            kecamatan: x.kecamatan,
                            kabupaten: x.kabupaten,
                            provinsi: x.provinsi,
                        };

                        _bpsPoi(exportObject, x)
                    });
                }else {
                    data.forEach(function(x){
                        var exportObject = {
                            territory_name: x.territoryName,
                            territory: x.territory,
                            week: x.week,
                            day: x.day,
                            seq: x.sequence,
                            depot_name: x.depotName,
                            start_location_name: x.startPointName,
                            finish_location_name: x.endPointName,
                            ex_stop_id: x.externalStopId,
                            stop_name: x.stopName,
                            stop_note: x.stopNote,
                            stop_lat: x.stopLatitude,
                            stop_lon: x.stopLongitude,
                            arrival_time: x.arrivalTime,
                            stop_duration: x.stopDuration,
                            leave_time: x.leaveTime,
                            travel_distance: Math.round((x.travelKm + Number.EPSILON) * 100) / 100,
                            travel_duration: x.travelTime,
                            overnight: x.overnight,
                            distance_cost: x.distanceCost,
                            kelurahan_code: x.kelurahanCode,
                            kelurahan: x.kelurahan,
                            kecamatan: x.kecamatan,
                            kabupaten: x.kabupaten,
                            provinsi: x.provinsi,
                        };

                        _bpsPoi(exportObject, x)
                    });
                }

                function _bpsPoi(exportObject, x) {
                    if (x.bps) {
                        x.bps.forEach(function (val) {
                            exportObject[val.name] = val.value
                        });
                    }
                    if (x.poi) {
                        x.poi.forEach(function (val) {
                            exportObject[val.name] = val.value
                        });
                    }

                    vm.exportArray.push(exportObject);
                }

                deferRouteDetails.resolve(vm.exportArray);
                mixpanel.track('Export Successful', {
                    'dataType': 'Route Details'
                })
            }, function(response){
                var message = response.data.status == 500 ? 'internalServer' : response.data.message;
                var param = response.data.params ? response.data.params.param0 : null ? response.data.params.param0 : null;
                vm.isSaving = false;
                vm.responseImportData = generateErrorTranslation(message, param);
                vm.importError = true;

                $("#fileImportErr").show(50);
            });

            return deferRouteDetails.promise;
        }

        function exportRouteLocks() {
            var deferRouteLocks = $q.defer();

            vm.isSaving = true;
            vm.exportArray = [];
            var promises = [];
            var maxSize = 2000;
            var limit = Math.ceil(vm.totalItems / maxSize);

            for (var i = 0; i < limit; i++) {
                var promise = ExportStopRoute.routeLock({ projectId: vm.project.id, size: maxSize, page: i, sort: 'id,asc' }).$promise;
                promises.push(promise);
            }
            mixpanel.time_event('Export Successful')
            $q.all(promises).then(function (result) {
                vm.exportArray = result.flat().map(function (data) {
                    return {
                        external_stop_id: data.externalId,
                        depot_lock: data.depotLocked,
                        territory_lock: data.territoryLocked,
                        week_lock: data.weeksLocked,
                        day_lock: data.daysLocked,
                    };
                })
                deferRouteLocks.resolve(vm.exportArray);
                vm.isSaving = false;
                mixpanel.track('Export Successful', {
                    'dataType': 'Route Lock'
                })
            }, function (response) {
                vm.isSaving = false;
                vm.responseExportStatus = response.status;
                vm.responseExportStatusText = response.statusText;
                vm.responseExportData = response.data;
                vm.exportError = true;

                $("#fileExportErr").show(50);
            });
            return deferRouteLocks.promise;
        }

        function importRadioChange(selected) {
            if (selected == 'STOP_DATA' || selected == 'ROUTE_DATA_BY_DEPOT') {
                vm.accept = '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel';
            } else {
                vm.accept = '.csv';
            }
        }

        function parseFile() {
            vm.isSaving = true;
            var isCsv = vm.fileImport.type == "text/csv" || vm.fileImport.type == "application/vnd.ms-excel" || vm.fileImport.name.endsWith(".csv");

            function _onParseError() {
                $("#fileImportErr").show(50);

                vm.responseImportData = $translate.instant('stpApp.route.importStopData.duplicateHeader');
                vm.importError = true;
                vm.isSaving = false;
            }

            if (isCsv) {
                ParseFileService.parseCsv(vm.fileImport).then(
                    function (result) {
                        var params = { type: vm.radioImportSelected, parseData: result, depot: vm.selectedDepot };
                        $uibModalInstance.close(params);
                    },
                    function () {
                        _onParseError();
                    }
                );
            } else {
                ParseFileService.parseSheet(vm.fileImport).then(
                    function (result) {
                        var params = { type: vm.radioImportSelected, parseData: result, depot: vm.selectedDepot };
                        $uibModalInstance.close(params);
                    },
                    function () {
                        _onParseError();
                    }
                );
            }
        }
    }
})();
