(function() {
    'use strict';

    angular
        .module('stpApp')
        .controller('ProjectAccessControlDeleteController',ProjectAccessControlDeleteController);

    ProjectAccessControlDeleteController.$inject = ['$uibModalInstance', 'entity', 'ProjectAccessControl'];

    function ProjectAccessControlDeleteController($uibModalInstance, entity, ProjectAccessControl) {
        var vm = this;

        vm.projectAccessControl = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            ProjectAccessControl.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
