(function() {
    'use strict';

    angular
        .module('stpApp')
        .controller('ProjectAccessControlDialogController', ProjectAccessControlDialogController);

    ProjectAccessControlDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'ProjectAccessControl', 'Project'];

    function ProjectAccessControlDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, ProjectAccessControl, Project) {
        var vm = this;

        vm.projectAccessControl = entity;
        vm.clear = clear;
        vm.save = save;
        vm.projects = Project.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.projectAccessControl.id !== null) {
                ProjectAccessControl.update(vm.projectAccessControl, onSaveSuccess, onSaveError);
            } else {
                ProjectAccessControl.save(vm.projectAccessControl, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('stpApp:projectAccessControlUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();
