(function() {
    'use strict';

    angular
        .module('stpApp')
        .controller('StopDialogController', StopDialogController);

    StopDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'DataUtils', 'entity', 'Stop', 'Project'];

    function StopDialogController ($timeout, $scope, $stateParams, $uibModalInstance, DataUtils, entity, Stop, Project) {
        var vm = this;

        vm.stop = entity;
        vm.clear = clear;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;
        vm.save = save;
        vm.projects = Project.query();

        var milisMultiple = 60000;
        vm.inStopMinutes = vm.stop.inStopDuration / milisMultiple;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            vm.stop.inStopDuration = vm.inStopMinutes * milisMultiple;
            if (vm.stop.id !== null) {
                Stop.update(vm.stop, onSaveSuccess, onSaveError);
            } else {
                Stop.save(vm.stop, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('stpApp:stopUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();
