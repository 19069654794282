(function() {
    'use strict';

    angular
        .module('stpApp')
        .factory('FormatterUtil', FormatterUtil);

    FormatterUtil.$inject = [];

    function FormatterUtil() {

        var service = {
            humanizeDistanceFormat: humanizeDistanceFormat,
            humanizeDurationFormat: humanizeDurationFormat
        };

        return service;

        function humanizeDistanceFormat (distance){
            var meterInKilo = (distance / 1000).toFixed(2);
            var distanceInKiloMeterOrMeter;

            return distanceInKiloMeterOrMeter = meterInKilo >= 1 ?  meterInKilo + ' km' : meterInKilo + ' m';
        }

        function humanizeDurationFormat(duration){
            var duration = moment.duration(duration, 'seconds'),
                days = duration.days(),
                hours = duration.hours(),
                minutes = duration.minutes(),
                seconds = duration.seconds(),
                durationAsDayHourMinute = '';

            durationAsDayHourMinute += days > 0 ? days+' d ': '';
            durationAsDayHourMinute += hours > 0 ? hours+' h ': '';
            durationAsDayHourMinute += minutes > 0 ? minutes+' min ': '';
            durationAsDayHourMinute += days == 0 && hours == 0 && minutes == 0 && seconds > 0 ? seconds+' sec': '';
            durationAsDayHourMinute += days == 0 && hours == 0 && minutes == 0 && seconds == 0 ? seconds+' sec': '';

            return durationAsDayHourMinute;
        }
    }

})();
