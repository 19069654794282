(function () {
    'use strict';

    angular
        .module('stpApp')
        .factory('FeatureSetting', FeatureSetting);

    FeatureSetting.$inject = ['ApplicationSetting'];

    function FeatureSetting (ApplicationSetting) {
        var FeatureSettingKey = 'ftr';
        var filterBy = 'key.contains';

        var reqParam = {};
        reqParam[filterBy] = FeatureSettingKey;

        return {
            getFeatureSetting: getFeatureSetting
        };

        function getFeatureSetting (okCallback, errorCallback) {
            return ApplicationSetting.query(reqParam).$promise
            .then(function (dataArr) {
                if (dataArr) {
                    okCallback(dataArr);
                } else {
                    errorCallback('Features not found');
                }
            }, errorCallback);
        }
    }
})();
