(function () {
    'use strict';

    angular
        .module('stpApp')
        .directive('routePlanningDay', routePlanningDay);

    routePlanningDay.$inject = ['Route', 'RoutePlanningConstants'];

    function routePlanningDay(Route, RoutePlanningConstants) {
        var directive = {
            restrict: 'E',
            scope: true,
            templateUrl: 'app/route/directives/route-planning-day.html',
            link: link
        };

        return directive;

        function link(scope, element, attrs) {
            var vm = scope.vm;
            var CONST = RoutePlanningConstants;
            var dataStyles = vm.getDataStyles();
            vm.totalListDay = dataStyles ? Object.keys(dataStyles.days).length || vm.sharedData.totalList.day : 0;
            vm.isAllStopDayHide = false

            vm.getGroupDay = getGroupDay;
            vm.getDayConfigIsValid = getDayConfigIsValid;
            vm.getDayStatus = getDayStatus;
            vm.isFullDayExist = isFullDayExist;
            vm.isFullDay = isFullDay;
            vm.onShowHideAllStopsDays = onShowHideAllStopsDays;

            function getGroupDay() {
                return vm.sharedData.groupDay;
            }
            function getDayConfigIsValid() {
                return vm.sharedData.dayConfigIsValid;
            }
            function getDayStatus() {
                return vm.sharedData.dayStatus;
            }
            function isFullDayExist() {
                return Object.keys(vm.getGroupDay()).some(function(key) {return isFullDay(key)});
            }
            function isFullDay(val) {
                return Number(val.substring(0, val.indexOf('d'))) == vm.project.props.maxDay;
            }

            function onShowHideAllStopsDays(showHide) {
                for (var item in vm.toggleLayer) {
                    if (Object.hasOwnProperty.call(vm.toggleLayer, item)) {
                        vm.toggleLayer[item] = showHide
                    }
                }
                if (!showHide) {
                    vm.isAllStopDayHide = true
                }else {
                    vm.isAllStopDayHide = false
                }
                vm.onToggleLayerChange()
            }

        }
    }

})();
