(function () {
    'use strict';
    angular
        .module('stpApp')
        .factory('MultiColorIconFactory', MultiColorIconFactory);

    MultiColorIconFactory.$inject = [];

    function MultiColorIconFactory() {

        L.Canvas.include({
            _updateSlicedCirclePoint: function (layer) {
                if (!this._drawing || layer._empty()) { return; }

                var p = layer._point,
                    ctx = this._ctx,
                    r = Math.max(Math.round(layer._radius), 1);

                var lastend = 7.85;
                var datas = layer.options.markerOption;
                var myTotal = 0;
                var myColor = [];

                if (layer.options.lock) {
                    r += 1;
                }

                datas.forEach(function (data) {
                    myTotal += data.value;
                    myColor.push(data.iconColor);
                });

                datas.forEach(function (data, i) {
                    ctx.fillStyle = myColor[i];
                    ctx.beginPath();
                    ctx.arc(p.x, p.y, r, lastend, lastend + (Math.PI * 2 * (data.value / myTotal)), false);
                    ctx.lineTo(p.x, p.y);
                    ctx.fill();
                    lastend += Math.PI * 2 * (data.value / myTotal);
                    ctx.closePath();
                });
                ctx.strokeStyle = layer.options.color;
                ctx.lineWidth = layer.options.weight;
                ctx.beginPath();
                ctx.arc(p.x, p.y, r, 0, (Math.PI * 2), false);
                ctx.stroke();
                ctx.closePath();
                if (layer.options.img && layer.options.lock) {
                    ctx.drawImage(layer.options.img, p.x - 10 / 2, p.y - 10 / 2, 10,10);
                }
            },
            _updateRectWithTextPoint: function (layer) {
                if (!this._drawing || layer._empty()) { return; }

                var p = layer._point,
                    ctx = this._ctx,
                    s = layer.options.sides;

                ctx.beginPath();
                ctx.rect(p.x - (s / 2), p.y - (s / 2), s, s);
                this._fillStroke(ctx, layer);

                ctx.font = "bold 8pt Arial";
                ctx.fillStyle = "#fff";
                ctx.textAlign = "center";
                if (!layer.options.lock) {
                    ctx.fillText(layer.options.textValue, p.x, p.y + 8 / 2);
                }
                if (layer.options.img && layer.options.lock) {
                    ctx.drawImage(layer.options.img, p.x - 12 / 2, p.y - 12 / 2, 12,12);
                }
            },
            _updateCircleWithLock: function (layer) {
                if (!this._drawing || layer._empty()) {
                    return;
                }

                var p = layer._point,
                    ctx = this._ctx,
                    r = Math.max(Math.round(layer._radius), 1),
                    s = (Math.max(Math.round(layer._radiusY), 1) || r) / r;

                this._drawnLayers[layer._leaflet_id] = layer;

                if (s !== 1) {
                    ctx.save();
                    ctx.scale(1, s);
                }

                if(layer.options.lock) {
                    r += 1;
                }

                ctx.beginPath();
                ctx.arc(p.x, p.y / s, r, 0, Math.PI * 2, false);

                if (s !== 1) {
                    ctx.restore();
                }

                this._fillStroke(ctx, layer);

                if (layer.options.img && layer.options.lock) {
                    ctx.drawImage(layer.options.img, p.x - 10 / 2, p.y - 10 / 2, 10,10);
                }
            },
            _updateUnassignStop: function(layer) {
                if (!this._drawing || layer._empty()) {
                    return;
                }

                var p = layer._point,
                    ctx = this._ctx,
                    r = Math.max(Math.round(layer._radius), 1),
                    s = (Math.max(Math.round(layer._radiusY), 1) || r) / r;

                this._drawnLayers[layer._leaflet_id] = layer;

                if (s !== 1) {
                    ctx.save();
                    ctx.scale(1, s);
                }

                ctx.beginPath();
                ctx.arc(p.x, p.y / s, r, 0, Math.PI * 2, false);

                if (s !== 1) {
                    ctx.restore();
                }

                this._fillStroke(ctx, layer);

                if (layer.options.img) {
                    ctx.drawImage(layer.options.img, p.x - 9 / 2, p.y - 9 / 2, 9,9);
                }
            }
        });

        function SlicedCircle() {
            return L.CircleMarker.extend({
                _updatePath: function () {
                    var marker = this;

                    if (!marker.options.img) {
                        var img = document.createElement('img');
                        img.src = 'content/images/bold-lock.png';
                        marker.options.img = img;
                        img.onload = function() {
                            marker.redraw();
                        }
                    } else {
                        marker._renderer._updateSlicedCirclePoint(marker);
                    }

                }
            });
        }

        function RectWithText() {
            return L.CircleMarker.extend({
                _updatePath: function () {
                    var marker = this;

                    if (!marker.options.img) {
                        var img = document.createElement('img');
                        img.src = 'content/images/bold-lock.png';
                        marker.options.img = img;
                        img.onload = function() {
                            marker.redraw();
                        }
                    } else {
                        marker._renderer._updateRectWithTextPoint(marker);
                    }
                },
            });
        }

        function CircleWithLock() {
            return L.CircleMarker.extend({
                _updatePath: function () {
                    var marker = this;

                    if (!marker.options.img) {
                        var img = document.createElement('img');
                        img.src = 'content/images/bold-lock.png';
                        marker.options.img = img;
                        img.onload = function() {
                            marker.redraw();
                        }
                    } else {
                        marker._renderer._updateCircleWithLock(marker);
                    }
                },
            });
        }

        function UnassignStop() {
            return L.CircleMarker.extend({
                _updatePath: function () {
                    var marker = this;

                    if (!marker.options.img) {
                        var img = document.createElement('img');
                        img.src = 'content/images/unassign-stop.png';
                        marker.options.img = img;
                        img.onload = function() {
                            marker.redraw();
                        }
                    } else {
                        marker._renderer._updateUnassignStop(marker);
                    }
                },
            });
        }

        return {
            SlicedCircle: SlicedCircle,
            RectWithText: RectWithText,
            CircleWithLock: CircleWithLock,
            UnassignStop: UnassignStop,
        };

    }
})();
