(function() {
    'use-strict';

    angular
        .module('stpApp')
        .controller('UnassignDialogController', UnassignDialogController);

    UnassignDialogController.$inject = ['$uibModalInstance', 'stopIds', 'OutletAssignmentService', 'projectId'];

    function UnassignDialogController($uibModalInstance, stopIds, OutletAssignmentService, projectId) {
        var vm = this;

        vm.isSaving = false;
        vm.close = close;
        vm.unassign = unassign;
        vm.stopIds = stopIds;
        vm.projectId = projectId;

        function close() {
            $uibModalInstance.dismiss();
        }

        function unassign() {
            vm.isSaving = true;

            OutletAssignmentService.unassign({id: vm.projectId},{outletIds: vm.stopIds}).$promise.then(function() {
                $uibModalInstance.close(true);
                vm.isSaving = false;
            }, function() {
                vm.isSaving = false;
            });
        }
    }
})();
